/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableHead, TableRow, styled, Container, Backdrop,
} from '@mui/material';
import {
  BounceLoader,
} from 'react-spinners';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import { formatTime } from '../common/util/formatter';
import scheduleReport from './common/scheduleReport';
import TablePaginationFooter from './components/TablePaginationFooter';
import ReportFilterPois from './components/ReportFilterPois';
import { usePreference } from '../common/util/preferences';

const CustomContainer = styled(Container)({
  maxWidth: 2200,
});

const PoisRepotPage = () => {
  const navigate = useNavigate();
  const classes = useReportStyles();
  const hours12 = usePreference('twelveHourFormat');
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);
  //   const admin = useAdministrator();

  const devices = useSelector((state) => state.devices.items);
  const devicesSelect = useSelector((state) => state.devices.selectedId);

  const [available, setAvailable] = useState([]);
  const [columns, setColumns] = useState(['fecha', 'nombre', 'parado', 'desde', 'hasta']);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [showCard, setShowCard] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   const onMapPointClick = useCallback((positionId) => {
  //     setSelectedItem(items.find((it) => it.id === positionId));
  //   }, [items, setSelectedItem]);

  const handleSubmit = useCatch(async ({ deviceIds, from, geofenceId, to, type }) => {
    const query = new URLSearchParams({ geofenceId, from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    if (type === 'export') {
      setLoading(true);
      try {
        await fetch(`/api/reports/pois/xlsx?${query.toString()}`)
          .then((response) => response.blob())
        // .then((response) => window.location.assign(response.url));
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            throw Error(error);
          });
      } finally {
        setLoading(false);
      }
      // window.location.assign(`/api/reports/pois/xlsx?${query.toString()}`);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/pois?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          const data = await response.json();
          setItems(data);
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  //   const onMarkerClick = useCallback((positionId) => {
  //     setShowCard(!!positionId);
  //   }, [setShowCard]);

  const handleSchedule = useCatch(async (deviceIds, groupIds, report) => {
    report.type = 'route';
    const error = await scheduleReport(deviceIds, groupIds, report);
    if (error) {
      throw Error(error);
    } else {
      navigate('/reports/pois');
    }
  });

  return (
    <>
      <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportPOIS']}>
        <div className={classes.container}>
          <CustomContainer>
            <div className={classes.containerMain}>
              <div className={classes.header}>
                <ReportFilterPois handleSubmit={handleSubmit} handleSchedule={handleSchedule} />
              </div>
              <Table style={{ maxWidth: '1920px', margin: '0 auto', padding: '0 5px' }}>
                <TableHead>
                  <TableRow>
                    {/* <TableCell
                    className={classes.columnAction}
                  />
                  <TableCell>{t('sharedDevice')}</TableCell> */}
                    {columns.map((key) => (<TableCell key={key}>{positionAttributes[key]?.name || key}</TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (<TableShimmer columns={columns.length + 2} startAction />) : (
                    <>
                      {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>{formatTime(item.stoppedDate, 'minutes', hours12)}</TableCell>
                          <TableCell className={classes.limitedText}>{item.name}</TableCell>
                          <TableCell>{item.duration}</TableCell>
                          <TableCell>{formatTime(item.hourStopped, 'minutes', hours12)}</TableCell>
                          <TableCell>{formatTime(item.hourExit, 'minutes', hours12)}</TableCell>
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={columns.length + 2} />
                      </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
                <TablePaginationFooter
                  count={items.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Table>
            </div>
          </CustomContainer>
        </div>
      </PageLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <BounceLoader
          // margin={7}
          size={80}
          color="#d63636"
        />
      </Backdrop>
    </>
  );
};

export default PoisRepotPage;
