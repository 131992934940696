import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { prefixString } from '../../util/stringUtils';
import { formatTime } from '../../util/formatter';
import { useAttributePreference, usePreference } from '../../util/preferences';
import { setMarkerEvent } from '../../../map/core/MapView';
import { useCatch } from '../../../reactHelper';
import { sessionActions } from '../../../store';

// eslint-disable-next-line no-unused-vars
const CardEvents = ({
  classes,
  theme,
  t,
  handleClose,
  position,
  device,
  card,
}) => {
  const hours12 = usePreference('twelveHourFormat');
  const speedUnit = useAttributePreference('speedUnit');
  const mapFollow = useSelector(
    (state) => state.session.server.attributes?.mapFollow || false,
  );
  const geofences = useSelector((state) => state.geofences.items);
  const dispatch = useDispatch();
  const addressEnabled = useSelector(
    (state) => state.session.server.geocoderEnabled,
  );
  const [events, setEvents] = useState([]);
  const [deviceId, setDeviceId] = useState(0);
  const [address, setAddress] = useState('');
  const [positionSelected, setPositionSelected] = useState(null);
  const [eventSelected, setEventSelected] = useState(null);
  const [loadingEvent, setLoadingEvent] = useState(false);

  useEffect(
    () => () => {
      setMarkerEvent({});
    },
    [],
  );

  useEffect(() => {
    const showEvents = async () => {
      if (device?.id && device?.id !== deviceId) {
        setEvents([]);
        setEventSelected(null);
        setPositionSelected(null);
        setMarkerEvent({});
        setDeviceId(device?.id);
        const query = new URLSearchParams({
          deviceId: device?.id,
          page: '1',
          pageSize: '50',
        });
        const response = await fetch(
          `/api/reports/events/last?${query.toString()}`,
          {
            // const response = await fetch(`/api/reports/events?${query.toString()}`, {
            headers: { Accept: 'application/json' },
          },
        );
        if (response.ok) {
          const resp = await response.json();
          // const sortedArray = resp.sort((a, b) => new Date(b.eventTime) - new Date(a.eventTime));
          // Get the last 25 elements of the sorted array with only eventTime and type properties
          const lasts = resp.events.map(
            ({ id, eventTime, type, attributes, positionId, geofenceId }) => ({
              id,
              eventTime,
              type,
              attributes,
              positionId,
              geofenceId,
            }),
          );
          setEvents(lasts);
        } else {
          setEvents([]);
        }
      }
      if (!device?.id) {
        setDeviceId(0);
        setEventSelected(null);
        setPositionSelected(null);
        setMarkerEvent({});
        setEvents([]);
      }
    };
    showEvents();
  }, [position]);

  const getEventById = useCatch(async (event) => {
    let change = true;
    if (eventSelected?.id === event?.id) {
      change = false;
    }
    setEventSelected(event);
    if (change && event?.positionId) {
      setMarkerEvent({});
      setLoadingEvent(true);
      setAddress('');
      const response = await fetch(`/api/positions?id=${event?.positionId}`);
      if (response.ok) {
        const positions = await response.json();
        if (positions.length > 0) {
          if (
            addressEnabled &&
            positions[0]?.latitude &&
            positions[0]?.longitude
          ) {
            const query = new URLSearchParams({
              latitude: positions[0]?.latitude,
              longitude: positions[0]?.longitude,
            });
            const response = await fetch(
              `/api/server/geocode?${query.toString()}`,
            );
            if (response.ok) {
              setAddress(await response.text());
            } else {
              setAddress('');
            }
          }
          setPositionSelected(positions[0]);
          setLoadingEvent(false);
        }
      } else {
        setLoadingEvent(false);
        throw Error(await response.text());
      }
    } else {
      setPositionSelected(null);
      setEventSelected(null);
    }
  });

  useEffect(() => {
    if (mapFollow) dispatch(sessionActions.updateMapFollow());
    let geofenceName = '';
    if (
      eventSelected?.geofenceId > 0 &&
      eventSelected?.type?.includes('geofence')
    ) {
      const geofence = geofences[eventSelected?.geofenceId];
      geofenceName = geofence?.name ?? '';
    }
    setMarkerEvent(
      positionSelected,
      eventSelected,
      address,
      geofenceName,
      t,
      hours12,
      speedUnit,
    );
  }, [positionSelected]);

  return (
    <Card elevation={4} className={classes.card} style={{ minWidth: '220px' }}>
      <CardHeader
        title={(
          <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
            {t(card) ?? 'Last Events'}
          </Typography>
        )}
        align="center"
        action={(
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
          </IconButton>
        )}
        sx={{
          backgroundColor: theme.palette.colors.primary,
          color: '#f5f5f5',
          paddingY: 0.1,
        }}
      />
      <CardContent className={classes.cardContent}>
        <List
          dense
          className={classes.list}
          style={{ maxHeight: 142, overflow: 'auto', scrollbarWidth: 'thin' }}
        >
          {events.map((event) => (
            <ListItem
              className={classes.listItem}
              key={event.id}
              sx={{
                background:
                  eventSelected?.id === event?.id
                    ? `${theme.palette.colors.primary}30`
                    : null,
              }}
            >
              <ListItemText
                className={classes.listItemText}
                primary={(
                  <span>
                    {event.type !== 'alarm' ? (
                      <b>{t(prefixString('event', event.type))}</b>
                    ) : (
                      <b>{t(prefixString('alarm', event.attributes?.alarm))}</b>
                    )}
                  </span>
                )}
                secondary={(
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Tooltip
                      title={formatTime(event.eventTime, 'seconds', hours12)}
                      placement="right"
                      leaveDelay={0}
                      arrow
                    >
                      <span>{moment(event.eventTime).fromNow()}</span>
                    </Tooltip>
                    {event?.positionId ? (
                      <Tooltip
                        title={
                          eventSelected?.id !== event?.id
                            ? 'Ver en Mapa'
                            : 'Ocultar en Mapa'
                        }
                        placement="left"
                        leaveDelay={0}
                        arrow
                      >
                        {loadingEvent && eventSelected?.id === event?.id ? (
                          <IconButton
                            aria-label="position"
                            size="small"
                            sx={{ marginTop: -1 }}
                          >
                            <CircularProgress size={20} />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="position"
                            size="small"
                            sx={{ marginTop: -1 }}
                            onClick={() => getEventById(event)}
                          >
                            {eventSelected?.id !== event?.id ? (
                              <LocationOnIcon fontSize="small" />
                            ) : (
                              <LocationOffIcon fontSize="small" />
                            )}
                          </IconButton>
                        )}
                      </Tooltip>
                    ) : null}
                  </span>
                )}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default CardEvents;
