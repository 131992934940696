import {
  Box,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'react-window';
import { useTranslation } from '../common/components/LocalizationProvider';
import {
  formatSpeedRounded,
} from '../common/util/formatter';
import { useAdministrator } from '../common/util/permissions';
import { useAttributePreference } from '../common/util/preferences';
import { devicesActions } from '../store';
import { vehicleIconKey, vehicleStatus } from '../map/core/preloadImages';

const iconSize = 20;

const useStyles = makeStyles((theme) => ({
  rowBg: {
    backgroundColor: 'white',
  },
  rowBgSelected: {
    backgroundColor: `${theme.palette.colors.primary}30`,
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  positive: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.positive,
  },
  neutral: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.neutral,
  },
  unknown: {
    fontSize: `${iconSize}px`,
    color: '#bdbdbd',
  },
  medium: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.medium,
  },
  negative: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.negative,
  },
  text: {
    date: {
      fontSize: '11px',
      fontWeight: 700,
    },
  },
}));

// eslint-disable-next-line no-unused-vars
const Item = styled(Paper)(({ theme }) => ({
  padding: 0,
  elevation: 0,
  textAlign: 'center',
  backgroundColor: 'transparent',
}));

export const createFeature = (device, position) => {
  const speedUnit = useAttributePreference('speedUnit');
  const t = useTranslation();
  let statusv = device.statusv || 'unknown';
  statusv = vehicleIconKey(statusv);
  return (statusv === 'ignition' && position?.speed > 0) ? (
    <>
      <img
        src={vehicleStatus[statusv]}
        alt={vehicleStatus[statusv]}
        style={{
          width: 26,
          height: 26,
          marginTop: -5,
        }}
      />
      <Item elevation={0}>
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
          <span style={{ fontSize: 10, paddingLeft: 2, fontWeight: 600 }}>
            {`${formatSpeedRounded(Math.round(position.speed), speedUnit, t)}`}
          </span>
        </div>
      </Item>
    </>
  ) : (
    <img
      src={vehicleStatus[statusv]}
      alt={vehicleStatus[statusv]}
      style={{
        width: 28,
        height: 28,
        marginTop: 4,
      }}
    />
  );
};

const DeviceRowSimple = memo(({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const admin = useAdministrator();
  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');

  return (
    <Box alignContent="space-between" style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.selectId(item.id))}
        disabled={!admin && item.disabled}
        className={selectedDeviceId === item.id ? classes.rowBgSelected : classes.rowBg}
        style={{ borderRadius: 15, padding: '8px' }}
      >
        <Stack direction="column" style={{ minWidth: '20px' }} />
        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <ListItemText
            sx={{ pl: 1 }}
            primary={item[devicePrimary]}
            primaryTypographyProps={{ noWrap: true, fontSize: '14px', fontWeight: 500, marginBottom: '4px' }}
            secondary={deviceSecondary && item[deviceSecondary] && `${item[deviceSecondary]}`}
            secondaryTypographyProps={{ noWrap: false, fontSize: '12px' }}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignSelf: 'stretch', margin: '0 4px' }}
          />
        </div>
        <Stack direction="column" alignSelf="stretch" justifyContent="space-between" alignItems="center" width="80px" style={{ marginLeft: '4px', padding: '2px 0', minWidth: '80px' }}>
          {createFeature(item, position, classes)}
        </Stack>
      </ListItemButton>
    </Box>
  );
}, areEqual);

export default DeviceRowSimple;
