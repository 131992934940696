/* eslint-disable no-unused-vars */
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import KeyIcon from '@mui/icons-material/Key';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import ReplayIcon from '@mui/icons-material/Replay';
import EditIcon from '@mui/icons-material/Edit';
import OutboxIcon from '@mui/icons-material/Outbox';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Divider,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../common/components/LocalizationProvider';
import {
  formatBoolean, formatPercentage, formatSpeed,
  formatSpeedRounded,
  formatStatus, formatTime, formatVoltage, getStatusColor,
} from '../common/util/formatter';
import { useAdministrator } from '../common/util/permissions';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { commandsActions, devicesActions } from '../store';

const iconSize = 20;

const useStyles = makeStyles((theme) => ({
  rowBg: {
    backgroundColor: 'white',
  },
  rowBgSelected: {
    backgroundColor: `${theme.palette.colors.primary}30`,
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  positive: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.positive,
  },
  neutral: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.neutral,
  },
  unknown: {
    fontSize: `${iconSize}px`,
    color: '#bdbdbd',
  },
  medium: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.medium,
  },
  negative: {
    fontSize: `${iconSize}px`,
    color: theme.palette.colors.negative,
  },
  text: {
    date: {
      fontSize: '11px',
      fontWeight: 700,
    },
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: 0,
  elevation: 0,
  textAlign: 'center',
  backgroundColor: 'transparent',
}));

const FadeMenu = ({ anchorEl, setAnchorEl, itemId, posId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          elevation: 1,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 6px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 24,
              height: 24,
              ml: 1,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '48%',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Box onClick={handleClose} style={{ display: 'flex' }}>
          <MenuItem sx={{ marginLeft: 1 }}>
            <ReplayIcon sx={{ color: '#5A6161', margin: 0, fontSize: 22 }} onClick={() => { dispatch(devicesActions.selectId(itemId)); navigate('/replay'); }} />
          </MenuItem>
          <MenuItem>
            <InfoIcon sx={{ color: '#5A6161', margin: 0, fontSize: 22 }} onClick={() => { dispatch(devicesActions.selectId(itemId)); if (posId > 0) navigate(`/position/${posId}`); }} />
          </MenuItem>
          <MenuItem>
            <OutboxIcon sx={{ color: '#5A6161', margin: 0, fontSize: 22 }} onClick={() => { dispatch(devicesActions.selectId(itemId)); dispatch(commandsActions.showCommandModal(true)); }} />
          </MenuItem>
          <MenuItem sx={{ marginRight: 1 }}>
            <EditIcon sx={{ color: '#5A6161', margin: 0, fontSize: 22 }} onClick={() => { dispatch(devicesActions.selectId(itemId)); dispatch(devicesActions.showEditModal(true)); }} />
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

const DeviceRow = ({ data, index, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const admin = useAdministrator();
  const hours12 = usePreference('twelveHourFormat');
  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };
  const isMobile = useMediaQuery('(max-width: 767px)');

  // const [lastValidPosition, setLastValidPosition] = useState(null);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');
  const speedUnit = useAttributePreference('speedUnit');
  const secondaryText = () => {
    let status;
    let date = '';
    let itemStatus = item.status;
    if (item.lastUpdate) {
      const now = moment();
      const targetDate = moment(item.lastUpdate);
      const diffInMinutes = now.diff(targetDate, 'minutes');
      if (diffInMinutes > 10) {
        itemStatus = 'offline';
      }
    }
    if (itemStatus === 'online' || !item.lastUpdate) {
      status = formatStatus(itemStatus, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
    }
    date = formatTime(item.lastUpdate, 'minutes', hours12);
    return (
      <>
        <span>
          {deviceSecondary && item[deviceSecondary] && `${item[deviceSecondary]}`}
        </span>
        <br />
        <span className={classes[getStatusColor(itemStatus)]} style={{ fontSize: 11 }}>{status}</span>
        <br />
        <span className={classes.text.date} style={{ fontSize: 11 }}>
          {date}
        </span>
      </>
    );
  };

  // useEffect(() => {
  //   if (position) {
  //     setLastValidPosition(position);
  //   }
  // }, [position]);

  return (
    <Box alignContent="space-between" style={style}>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.selectId(item.id))}
        disabled={!admin && item.disabled}
        className={selectedDeviceId === item.id ? classes.rowBgSelected : classes.rowBg}
        style={{ borderRadius: 15, padding: '8px' }}
      >
        <Stack direction="column" alignSelf="stretch" justifyContent="space-between" alignItems="flex-start" style={{ marginRight: '4px', padding: '2px 0' }}>
          {position?.attributes?.hasOwnProperty('charge') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionCharge')}`}>
                {position.attributes.charge ?
                  <PowerIcon className={classes.positive} /> :
                  <PowerOffIcon className={classes.negative} />}
              </Tooltip>
            </Item>
          )}

          {!position?.attributes?.hasOwnProperty('charge') && (
            <Item elevation={0}>
              <PowerOffIcon className={classes.unknown} />
            </Item>
          )}

          {/* {position?.attributes?.hasOwnProperty('alarm') && (
            <Item elevation={0}>
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="medium" style={{ padding: 0 }}>
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            </Item>
          )} */}

          {position?.attributes?.hasOwnProperty('ignition') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                {position.attributes.ignition ? (
                  <KeyIcon className={classes.positive} />
                ) : (
                  <KeyIcon className={classes.negative} />
                )}
              </Tooltip>
            </Item>
          )}
          {!position?.attributes?.hasOwnProperty('ignition') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionIgnition')}: ${t('deviceStatusUnknown')}`}>
                <KeyIcon className={classes.unknown} />
              </Tooltip>
            </Item>
          )}

          {position?.attributes?.hasOwnProperty('sat') && (
            <Tooltip title={`${t('positionSat')}: ${position.attributes.sat}`}>
              {position.attributes.sat ? (
                <SatelliteAltIcon className={classes.positive} />
              ) : (
                <SatelliteAltIcon className={classes.negative} />
              )}
            </Tooltip>
          )}
          {!position?.attributes?.hasOwnProperty('sat') && (
            <Tooltip title={`${t('positionSat')}: ${t('deviceStatusUnknown')}`}>
              <SatelliteAltIcon className={classes.unknown} />
            </Tooltip>
          )}

          {/* {position.hasOwnProperty('power') && (
              <Tooltip title={`${t('positionPower')}: ${formatBoolean(position.valid, t)}`}>
                <IconButton size="small">
                  {position.valid ? (
                    <CheckCircleOutlineIcon className={classes.positive} />
                  ) : (
                    <HighlightOffIcon className={classes.negative} />
                  )}
                </IconButton>
              </Tooltip>
              )}
              {!position.hasOwnProperty('power') && (
              <Tooltip title={`${t('positionPower')}: ${t('deviceStatusUnknown')}`}>
                <IconButton size="small">
                  <HighlightOffIcon className={classes.unknown} />
                </IconButton>
              </Tooltip>
              )} */}
        </Stack>

        <Divider flexItem orientation="vertical" color="gray" />

        <div style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <ListItemText
            sx={{ pl: 1 }}
            primary={item[devicePrimary]}
            primaryTypographyProps={{ noWrap: true, fontSize: '14px', fontWeight: 500, marginBottom: '4px' }}
            secondary={secondaryText()}
            secondaryTypographyProps={{ noWrap: false, fontSize: '12px' }}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignSelf: 'stretch', margin: '0 4px' }}
          />
          <Tooltip title={`${t('settingsTitle')}`}>
            <SettingsIcon onClick={handleMenuClick} style={{ color: '#5A6161', marginLeft: isMobile ? '200px' : '165px', marginTop: '-21px', position: 'absolute', fontSize: '19px' }} />
          </Tooltip>
          <FadeMenu anchorEl={menuAnchorEl} setAnchorEl={setMenuAnchorEl} itemId={item.id} posId={item.positionId} />
        </div>

        <Divider flexItem orientation="vertical" color="gray" />

        <Stack direction="column" alignSelf="stretch" justifyContent="space-between" alignItems="flex-start" width="80px" style={{ marginLeft: '4px', padding: '2px 0', minWidth: '80px' }}>
          {/* {position?.attributes?.hasOwnProperty('alarm') && (
            <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
              <IconButton size="small">
                <ErrorIcon fontSize="small" className={classes.negative} />
              </IconButton>
            </Tooltip>
          )} */}

          {position?.hasOwnProperty('speed') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionSpeed')}: ${formatSpeed(position.speed, speedUnit, t)}`}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
                  <SpeedIcon className={position.speed > 0 ? classes.positive : classes.unknown} />
                  <span style={{ fontSize: 11, paddingLeft: 2 }}>
                    {`${formatSpeedRounded(Math.round(position.speed), speedUnit, t)}`}
                  </span>
                </div>
              </Tooltip>
            </Item>
          )}
          {!position?.hasOwnProperty('speed') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionSpeed')}: ${t('deviceStatusUnknown')}`}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
                  <SpeedIcon className={classes.unknown} />
                </div>
              </Tooltip>
            </Item>
          )}

          {position?.attributes?.hasOwnProperty('batteryLevel') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.batteryLevel)}`}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
                  {position.attributes.batteryLevel > 70 ? (
                    position.attributes.charge
                      ? (<BatteryChargingFullIcon className={classes.positive} />)
                      : (<BatteryFullIcon className={classes.positive} />)
                  ) : position.attributes.batteryLevel > 30 ? (
                    position.attributes.charge
                      ? (<BatteryCharging60Icon className={classes.medium} />)
                      : (<Battery60Icon className={classes.medium} />)
                  ) : (
                    position.attributes.charge
                      ? (<BatteryCharging20Icon className={classes.negative} />)
                      : (<Battery20Icon className={classes.negative} />)
                  )}
                  <span style={{ fontSize: 11, paddingLeft: 2 }}>
                    {`${formatPercentage(position.attributes.batteryLevel)}`}
                  </span>
                </div>
              </Tooltip>
            </Item>
          )}
          {!position?.attributes?.hasOwnProperty('batteryLevel') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionBatteryLevel')}: ${t('deviceStatusUnknown')}`}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
                  <BatteryFullIcon className={classes.unknown} />
                </div>
              </Tooltip>
            </Item>
          )}

          {position?.attributes?.hasOwnProperty('power') && position?.attributes?.hasOwnProperty('power') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionPower')}: ${formatVoltage(position.attributes.power, t)}`}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
                  <ElectricalServicesIcon className={position.attributes.power > 0 ? classes.positive : classes.unknown} />
                  <span style={{ fontSize: 11, paddingLeft: 2 }} className={position.attributes.power === 0 ? classes.unknown : ''}>
                    {`${formatVoltage(position.attributes.power, t)}`}
                  </span>
                </div>
              </Tooltip>
            </Item>
          )}
          {!position?.attributes?.hasOwnProperty('power') && (
            <Item elevation={0}>
              <Tooltip title={`${t('positionPower')}: ${t('deviceStatusUnknown')}`}>
                <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'stretch', alignItems: 'center' }}>
                  <ElectricalServicesIcon className={classes.unknown} />
                </div>
              </Tooltip>
            </Item>
          )}
        </Stack>
      </ListItemButton>
    </Box>
  );
};

export default DeviceRow;
