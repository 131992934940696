import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-unused-vars
const CardStatusCard = ({ classes, theme, t, handleClose, position, device, card }) => {
  const [malagacarApiEnable] = useState(process.env.REACT_APP_MALAGACAR);
  const [deviceId, setDeviceId] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [carInfo, setCarInfo] = useState({});
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    const matricula = device?.matricula ?? device?.name?.substring(0, 7);
    if (matricula && matricula.length > 0) {
      const getCarData = async () => {
        if (malagacarApiEnable === 'enable' && device?.id && device?.id !== deviceId) {
          setHasError(false);
          setCarInfo({});
          setDeviceId(device?.id);
          const query = new URLSearchParams({ matricula });
          try {
            setLoading(true);
            const response = await fetch(`/api/devices/location?${query.toString()}`);
            if (response.ok) {
              const info = (await response.json())[0];
              if (info?.Localizacion === null) throw new Error('Error Matrícula');
              const newCarInfo = {
                model: info?.Modelo ?? '',
                group: info?.Grupo ?? '',
                localization: info?.Localizacion ?? '',
                initDate: '',
                endDate: '',
                client: '',
                workshop: '',
                contract: '',
              };
              if (info?.Localizacion === 'ALQUILADO') {
                newCarInfo.client = info?.Reserva?.Cliente ?? '';
                newCarInfo.contract = info?.Reserva?.Contrato ?? '';
                newCarInfo.initDate = info?.Reserva?.FechaEntrega ?
                  moment(info?.Reserva?.FechaEntrega, 'YYYYMMDD').format('DD-MM-YYYY') :
                  '';
                newCarInfo.endDate = info?.Reserva?.FechaRecogida ?
                  moment(info?.Reserva?.FechaRecogida, 'YYYYMMDD').format('DD-MM-YYYY') :
                  '';
              } else if (
                info?.Localizacion !== 'VENDIDO/BAJA' &&
                info?.Localizacion !== 'BAJA' &&
                info?.Localizacion !== 'VENDIDO' &&
                info?.Taller?.Datos
              ) {
                newCarInfo.workshop = info?.Taller?.Datos;
                newCarInfo.localization = 'TALLER';
                newCarInfo.initDate = info?.Taller?.FechaEntrada ?
                  moment(info?.Taller?.FechaEntrada, 'YYYYMMDD').format('DD-MM-YYYY') :
                  '';
                newCarInfo.endDate = info?.Taller?.FechaSalida ?
                  moment(info?.Taller?.FechaSalida, 'YYYYMMDD').format('DD-MM-YYYY') :
                  '';
              }
              setCarInfo(newCarInfo);
            } else {
              setCarInfo({});
              setHasError(true);
            }
            setLoading(false);
          } catch (error) {
            setCarInfo({});
            setHasError(true);
            setLoading(false);
          }
        }
        if (!device?.id) {
          setDeviceId(0);
          setCarInfo({});
        }
      };
      getCarData();
    } else {
      setDeviceId(0);
      setCarInfo({});
    }
  }, [device]);

  return (
    <Card elevation={4} className={classes.card} style={{ minWidth: '280px' }}>
      <CardHeader
        title={(
          <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
            {t(card) ?? 'Car Status'}
            {' '}
            { device?.matricula ?? device?.name?.substring(0, 7) }
          </Typography>
          )}
        align="center"
        action={(
          <IconButton
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
          </IconButton>
          )}
        sx={{
          backgroundColor: theme.palette.colors.primary,
          color: '#f5f5f5',
          paddingY: 0.1,
        }}
      />
      <CardContent className={classes.cardContent}>
        { hasError ? (
          <>
            <b><span style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}> Error al conectar! </span></b>
            <span style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', fontSize: 'small', color: 'GrayText' }}>
              Verifique la matrícula
            </span>
          </>
        ) : (
          <List dense className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText
                className={classes.listItemText}
                primary={(
                  <div>
                    <b>Grupo: </b>
                    {' '}
                    {carInfo?.group ?? ''}
                  </div>
                )}
              />
            </ListItem>
            { loading ? (
              <ListItem sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                <CircularProgress disableShrink size={60} />
              </ListItem>
            ) : (
              <>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    className={classes.listItemText}
                    primary={(
                      <div>
                        <b>Modelo: </b>
                        {' '}
                        {carInfo?.model ?? ''}
                      </div>
                      )}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    className={classes.listItemText}
                    primary={(
                      <div>
                        <b>Localización: </b>
                        {' '}
                        {carInfo?.localization ?? ''}
                      </div>
                        )}
                  />
                </ListItem>
                { carInfo?.workshop && (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      className={classes.listItemText}
                      primary={(
                        <div>
                          <b>Datos Taller: </b>
                          {' '}
                          {carInfo?.workshop ?? ''}
                        </div>
                        )}
                    />
                  </ListItem>
                )}
                <ListItem className={classes.listItem}>
                  <ListItemText
                    className={classes.listItemText}
                    primary={(
                      <div>
                        <b>
                          {carInfo?.workshop ? 'Fecha Entrada:' : 'Fecha Entrega:'}
                        </b>
                        {' '}
                        {carInfo?.initDate ?? ''}
                      </div>
                        )}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    className={classes.listItemText}
                    primary={(
                      <div>
                        <b>{carInfo?.workshop ? 'Fecha Salida:' : 'Fecha Recogida:'}</b>
                        {' '}
                        {carInfo?.endDate ?? ''}
                      </div>
                        )}
                  />
                </ListItem>
                { carInfo?.localization === 'ALQUILADO' && (
                  <>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        className={classes.listItemText}
                        primary={(
                          <div>
                            <b>Cliente: </b>
                            {' '}
                            {carInfo?.client ?? ''}
                          </div>
                          )}
                      />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        className={classes.listItemText}
                        primary={(
                          <div>
                            <b>Contrato: </b>
                            {' '}
                            {carInfo?.contract ?? ''}
                          </div>
                            )}
                      />
                    </ListItem>
                  </>
                )}
              </>
            ) }
          </List>
        )}

      </CardContent>
    </Card>
  );
};

export default CardStatusCard;
