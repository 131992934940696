/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import {
  Box, Checkbox, Collapse, FormControlLabel, FormGroup, ListItem, TextField, Typography, List as ListMaterial, ListItemButton,
} from '@mui/material';
import { List } from 'react-virtualized';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { devicesActions } from '../../store';
import { useTranslation } from '../../common/components/LocalizationProvider';

const listStyle = {
  width: '100%',
  bgcolor: 'background.paper',
  position: 'relative',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  height: '100%',
  minHeight: 340,
  maxHeight: 480,
  padding: 0,
};

const calcListHeight = (totalItems) => {
  const calc = (26 * totalItems + 2);
  if (calc > 360) return 362;
  return calc;
};

const InfinitiScroll = ({ selectedItems, setSelectedItems, always, setAlways, pois }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');

  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);

  const [itemsGroup, setItemsGroup] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [devicesWithGroup, setDevicesWithGroup] = useState([]);

  const [devicesWithoutGroup, setDevicesWithoutGroup] = useState([]);

  // useEffect(() => {
  //   if (always) {
  //     const newArray = [];
  //     const selectIdsDevices = [];
  //     Object.values(pois || devices).forEach((dev) => {
  //       newArray.push({ id: dev.id, name: dev.name });
  //       selectIdsDevices.push(dev.id);
  //     });
  //     dispatch(devicesActions.selectIds(selectIdsDevices));
  //     setSelectedItems(newArray);
  //   } else {
  //     dispatch(devicesActions.selectIds([]));
  //     if (selectedItems) {
  //       setSelectedItems(selectedItems);
  //     } else {
  //       setSelectedItems([]);
  //     }

  //     //
  //   }
  // }, [always]);

  useEffect(() => {
    const groupFilter = [];
    if (!filter) {
      setDevicesWithGroup(!pois ? Object.values(devices).filter((item) => Object.keys(groups).includes(`${item.groupId}`)) : Object.values(pois).filter((item) => Object.keys(groups).includes(`${item.groupId}`)));
      setDevicesWithoutGroup(!pois ? Object.values(devices).filter((item) => !Object.keys(groups).includes(`${item.groupId}`)) : Object.values(pois).filter((item) => !Object.keys(groups).includes(`${item.groupId}`)));

      setExpanded([]);
    } else {
      setDevicesWithGroup(!pois ? Object.values(devices).filter((item) => (Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)) : Object.values(pois).filter((item) => (Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)));
      setDevicesWithoutGroup(!pois ? Object.values(devices).filter((item) => (!Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)) : Object.values(pois).filter((item) => (!Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)));

      Object.values(devicesWithGroup).map((item) => (Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) && groupFilter.push(item.groupId));
      Object.values(devicesWithoutGroup).map((item) => (!Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1) && groupFilter.push(0));

      setExpanded(groupFilter);
    }
  }, [filter, pois]);

  useEffect(() => {
    if (selectedItems.length === Object.values(devices).length) {
      setAlways(true);
    } else {
      setAlways(false);
    }
  }, [selectedItems]);

  useEffect(() => {
    const itemsArr = [];
    for (const groupId in groups) {
      if (Object.hasOwn(groups, groupId)) {
        const groupName = groups[groupId].name;
        const idGroup = groups[groupId].id;
        const groupItems = [];
        for (const device of devicesWithGroup) {
          if (device.groupId === groups[groupId].id) {
            groupItems.push({ id: device.id, name: device.name });
          }
        }
        itemsArr.push({
          groupName,
          idGroup,
          items: groupItems,
        });
      }
    }
    const groupItems = [];
    for (const device of devicesWithoutGroup) {
      groupItems.push({ id: device.id, name: device.name });
    }
    itemsArr.push({
      groupName: 'Sin Grupo',
      idGroup: 0,
      items: groupItems,
    });
    setItemsGroup(itemsArr);
  }, [devicesWithGroup, devicesWithoutGroup]);

  const handleToggleGroup = (index) => {
    try {
      if (expanded.includes(index)) {
        setExpanded(expanded.filter((item) => item !== index));
      } else {
        setExpanded([...expanded, index]);
      }
    } catch (error) {
      // console.error('handleToggleGroup: ', error);
    }
  };

  const handleToggleItem = (item) => {
    try {
      if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
        setSelectedItems(
          selectedItems.filter((selectedItem) => selectedItem.id !== item.id),
        );
      } else {
        setSelectedItems([...selectedItems, { id: item.id, name: item.name }]);
      }
    } catch (error) {
      // console.error('handleToggleItem: ', error);
    }
  };

  const handleToggleGroupItems = (groupIndex) => {
    try {
      const group = itemsGroup[groupIndex];
      const groupItems = group.items;
      const selectIdsDevices = [];

      if (
        selectedItems.some((item) => groupItems.some((groupItem) => groupItem.id === item.id))
      ) {
        setSelectedItems(
          selectedItems.filter(
            (item) => !groupItems.some((groupItem) => groupItem.id === item.id),
          ),
        );
      } else {
        setSelectedItems([...selectedItems, ...groupItems]);
      }
      Object.values(selectedItems).forEach((dev) => {
        selectIdsDevices.push(dev.id);
      });
      dispatch(devicesActions.selectIds(selectIdsDevices));
    } catch (error) {
      // console.error('handleToggleGroupItems: ', error);
    }
  };

  const onChangeAll = (evt) => {
    if (evt) {
      const newArray = [];
      const selectIdsDevices = [];
      Object.values(pois || devices).forEach((dev) => {
        newArray.push({ id: dev.id, name: dev.name });
        selectIdsDevices.push(dev.id);
      });
      dispatch(devicesActions.selectIds(selectIdsDevices));
      setSelectedItems(newArray);
    } else {
      dispatch(devicesActions.selectIds([]));
      setSelectedItems([]);
    }
    setAlways(evt);
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              checked={always}
              onChange={(e) => onChangeAll(e.target.checked)}
            />
                )}
          label={t('notificationAlways')}
        />
      </FormGroup>
      <>
        <Box sx={{ padding: '12px 10px 0 5px' }}>
          <TextField
            label={t('sharedSearchDevices')}
            value={filter}
            onChange={(event) => (event.target.value ? setFilter(event.target.value.trimStart()) : setFilter(''))}
            fullWidth
            sx={{ padding: 0 }}
          />
        </Box>
        <ListMaterial sx={{ ...listStyle }}>
          {itemsGroup.map((group, groupIndex) => (

            group.items?.length > 0 && (
            <React.Fragment key={groupIndex}>
              <Box
                key={`${groupIndex}box`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0,
                  cursor: 'pointer',
                }}
              >
                <Box
                  onClick={() => handleToggleGroupItems(groupIndex)}
                  sx={{ paddingTop: 1 }}
                >
                  {selectedItems.filter((item) => group.items.some((it) => it.id === item.id)).length === 0 ? (
                    <CheckBoxOutlineBlankIcon />
                  ) : (
                    selectedItems.filter((item) => group.items.some((it) => it.id === item.id)).length === group.items.length ? (
                      <CheckBoxIcon />
                    ) : (
                      <IndeterminateCheckBoxIcon />
                    )
                  )}
                </Box>
                <ListItem
                  onClick={() => handleToggleGroup(group.idGroup)}
                  sx={{ justifyContent: 'space-between' }}
                >
                  {group.groupName}
                  {expanded.includes(group.idGroup) ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItem>
              </Box>
              <Collapse
                in={expanded.includes(group.idGroup)}
                timeout="auto"
                unmountOnExit
              >
                <List
                  width={400}
                  height={calcListHeight(group.items.length)}
                  rowHeight={26}
                  rowCount={group.items.length}
                  rowRenderer={({ key, index, style }) => {
                    const device = group.items[index];
                    return (

                      <ListItemButton
                        sx={{ paddingLeft: 3, width: 10, height: 10 }}
                        key={key}
                        style={style}
                        onClick={() => handleToggleItem(device)}
                      >
                        {selectedItems.some(
                          (it) => it.id === device?.id,
                        ) ? (
                          <CheckBoxIcon />
                          ) : (
                            <CheckBoxOutlineBlankIcon />
                          )}

                        <Typography key={key} className="textDeviceList">
                          {device?.name}
                        </Typography>

                      </ListItemButton>

                    );
                  }}
                  style={{ scrollbarWidth: 'thin', width: '100%' }}
                />
              </Collapse>
            </React.Fragment>
            )

          ))}
        </ListMaterial>
      </>
    </>
  );
};

export default InfinitiScroll;
