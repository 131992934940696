import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import maplibregl from 'maplibre-gl';
import {
  useCallback,
  useEffect, useId,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../common/components/LocalizationProvider';
import { formatTime } from '../common/util/formatter';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import useFeatures from '../common/util/useFeatures';
import { getAddress, getPopupContent } from './MapPositionsHelper';
import { map } from './core/MapView';
import { mapIconKey, vehicleIconKey } from './core/preloadImages';

let popupShow = true;
let popup;
let position;

const MapPositionsRoutes = ({ positions, onClick, showStatus, playing }) => {
  const selectedDeviceAnimation = useRef();
  const id = useId();
  const direction = `${id}-direction`;

  const selectedDeviceContentElement = useRef();

  const navigate = useNavigate();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const iconScale = useAttributePreference('iconScale', desktop ? 0.75 : 1);
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);

  const directionType = useAttributePreference('mapDirection', 'selected');
  const mapFollow = useSelector((state) => state.session.server.attributes?.mapFollow || false);

  const [selectedDeviceId, setSelectedDeviceId] = useState();

  const hours12 = usePreference('twelveHourFormat');
  const speedUnit = useAttributePreference('speedUnit');

  const [popupPosition, setPopupPosition] = useState();
  const [popupM, setPopupM] = useState();
  const features = useFeatures();

  useEffect(() => {
    if (popupM && map) {
      popupM.setLngLat(popupPosition);
    }
  }, [popupPosition]);

  useEffect(() => {
    popupM?.remove();
    popup?.remove();
    const popups = document.getElementsByClassName('maplibregl-popup');
    if (popups.length) {
      popups[0].remove();
    }
    popupShow = true;
    popup = undefined;
    position = undefined;
    return () => {
      popupM?.remove();
      popup?.remove();
      const popups = document.getElementsByClassName('maplibregl-popup');
      if (popups.length) {
        popups[0].remove();
      }
      popupShow = true;
      popup = undefined;
      position = undefined;
    };
  }, []);

  const createFeature = (devices, position, selectedPositionId) => {
    const device = devices[position.deviceId];
    let showDirection;
    switch (directionType) {
      case 'none':
        showDirection = false;
        break;
      case 'all':
        showDirection = true;
        break;
      default:
        showDirection = selectedPositionId === position.id;
        break;
    }

    let vehicleStatus = 'unknown';
    if (!position.attributes.ignition) {
      vehicleStatus = 'parking';
    }
    if (position.attributes.ignition) {
      vehicleStatus = 'ignition';
    }
    if (!position.valid) {
      vehicleStatus = 'no-gps';
    }

    return {
      id: position.id,
      deviceId: position.deviceId,
      name: device.name,
      fixTime: formatTime(position.fixTime, 'seconds', hours12),
      category: mapIconKey(device.category),
      color: showStatus ? position.attributes?.color || 'positive' : 'neutral',
      rotation: position.course,
      direction: showDirection,
      vehicleStatus: vehicleIconKey(vehicleStatus),
      position,
    };
  };

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  const onMapClick = useCallback((event) => {
    if (!event.defaultPrevented && onClick) {
      onClick();
    }
    selectedDeviceAnimation?.value?.remove();
  }, [onClick]);

  const onMarkerClick = useCallback((event) => {
    event.preventDefault();
    const feature = event.features[0];
    onClick(feature.properties.id, feature.properties.deviceId);
    popupM?.remove();
    // eslint-disable-next-line no-undef
    const popups = document.getElementsByClassName('maplibregl-popup');
    if (popups.length) {
      popups[0].remove();
    }
    if (popupShow) {
      popupShow = false;

      selectedDeviceContentElement.value = document.createElement('div');
      selectedDeviceContentElement.value.class = `selected-device-popup_${position.deviceId}`;
      selectedDeviceContentElement.value.innerHTML = getPopupContent(devices[position.deviceId], position, { hours12, speedUnit, t }, features, mapFollow, true);

      popup = new maplibregl.Popup()
        .setDOMContent(selectedDeviceContentElement.value)
        .setLngLat(event.lngLat)
        // .setHTML(getPopupContent(devices[position.deviceId], position, { hours12, speedUnit, t }, mapFollow, features, true))
        .addTo(map);

      selectedDeviceContentElement.value.addEventListener('click', (e) => {
        const targetId = e.target.id.split('_')[1];
        switch (targetId) {
          case 'google-street-view':
            // eslint-disable-next-line no-undef
            window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`, '_blank');
            break;
            // case 'replay':
            //   selectedDeviceContentElement.value?.remove();
            //   navigate('/replay');
            //   break;
          case 'info':
            navigate(`/position/${positions.id}`);
            break;
            // case 'commands':
            //   dispatch(commandsActions.showCommandModal(true));
            //   break;
          case 'address':
            getAddress(positions.longitude, positions.latitude, positions.deviceId);
            break;
          default:
            break;
        }
      });
      popup.on('close', () => {
        popupShow = true;
      });
      setPopupM(popup);
    } else {
      popupShow = true;
    }
  }, [onClick]);

  useEffect(() => {
    if (positions[0] && selectedDeviceId !== positions[0].deviceId) {
      map.setZoom(16);
      // eslint-disable-next-line no-undef
      selectedDeviceAnimation.value = document.createElement('div');
      selectedDeviceAnimation.value = new maplibregl.Marker({ element: selectedDeviceAnimation.value })
        .setLngLat([positions[0].longitude, positions[0].latitude])
        .addTo(map);
      setSelectedDeviceId(positions[0].deviceId);
    }

    return () => {
      selectedDeviceAnimation?.value?.remove();
    };
  }, [onClick]);

  // update popup
  useEffect(() => {
    position = { ...positions[0] };
    const pos = { lng: positions[0].longitude, lat: positions[0].latitude };
    if (playing && !map.getBounds().contains(pos)) {
      map.easeTo({
        center: pos,
        duration: 500,
      });
    }
    setPopupPosition(pos);
    if (popupM) {
      selectedDeviceContentElement.value = document.createElement('div');
      selectedDeviceContentElement.value.class = `selected-device-popup_${position.deviceId}`;
      selectedDeviceContentElement.value.innerHTML = getPopupContent(devices[position.deviceId], position, { hours12, speedUnit, t }, features, mapFollow, true);
      selectedDeviceContentElement.value.addEventListener('click', (e) => {
        const targetId = e.target.id.split('_')[1];
        switch (targetId) {
          case 'google-street-view':
            // eslint-disable-next-line no-undef
            window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`, '_blank');
            break;
            // case 'replay':
            //   selectedDeviceContentElement.value?.remove();
            //   navigate('/replay');
            //   break;
          case 'info':
            navigate(`/position/${positions.id}`);
            break;
            // case 'commands':
            //   dispatch(commandsActions.showCommandModal(true));
            //   break;
          case 'address':
            getAddress(positions.longitude, positions.latitude, positions.deviceId);
            break;
          default:
            break;
        }
      });
      popupM.setDOMContent(selectedDeviceContentElement.value);
    }
  }, [positions]);

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    map.addLayer({
      id,
      type: 'symbol',
      source: id,
      filter: ['!has', 'point_count'],
      layout: {
        'icon-image': 'vehicle-{vehicleStatus}',
        'icon-size': iconScale,
        'icon-allow-overlap': true,
        'icon-rotate': [
          'match',
          ['get', 'vehicleStatus'],
          ['no-gps', 'parking'],
          0,
          [
            'case',
            ['==', ['get', 'direction'], true],
            ['+', ['get', 'rotation'], -45],
            0,
          ],
        ],
        'icon-rotation-alignment': 'map',
      },
    });

    map.on('mouseenter', id, onMouseEnter);
    map.on('mouseleave', id, onMouseLeave);
    map.on('click', id, onMarkerClick);
    map.on('click', onMapClick);

    return () => {
      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('click', id, onMarkerClick);
      map.off('click', onMapClick);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }

      if (map.getLayer(direction)) {
        map.removeLayer(direction);
      }

      // important to remove this layer if it exists
      if (map.getLayer(`selected-device-${id}`)) {
        map.removeLayer(`selected-device-${id}`);
      }

      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [direction, onMarkerClick]);

  // selected device
  useEffect(() => {
    map.addLayer({
      id: `selected-device-${id}`,
      type: 'symbol',
      source: id,
      filter: [
        'all',
        ['!has', 'point_count'],
        ['==', 'deviceId', positions[0]?.deviceId ?? 0],
      ],
      layout: {
        'icon-image': 'vehicle-{vehicleStatus}',
        'icon-size': iconScale,
        'icon-allow-overlap': true,
        'icon-rotate': [
          'match',
          ['get', 'vehicleStatus'],
          ['no-gps', 'parking'],
          0,
          [
            'case',
            ['==', ['get', 'direction'], true],
            ['+', ['get', 'rotation'], -45],
            0,
          ],
        ],
        'icon-rotation-alignment': 'map',
      },
    });

    return () => {
      if (map.getLayer(`selected-device-${id}`)) {
        map.removeLayer(`selected-device-${id}`);
      }
    };
  }, [positions]);

  useEffect(() => {
    map.getSource(id)?.setData({
      type: 'FeatureCollection',
      features: positions.filter((it) => devices.hasOwnProperty(it.deviceId)).map((position) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [position.longitude, position.latitude],
        },
        properties: createFeature(devices, position, positions[0] && positions[0].id),
      })),
    });
  }, [direction, devices, positions]);

  return null;
};

export default MapPositionsRoutes;
