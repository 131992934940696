/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Table, TableBody, TableCell, TableHead, TableRow, styled, Container, Backdrop,
} from '@mui/material';
import {
  BounceLoader,
} from 'react-spinners';
import ReportFilter from './components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useCatch } from '../reactHelper';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import MapCamera from '../map/MapCamera';
import MapGeofence from '../map/MapGeofence';
import { formatTime } from '../common/util/formatter';
import { usePreference } from '../common/util/preferences';
import { prefixString } from '../common/util/stringUtils';
import MapMarkers from '../map/MapMarkers';
import TablePaginationFooter from './components/TablePaginationFooter';

const CustomContainer = styled(Container)({
  maxWidth: 1200,
});

const CombinedReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);

  const hours12 = usePreference('twelveHourFormat');

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pos, setPos] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const createMarkers = () => items.flatMap((item) => item.events
    .map((event) => item.positions.find((p) => event.positionId === p.id))
    .filter((position) => position != null)
    .map((position) => ({
      latitude: position.latitude,
      longitude: position.longitude,
    })));

  const handleSubmit = useCatch(async ({ deviceIds, groupIds, from, to }) => {
    const query = new URLSearchParams({ from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    groupIds.forEach((groupId) => query.append('groupId', groupId));
    setLoading(true);
    try {
      const response = await fetch(`/api/reports/combined?${query.toString()}`);
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    const pepe = [];
    if (items) {
      items.flatMap((item) => item.events
        .map(() => (
          pepe.push(item.deviceId)
        )));
      setPos(pepe.length);
    }
  }, [items]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportCombined']}>
        <div className={classes.container}>
          {Boolean(items.length) && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              {items.map((item) => (
                <MapRoutePath
                  key={item.deviceId}
                  name={devices[item.deviceId].name}
                  coordinates={item.route}
                />
              ))}
              <MapMarkers markers={createMarkers()} />
            </MapView>
            <MapCamera coordinates={items.flatMap((item) => item.route)} />
          </div>
          )}
          <CustomContainer>
            <div className={classes.containerMain}>
              <div className={classes.header}>
                <ReportFilter handleSubmit={handleSubmit} showOnly multiDevice includeGroups />
              </div>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('sharedDevice')}</TableCell>
                    <TableCell>{t('positionFixTime')}</TableCell>
                    <TableCell>{t('sharedType')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading ? items.flatMap((item) => item.events
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((event, index) => (
                      <TableRow key={event.id}>
                        <TableCell>{index ? '' : devices[item.deviceId].name}</TableCell>
                        <TableCell>{formatTime(event.eventTime, 'seconds', hours12)}</TableCell>
                        <TableCell>{t(prefixString('event', event.type))}</TableCell>
                      </TableRow>
                    ))) : (<TableShimmer columns={3} />)}
                </TableBody>
                <TablePaginationFooter
                  count={pos}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Table>

            </div>
          </CustomContainer>
        </div>

      </PageLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <BounceLoader
          // margin={7}
          size={80}
          color="#d63636"
        />
      </Backdrop>
    </>

  );
};

export default CombinedReportPage;
