/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from '../core/MapView';
import './labels.css';
import { statusCardActions } from '../../store';

const statusClass = (status) => `maplibregl-ctrl-icon maplibre-ctrl-labels maplibre-ctrl-labels-${status}`;

class LabelsControl {
  constructor(eventHandler, labels) {
    this.eventHandler = eventHandler;
    this.labels = labels;
  }

  onAdd() {
    this.button = document.createElement('button');
    this.button.className = statusClass(this.labels ? 'on' : 'off');
    this.button.type = 'button';
    this.button.onclick = () => this.eventHandler(this);

    this.container = document.createElement('div');
    this.container.className = 'maplibregl-ctrl-group maplibregl-ctrl';
    this.container.appendChild(this.button);

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
  }
}

const MapLabels = () => {
  const dispatch = useDispatch();
  const showLabel = useSelector((state) => state.statusCard.showLabel);
  const onClick = () => dispatch(statusCardActions.toggleShowLabel());
  const control = useMemo(() => new LabelsControl(onClick, showLabel), [onClick]);

  useEffect(() => {
    map.addControl(control);
    return () => map.removeControl(control);
  }, [onClick]);

  return null;
};

export default MapLabels;
