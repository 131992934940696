import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import PublicIcon from '@mui/icons-material/Public';
import RouteIcon from '@mui/icons-material/Route';
import InfoIcon from '@mui/icons-material/Info';
import OutboxIcon from '@mui/icons-material/Outbox';
import EditIcon from '@mui/icons-material/Edit';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import MyLocationIcon from '@mui/icons-material/MyLocation';
// import TitleIcon from '@mui/icons-material/Title';
// import FormatClearIcon from '@mui/icons-material/FormatClear';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Menu,
  MenuItem,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import { useAttributePreference, usePreference } from '../util/preferences';
import { useTranslation } from './LocalizationProvider';
import {
  commandsActions, devicesActions, sessionActions, statusCardActions,
} from '../../store';
import CardResume from './status-card-components/card-resume';
import CardNetwork from './status-card-components/card-network';
import CardLocation from './status-card-components/card-location';
import CardPower from './status-card-components/card-power';
import CardOdometer from './status-card-components/card-odometer';
import CardEvents from './status-card-components/card-events';
import CardStatusCard from './status-card-components/card-status-car';
import CardEngineCut from './status-card-components/card-engine-cut';
import usePersistedState from '../util/usePersistedState';

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: 'auto',
    // width: theme.dimensions.popupMaxWidth,
    minWidth: 242,
    maxWidth: 300,
    minHeight: '162px',
    maxHeight: '28vh',
    marginRight: 2,
    overflow: 'auto',
    scrollbarWidth: 'thin',
    borderRadius: 0,
  },
  cardContent: {
    padding: '0px !important',
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  rightWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    fontSize: '0.7rem',
  },
  subHeader: {
    display: 'flex',
    height: 14,
    alignItems: 'center',
    padding: 0,
  },
  date: {
    marginRight: theme.spacing(1),
    fontSize: 12,
    fontWeight: 'bold',
  },
  leftColumn: {
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: 'left',
  },
  rightColumn: {
    flexShrink: 0,
    justifyContent: 'flex-end',
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  unknown: {
    color: '#bdbdbd',
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  icon: {
    width: '20px',
    height: '20px',
    color: 'green',
  },
  actions: {
    justifyContent: 'space-between',
  },
  list: {
    padding: '0px !important',
  },
  listItem: {
    padding: '0 10px',
  },
  listItemText: {
    fontSize: '10pt',
    padding: '1px 0 0 0 !important',
    margin: '0px !important',
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  root: ({ desktopPadding }) => ({
    pointerEvents: 'auto',
    overflow: 'hidden',
    position: 'fixed',
    zIndex: 2,
    width: '100%',
    // border: '2px solid red',
    // left: '50%',
    [theme.breakpoints.up('md')]: {
      //   left: `calc(50% + ${desktopPadding} / 2)`,
      width: `calc(100% - ${desktopPadding} - ${theme.spacing(1.6)})`,
      bottom: theme.spacing(1.5),
      transform: `translateX(calc(${desktopPadding} + ${theme.spacing(1.6)}))`,
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(0)} + ${
        theme.dimensions.bottomBarHeight
      }px)`,
    },
    transform: 'translateX(-50%)',
  }),
}));
const initCardOptions = ['cardResume', 'cardNetwork', 'cardLocation', 'cardPower', 'cardEvents', 'cardOdometer', 'cardEngineCut'];
const cardOptions = (!process.env.REACT_APP_MALAGACAR || process.env.REACT_APP_MALAGACAR !== 'enable') ?
  initCardOptions :
  ['cardStatusCar', ...initCardOptions];

const RenderCard = ({ card, handleCloseCard, position, device, t }) => {
  // const hours24 = usePreference('24HourFormat');
  const classes = useStyles();
  const theme = useTheme();
  // const positionItems = useAttributePreference(
  //   'positionItems',
  //   'address,totalDistance,speed,course',
  // );

  switch (card) {
    case 'cardStatusCar':
      return (
        <CardStatusCard
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
          card={card}
        />
      );
    case 'cardResume':
      return (
        <CardResume
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
        />
      );
    case 'cardNetwork':
      return (
        <CardNetwork
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
          card={card}
        />
      );
    case 'cardLocation':
      return (
        <CardLocation
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
          card={card}
        />
      );
    case 'cardPower':
      return (
        <CardPower
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
          card={card}
        />
      );
    case 'cardOdometer':
      return (
        <CardOdometer
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
          card={card}
        />
      );
    case 'cardEvents':
      return (
        <CardEvents
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
          card={card}
        />
      );
    case 'cardEngineCut':
      return (
        <CardEngineCut
          handleClose={() => handleCloseCard(card)}
          classes={classes}
          theme={theme}
          t={t}
          position={position}
          device={device}
          card={card}
        />
      );
    default:
      return null;
  }
};

// eslint-disable-next-line no-unused-vars
const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const theme = useTheme();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  const mapFollow = useSelector((state) => state.session.server.attributes?.mapFollow || false);
  const device = useSelector((state) => state.devices.items[deviceId]);
  const showContent = useSelector((state) => state.statusCard.showContent);
  const user = useSelector((state) => state.session.user);
  const [selectedCards, setSelectedCards] = usePersistedState(`selectedCards${user.id ?? 0}`, cardOptions);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleCloseCard = (card) => {
    setSelectedCards(selectedCards.filter((item) => item !== card));
  };

  const handleToggleSelectCards = (e, card) => {
    e.stopPropagation();
    if (selectedCards.includes(card)) {
      handleCloseCard(card);
    } else {
      setSelectedCards([...selectedCards, card]);
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleAddCardMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleIconsOptions = (e, targetId) => {
    e.stopPropagation();
    switch (targetId) {
      case 'google-street-view':
        // eslint-disable-next-line no-undef
        if (position && position?.latitude && position?.longitude) window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`, '_blank');
        break;
      case 'replay':
        // selectedDeviceContentElement.value?.remove();
        navigate('/replay');
        break;
      case 'info':
        if (position) navigate(`/position/${position.id}`);
        break;
      case 'edit':
        dispatch(devicesActions.showEditModal(true));
        break;
      case 'commands':
        dispatch(commandsActions.showCommandModal(true));
        break;
      case 'follow':
        dispatch(sessionActions.updateMapFollow());
        break;
      // case 'label':
      //   dispatch(statusCardActions.toggleShowLabel());
      //   break;
      default:
        break;
    }
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <div className={classes.root}>
      <Accordion
        expanded={showContent}
        onChange={() => dispatch(statusCardActions.showContent(!showContent))}
        style={{ margin: 0, padding: 0, backgroundColor: '#fff0' }}
      >
        <AccordionSummary
          style={{
            margin: 0,
            padding: '0 3px 0 6px',
            minHeight: 5,
            flexDirection: 'row-reverse',
            backgroundColor: theme.palette.colors.primary,
            width: 350,
            height: 46,
          }}
          expandIcon={(
            <ExpandLessIcon
              sx={{ color: theme.palette.primary.contrastText }}
            />
          )}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={{ marginLeft: 5 }}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleAddCardMenu}
            >
              <BootstrapTooltip title={t('options') || 'Options'}>
                <LibraryAddIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </BootstrapTooltip>
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
                variant: 'menu',
              }}
              elevation={0}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  backgroundColor: '#ffffffcc',
                },
              }}
            >
              {cardOptions.map((option) => (
                <MenuItem
                  key={option}
                  onClick={(e) => handleToggleSelectCards(e, option)}
                  sx={{ padding: '0 5px' }}
                >
                  <ListItemIcon>
                    <Checkbox checked={selectedCards.includes(option)} />
                  </ListItemIcon>
                  <ListItemText primary={t(option) ?? option} />
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div style={{ marginLeft: 5 }}>
            <IconButton onClick={(e) => handleIconsOptions(e, 'google-street-view')}>
              <BootstrapTooltip title="Google Street View">
                <PublicIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </BootstrapTooltip>
            </IconButton>
          </div>
          <div style={{ marginLeft: 5 }}>
            <IconButton onClick={(e) => handleIconsOptions(e, 'replay')}>
              <BootstrapTooltip title={t('reportReplay') || 'Report Replay'}>
                <RouteIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </BootstrapTooltip>
            </IconButton>
          </div>
          <div style={{ marginLeft: 5 }}>
            <IconButton onClick={(e) => handleIconsOptions(e, 'info')}>
              <BootstrapTooltip title={t('sharedInfoTitle') || 'Info'}>
                <InfoIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </BootstrapTooltip>
            </IconButton>
          </div>
          <div style={{ marginLeft: 5 }}>
            <IconButton onClick={(e) => handleIconsOptions(e, 'commands')}>
              <BootstrapTooltip title={t('commandConsoleHeader') || 'Command Console'}>
                <OutboxIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </BootstrapTooltip>
            </IconButton>
          </div>
          <div style={{ marginLeft: 5 }}>
            <IconButton onClick={(e) => handleIconsOptions(e, 'edit')}>
              <BootstrapTooltip title={t('sharedEdit') || 'Edit'}>
                <EditIcon
                  sx={{ color: theme.palette.primary.contrastText }}
                />
              </BootstrapTooltip>
            </IconButton>
          </div>
          <div style={{ marginLeft: 5 }}>
            <IconButton onClick={(e) => handleIconsOptions(e, 'follow')}>
              { mapFollow ? (
                <MyLocationIcon
                  sx={{ color: theme.palette.colors.green }}
                />
              ) : (
                <BootstrapTooltip title={t('deviceFollow') || 'Follow'}>
                  <LocationSearchingIcon
                    sx={{ color: theme.palette.primary.contrastText }}
                  />
                </BootstrapTooltip>
              )}
            </IconButton>
          </div>
          {/* <div style={{ marginLeft: 5 }}>
            <IconButton onClick={(e) => handleIconsOptions(e, 'label')}>
              { showLabel ? (
                <TitleIcon
                  sx={{ color: theme.palette.colors.green }}
                />
              ) : (
                <BootstrapTooltip title="Labels">
                  <FormatClearIcon
                    sx={{ color: theme.palette.primary.contrastText }}
                  />
                </BootstrapTooltip>
              )}
            </IconButton>
          </div> */}
        </AccordionSummary>
        <AccordionDetails style={{ margin: 0, padding: 0 }}>
          <div
            style={{
              width: '100%',
              overflow: 'auto',
              scrollbarWidth: 'thin',
              minHeight: '140px',
              backgroundColor: '#ffffffcc',
              border: `2px solid ${theme.palette.colors.primary}`,
              display: 'flex',
              flexDirection: 'row',
              cursor: 'default',
            }}
          >
            {device &&
              selectedCards.map((card) => (
                <RenderCard
                  key={card}
                  card={card}
                  handleCloseCard={handleCloseCard}
                  position={position}
                  device={device}
                  t={t}
                />
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default StatusCard;
