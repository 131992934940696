import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAttributePreference } from '../../util/preferences';
import { formatAltitude, formatCourse, formatSpeedRounded } from '../../util/formatter';
// import { getBatteryStatus } from '../../util/formatter';

// eslint-disable-next-line no-unused-vars
const CardLocation = ({ classes, theme, t, handleClose, position, device, card }) => {
  const speedUnit = useAttributePreference('speedUnit');
  const altitudeUnit = useAttributePreference('altitudeUnit');
  const addressEnabled = useSelector((state) => state.session.server.geocoderEnabled);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  useEffect(() => {
    const showAddress = async () => {
      if (addressEnabled && position?.latitude && position?.longitude && (position?.latitude !== latitude || position?.longitude !== longitude)) {
        setLatitude(position?.latitude);
        setLongitude(position?.longitude);
        setAddress('');
        const query = new URLSearchParams({ latitude: position?.latitude, longitude: position?.longitude });
        const response = await fetch(`/api/server/geocode?${query.toString()}`);
        if (response.ok) {
          setAddress(await response.text());
        } else {
          setAddress('');
          // throw Error(await response.text());
        }
      }
      if (!addressEnabled || !position?.latitude || !position?.longitude) {
        setLatitude('');
        setLongitude('');
        setAddress('');
      }
    };
    showAddress();
  }, [position]);

  return (
    <Card elevation={4} className={classes.card} style={{ minWidth: '300px' }}>
      <CardHeader
        title={(
          <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
            {t(card) ?? 'Location'}
          </Typography>
          )}
        align="center"
        action={(
          <IconButton
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
          </IconButton>
          )}
        sx={{
          backgroundColor: theme.palette.colors.primary,
          color: '#f5f5f5',
          paddingY: 0.1,
        }}
      />
      <CardContent className={classes.cardContent}>
        <List dense className={classes.list}>
          <ListItem className={classes.listItem}>
            { position && (
              <ListItemText
                className={classes.listItemText}
                primary={(
                  <div style={{ textAlign: 'center', padding: '4px 0' }}>
                    <a
                      style={{ fontSize: 12, color: '#0083df', textDecoration: 'underline dotted' }}
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${position?.latitude}%2C${position?.longitude}`}
                      rel="noreferrer"
                    >
                      {position?.latitude}
                      ,
                      {position?.longitude}
                    </a>
                  </div>
                )}
              />
            )}
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{ width: '55%' }}
                    >
                      <b>Satélites: </b>
                      {' '}
                      <span className={
                        position && !Number.isNaN(position?.attributes?.sat) ?
                          (position?.attributes?.sat > 3 ? classes.positive : classes.negative) :
                          null
                      }
                      >
                        { position?.attributes?.sat ?? '' }
                      </span>
                    </div>
                    <div style={{ width: '45%' }}>
                      <b>
                        Dirección:
                        {' '}
                        {' '}
                        { position?.course ? formatCourse(position?.course) : '' }
                      </b>

                    </div>
                  </div>

                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '55%' }}>
                      <b>Velocidad: </b>
                      {' '}
                      { position && !Number.isNaN(position?.speed) ? `${formatSpeedRounded(Math.round(position?.speed), speedUnit, t)}` : ''}
                    </div>
                    <div style={{ width: '45%' }}>
                      <b>Altitud: </b>
                      {' '}
                      { position && !Number.isNaN(position?.altitude) ? formatAltitude(position?.altitude, altitudeUnit, t) : '' }
                    </div>
                  </div>

                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>Dirección: </b>
                  {' '}
                  { address }
                </div>
                )}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default CardLocation;
