/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import 'maplibre-gl/dist/maplibre-gl.css';
import React, {
  useState,
} from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from '../LocalizationProvider';
import { useAttributePreference } from '../../util/preferences';
import { distanceToMeters } from '../../util/converter';
import { useCatch } from '../../../reactHelper';

const OdometerEdit = ({ deviceId, valueOdometer, open, setOpen, loading, setLoading }) => {
  const t = useTranslation();
  const [odometerValue, setOdometerValue] = useState(valueOdometer ?? '');
  const distanceUnit = useAttributePreference('distanceUnit');
  const [invalidValue, setInvalidValue] = useState(false);

  const handleCloseGeofence = () => {
    setLoading(false);
    setOpen(false);
    setInvalidValue(false);
    setOdometerValue('');
  };

  const checkValidSpeed = (value) => {
    const numberValue = Number(value);
    if (!Number.isNaN(numberValue) && numberValue >= 0 && distanceToMeters(numberValue, distanceUnit) <= 4294967295) {
      return true;
    }
    return false;
  };

  const handleSpeedChange = (value) => {
    if (checkValidSpeed(value)) {
      setOdometerValue(Number(value));
      setInvalidValue(false);
    } else { setOdometerValue(value.length > 0 ? value.slice(0, -1) : ''); }
  };

  const handleSendCommand = useCatch(async (speedLimit) => {
    if (speedLimit) {
      setLoading(true);
      try {
        const command = {
          type: 'custom',
          attributes: {
            data: `MILEAGEC,${distanceToMeters(odometerValue, distanceUnit).toFixed(0)}#`,
          },
        };
        const devicesId = [deviceId];
        await fetch('/api/commands/sends', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ devicesId, command }),
        });
      } catch (error) {
        //
      }
      setLoading(false);
      handleCloseGeofence();
    }
  });

  return (
    <Dialog
      open={open}
      onClose={handleCloseGeofence}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const { speedLimit } = formJson;
          if (checkValidSpeed(speedLimit)) {
            handleSendCommand({ speedLimit });
          } else { setInvalidValue(true); }
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        Modificar Odometro
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 440, maxHeight: 900, scrollbarWidth: 'thin' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <TextField
            margin="dense"
            id="speedLimit"
            name="speedLimit"
            type="number"
            variant="outlined"
            fullWidth
            size="small"
            label={distanceUnit ?? 'km'}
            value={odometerValue}
            onChange={(event) => {
              handleSpeedChange(event.target.value);
            }}
            disabled={loading}
          />
        </div>
        {invalidValue && (
          <Typography variant="caption" display="block" gutterBottom color="red" sx={{ padding: 1 }}>
            Valor incorrecto.
          </Typography>
        )}
        <Typography variant="caption" display="block" gutterBottom sx={{ paddingTop: 2 }}>
          Se actualiza e incrementa de acuerdo a los datos GPS.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          onClick={handleCloseGeofence}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          Actualizar
        </Button>
      </DialogActions>
      <div style={{ width: '100%', height: 4 }}>
        {loading && (<LinearProgress />)}
      </div>
    </Dialog>

  );
};

export default OdometerEdit;
