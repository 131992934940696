/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Button,
  Alert,
  Card,
  Skeleton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import { formatNotificationTitle } from '../common/util/formatter';
import PageLayout from '../common/components/PageLayout';
import useFeatures from '../common/util/useFeatures';
import { useEffectAsync } from '../reactHelper';
import SelectField from '../common/components/SelectField';
import InfinitiScroll from './common/InfinitiScroll';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  card: {
    textAlign: 'center',
    padding: (3, 3, 3, 6),
    background: theme.palette.colors.primary,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const GroupConnectionsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const accordionRef = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);
  const [itemsGroup, setItemsGroup] = useState([]);
  const [itemsGeoLink, setItemsGeoLink] = useState();
  const [itemsNotiLink, setItemsNotiLink] = useState();
  const [itemsMaintLink, setItemsMaintLink] = useState();
  const [always, setAlways] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [nameGroup, setNameGroup] = useState('');

  const [devicesWithGroup, setDevicesWithGroup] = useState([]);

  const [devicesWithoutGroup, setDevicesWithoutGroup] = useState([]);

  const [filter, setFilter] = useState('');

  const [expanded, setExpanded] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const [trueAdd, setTrueAdd] = useState(false);
  const features = useFeatures();

  const windowsBack = () => {
    navigate(-1);
  };

  const handleAccordionChange = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleSave = async () => {
    const dataDevices = [];
    Object.values(selectedItems).map((item) => (
      dataDevices.push(item.id)
    ));
    const data = {
      groupId: Number(id),
      devicesIds: dataDevices,
      maintenanceId: itemsMaintLink,
      notificationId: itemsNotiLink,
      geofenceId: itemsGeoLink,
    };
    const response = await fetch('/api/groups/multiple', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      setTrueAdd(true);

      await sleep(1e3);
      navigate(-1);
    }
  };

  useEffectAsync(async () => {
    const response = await fetch(`/api/groups/${id}`);
    if (response.ok) {
      const nameG = await response.json();
      setNameGroup(nameG.name);
    }
  }, []);

  // useEffect(() => {
  //   if (!filter) {
  //     setDevicesWithGroup(
  //       Object.values(devices).filter((item) => Object.keys(groups).includes(`${item.groupId}`)),
  //     );
  //     setDevicesWithoutGroup(
  //       Object.values(devices).filter(
  //         (item) => !Object.keys(groups).includes(`${item.groupId}`),
  //       ),
  //     );
  //   } else {
  //     setDevicesWithGroup(
  //       Object.values(devices).filter(
  //         (item) => Object.keys(groups).includes(`${item.groupId}`) &&
  //           item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
  //       ),
  //     );
  //     setDevicesWithoutGroup(
  //       Object.values(devices).filter(
  //         (item) => !Object.keys(groups).includes(`${item.groupId}`) &&
  //           item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1,
  //       ),
  //     );
  //   }
  // }, [filter, groups, devices, activeStep]);

  // useEffect(() => {
  //   const itemsArr = [];
  //   for (const groupId in groups) {
  //     if (Object.hasOwn(groups, groupId)) {
  //       const groupName = groups[groupId].name;
  //       const groupItems = [];

  //       for (const device of devicesWithGroup) {
  //         if (device.groupId === groups[groupId].id) {
  //           groupItems.push({ id: device.id, name: device.name });
  //         }
  //       }
  //       itemsArr.push({
  //         groupName,
  //         items: groupItems,
  //       });
  //     }
  //   }
  //   const groupItems = [];
  //   for (const device of devicesWithoutGroup) {
  //     groupItems.push({ id: device.id, name: device.name });
  //   }
  //   itemsArr.push({
  //     groupName: 'Sin Grupo',
  //     items: groupItems,
  //   });
  //   setItemsGroup(itemsArr);
  // }, [devicesWithGroup, devicesWithoutGroup]);

  useEffectAsync(async () => {
    const response = await fetch(`/api/groups/device?groupId=${id}`);
    if (response.ok) {
      const itemsRefact = await response.json();
      setSelectedItems(itemsRefact.map((item) => ({
        id: item.id,
        name: item.name,
      })));
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/geofences?groupId=${id}`);
    if (response.ok) {
      const itemsRefact = await response.json();
      itemsRefact.map((item) => setItemsGeoLink(item.id));
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/notifications?groupId=${id}`);
    if (response.ok) {
      const itemsRefact = await response.json();
      itemsRefact.map((item) => setItemsNotiLink(item.id));
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/maintenance?groupId=${id}`);
    if (response.ok) {
      const itemsRefact = await response.json();
      itemsRefact.map((item) => setItemsMaintLink(item.id));
    } else {
      throw Error(await response.text());
    }
  }, []);

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'groupDialog', 'sharedConnections']}
    >
      <Container maxWidth="xs" className={classes.container}>
        <Card className={classes.card}>
          <Typography fontSize="20px" color="#ffffff">
            GRUPO
          </Typography>
          <Typography fontSize="12px" color="#ffffff">
            {nameGroup}
          </Typography>
        </Card>
        {/* <Typography variant="h6">{`Group: ${nameGroup}` }</Typography> */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedConnections')}
            </Typography>
          </AccordionSummary>

          <AccordionDetails className={classes.details}>
            {/* <LinkField
              endpointAll="/api/geofences"
              endpointLinked={`/api/geofences?groupId=${id}`}
              baseId={id}
              keyBase="groupId"
              keyLink="geofenceId"
              label={t('sharedGeofences')}
            /> */}

            <SelectField
              emptyValue={null}
              endpoint="/api/geofences"
              value={itemsGeoLink || []}
              onChange={(e) => setItemsGeoLink(e.target.value)}
              // data={deviceFields.filter((field) => field.id === 'name')}
             // titleGetter={(it) => t(it.name)}
              label={t('sharedGeofences')}
            />

            {/* <LinkField
              endpointAll="/api/notifications"
              endpointLinked={`/api/notifications?groupId=${id}`}
              baseId={id}
              keyBase="groupId"
              keyLink="notificationId"
              titleGetter={(it) => formatNotificationTitle(t, it)}
              label={t('sharedNotifications')}
            /> */}

            <SelectField
              emptyValue={null}
              endpoint="/api/notifications"
              value={itemsNotiLink || []}
              onChange={(e) => setItemsNotiLink(e.target.value)}
              // data={deviceFields.filter((field) => field.id === 'name')}
              titleGetter={(it) => formatNotificationTitle(t, it)}
              label={t('sharedNotifications')}
            />

            {/* {!features.disableDrivers && (
              <LinkField
                endpointAll="/api/drivers"
                endpointLinked={`/api/drivers?groupId=${id}`}
                baseId={id}
                keyBase="groupId"
                keyLink="driverId"
                label={t('sharedDrivers')}
              />
            )} */}
            {/* {!features.disableComputedAttributes && (
              <LinkField
                endpointAll="/api/attributes/computed"
                endpointLinked={`/api/attributes/computed?groupId=${id}`}
                baseId={id}
                keyBase="groupId"
                keyLink="attributeId"
                titleGetter={(it) => it.description}
                label={t('sharedComputedAttributes')}
              />
            )} */}
            {/* <LinkField
              endpointAll="/api/commands"
              endpointLinked={`/api/commands?groupId=${id}`}
              baseId={id}
              keyBase="groupId"
              keyLink="commandId"
              titleGetter={(it) => it.description}
              label={t('sharedSavedCommands')}
            /> */}

            {/* <LinkField
                endpointAll="/api/maintenance"
                endpointLinked={`/api/maintenance?groupId=${id}`}
                baseId={id}
                keyBase="groupId"
                keyLink="maintenanceId"
                label={t('sharedMaintenance')}
              /> */}

            {!features.disableMaintenance && (
            <SelectField
              emptyValue={null}
              endpoint="/api/maintenance"
              value={itemsMaintLink || []}
              onChange={(e) => setItemsMaintLink(e.target.value)}
              // data={deviceFields.filter((field) => field.id === 'name')}
              // titleGetter={(it) => formatNotificationTitle(t, it)}
              label={t('sharedMaintenance')}
            />
            )}

            {/* <LinkFieldSelect
              endpointAll="/api/devices"
              endpointLinked={`/api/groups/device?groupId=${id}`}
              group="group"
              locations={location}
              baseId={id}
              keyBase="groupId"
              keyLink="deviceId"
              label={t('deviceTitle')}
            /> */}
            {/* <LinkField
              endpointAll="/api/maintenance"
              endpointLinked={`/api/maintenance?groupId=${id}`}
              baseId={id}
              keyBase="groupId"
              keyLink="maintenanceId"
              label={t('sharedSearchDevices')}
            /> */}
            <div ref={accordionRef}>
              <Accordion
                expanded={isOpen}
                onChange={handleAccordionChange}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('deviceTitle')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <InfinitiScroll selectedItems={selectedItems} setSelectedItems={setSelectedItems} always={always} setAlways={setAlways} enableAlways />
                </AccordionDetails>
              </Accordion>
            </div>

            {/* <Paper sx={{ paddingLeft: 1 }} /> */}
          </AccordionDetails>
          {trueAdd && (

          <Alert severity="success" color="success">
            Datos Salvados Satisfactoriamente...
          </Alert>

          )}
          <div className={classes.buttons}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => windowsBack()}
            >
              {t('atributeAtras')}
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => handleSave()}
            >
              {t('sharedSave')}
            </Button>
          </div>
        </Accordion>
      </Container>
    </PageLayout>
  );
};

export default GroupConnectionsPage;
