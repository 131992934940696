import React from 'react';
import {
  TableFooter, TableRow, TablePagination,
} from '@mui/material';
import TablePaginationActions from './TablePaginationActions';

const TablePaginationFooter = ({ count, rowsPerPage, page, onPageChange, onRowsPerPageChange }) => (
  <TableFooter>
    <TableRow>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
  </TableFooter>
);

export default TablePaginationFooter;
