/* eslint-disable no-unused-vars */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
  Card,
  Skeleton,
  Autocomplete,
  TextField,
  createFilterOptions,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import { formatNotificationTitle } from '../common/util/formatter';
import { useEffectAsync } from '../reactHelper';
import SettingsMenu from './components/SettingsMenu';
import LinkFieldSelect from '../common/components/LinkFieldSelect';
import InfinitiScroll from './common/InfinitiScroll';
import SelectField from '../common/components/SelectField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  card: {
    textAlign: 'center',
    padding: (3, 3, 3, 6),
    background: theme.palette.colors.primary,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const UserConnectionsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const { id } = useParams();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [always, setAlways] = useState(false);
  const [trueAdd, setTrueAdd] = useState(false);
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectGeo, setSelectGeo] = useState([]);
  const [selectNotif, setSelectNotif] = useState([]);
  const [selectCalend, setSelectCalend] = useState([]);
  const [selectUsers, setSelectUsers] = useState([]);
  const [selectAtt, setSelectAtt] = useState([]);
  const [selectDrivers, setSelectDrivers] = useState([]);
  const [selectComm, setSelectComm] = useState([]);
  const [selectMain, setSelectMain] = useState([]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/users/${id}`);
      const resDevices = await fetch(`/api/devices?userId=${id}`);
      const resGroup = await fetch(`/api/groups?userId=${id}`);
      const resGeo = await fetch(`/api/geofences?userId=${id}`);
      const resNotif = await fetch(`/api/notifications?userId=${id}`);
      const resCale = await fetch(`/api/calendars?userId=${id}`);
      const resUsers = await fetch(`/api/users?userId=${id}`);
      const resAtt = await fetch(`/api/attributes/computed?userId=${id}`);
      const resDrivers = await fetch(`/api/drivers?userId=${id}`);
      const resComm = await fetch(`/api/commands?userId=${id}`);
      const resMain = await fetch(`/api/maintenance?userId=${id}`);

      if (response.ok) {
        setUser(await response.json());
      } else {
        throw Error(await response.text());
      }
      if (resDevices.ok) {
        setSelectedItems(await resDevices.json());
      } else {
        throw Error(await resDevices.text());
      }
      if (resGroup.ok) {
        setSelectedGroup(await resGroup.json());
      } else {
        throw Error(await resGroup.text());
      }
      if (resGeo.ok) {
        setSelectGeo(await resGeo.json());
      } else {
        throw Error(await resGeo.text());
      }
      if (resNotif.ok) {
        setSelectNotif(await resNotif.json());
      } else {
        throw Error(await resNotif.text());
      }
      if (resCale.ok) {
        setSelectCalend(await resCale.json());
      } else {
        throw Error(await resCale.text());
      }
      if (resUsers.ok) {
        setSelectUsers(await resUsers.json());
      } else {
        throw Error(await resUsers.text());
      }
      if (resAtt.ok) {
        setSelectAtt(await resAtt.json());
      } else {
        throw Error(await resAtt.text());
      }
      if (resDrivers.ok) {
        setSelectDrivers(await resDrivers.json());
      } else {
        throw Error(await resDrivers.text());
      }
      if (resComm.ok) {
        setSelectComm(await resComm.json());
      } else {
        throw Error(await resComm.text());
      }
      if (resMain.ok) {
        setSelectMain(await resMain.json());
      } else {
        throw Error(await resMain.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const windowsBack = () => {
    navigate(-1);
  };

  // const disable = (selectedItems.length !== 0 && selectedGroup.length !== 0 && selectGeo.length !== 0 && selectNotif.length !== 0 && selectCalend.length !== 0 && selectUsers.length !== 0 && selectAtt.length !== 0 && selectDrivers.length !== 0 && selectComm.length !== 0 && selectMain.length !== 0);

  const handleSave = async () => {
    const devicesIds = Object.values(selectedItems).map((dev) => dev.id);
    const groupsIds = Object.values(selectedGroup).map((dev) => dev.id);
    const geofencesIds = Object.values(selectGeo).map((dev) => dev.id);
    const notificationsIds = Object.values(selectNotif).map((dev) => dev.id);
    const calendarsIds = Object.values(selectCalend).map((dev) => dev.id);
    const usersIds = Object.values(selectUsers).map((dev) => dev.id);
    const atributesCalcIds = Object.values(selectAtt).map((dev) => dev.id);
    const driversIds = Object.values(selectDrivers).map((dev) => dev.id);
    const comandsIds = Object.values(selectComm).map((dev) => dev.id);
    const maintenanceIds = Object.values(selectMain).map((dev) => dev.id);

    const data = {
      userId: Number(id),
      devicesIds,
      groupsIds,
      geofencesIds,
      notificationsIds,
      calendarsIds,
      usersIds,
      atributesCalcIds,
      driversIds,
      comandsIds,
      maintenanceIds,
    };
    const res = await fetch('/api/users/links', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (res.ok) {
      setTrueAdd(true);

      await sleep(1e3);
      navigate(-1);
    } else {
      throw Error(await res.text());
    }
  };

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'settingsUser', 'sharedConnections']}
    >
      <Container maxWidth="xs" className={classes.container}>
        <Card className={classes.card}>
          <Typography fontSize="20px" color="#ffffff">
            {!loading ? user.name : <Skeleton width={220} style={{ margin: '0 auto' }} />}
          </Typography>
          <Typography fontSize="12px" color="#ffffff">
            {!loading ? user.email : <Skeleton width={180} style={{ margin: '0 auto' }} />}
          </Typography>
        </Card>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {t('sharedConnections')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('reportDevice')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InfinitiScroll selectedItems={selectedItems} setSelectedItems={setSelectedItems} always={always} setAlways={setAlways} />
              </AccordionDetails>
            </Accordion>
            <LinkFieldSelect
              endpointAll="/api/groups?all=true"
              // endpointLinked={`/api/groups?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="groupId"
              label={t('settingsGroups')}
              notsave
              selectData={selectedGroup}
              setSelectData={setSelectedGroup}
              set
            />
            <LinkFieldSelect
              endpointAll="/api/geofences?all=true"
            // endpointLinked={`/api/geofences?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="geofenceId"
              label={t('sharedGeofences')}
              selectData={selectGeo}
              setSelectData={setSelectGeo}
              notsave
            />
            <LinkFieldSelect
              endpointAll="/api/notifications?all=true"
             // endpointLinked={`/api/notifications?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="notificationId"
              titleGetter={(it) => formatNotificationTitle(t, it, true)}
              label={t('sharedNotifications')}
              notsave
              selectData={selectNotif}
              setSelectData={setSelectNotif}
            />
            <LinkFieldSelect
              endpointAll="/api/calendars?all=true"
             // endpointLinked={`/api/calendars?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="calendarId"
              label={t('sharedCalendars')}
              notsave
              selectData={selectCalend}
              setSelectData={setSelectCalend}
            />
            <LinkFieldSelect
              endpointAll="/api/users?all=true"
              // endpointLinked={`/api/users?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="managedUserId"
              label={t('settingsUsers')}
              notsave
              selectData={selectUsers}
              setSelectData={setSelectUsers}
            />
            <LinkFieldSelect
              endpointAll="/api/attributes/computed?all=true"
              // endpointLinked={`/api/attributes/computed?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="attributeId"
              titleGetter={(it) => it.description}
              label={t('sharedComputedAttributes')}
              notsave
              selectData={selectAtt}
              setSelectData={setSelectAtt}
            />
            <LinkFieldSelect
              endpointAll="/api/drivers?all=true"
              // endpointLinked={`/api/drivers?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="driverId"
              label={t('sharedDrivers')}
              notsave
              selectData={selectDrivers}
              setSelectData={setSelectDrivers}
            />
            <LinkFieldSelect
              endpointAll="/api/commands?all=true"
              // endpointLinked={`/api/commands?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="commandId"
              titleGetter={(it) => it.description}
              label={t('sharedSavedCommands')}
              notsave
              selectData={selectComm}
              setSelectData={setSelectComm}
            />
            <LinkFieldSelect
              endpointAll="/api/maintenance?all=true"
              // endpointLinked={`/api/maintenance?userId=${id}`}
              baseId={id}
              keyBase="userId"
              keyLink="maintenanceId"
              label={t('sharedMaintenance')}
              notsave
              selectData={selectMain}
              setSelectData={setSelectMain}
            />
          </AccordionDetails>
          {trueAdd && (

          <Alert severity="success" color="success">
            Datos Salvados Satisfactoriamente...
          </Alert>

          )}
          <div className={classes.buttons}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              onClick={() => windowsBack()}
            >
              {t('atributeAtras')}
            </Button>
            <Button
              // disabled={!disable}
              type="button"
              color="primary"
              variant="contained"
              onClick={() => handleSave()}
            >
              {t('sharedSave')}
            </Button>
          </div>
        </Accordion>
      </Container>
    </PageLayout>
  );
};

export default UserConnectionsPage;
