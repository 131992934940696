import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import React, { useEffect, useState } from 'react';
import Odometer from 'react-odometerjs';
import { useAttributePreference } from '../../util/preferences';
import { useLocalization } from '../LocalizationProvider';
import { distanceFromMeters } from '../../util/converter';
import OdometerEdit from './odometer-edit';

// eslint-disable-next-line no-unused-vars
const CardOdometer = ({ classes, theme, t, handleClose, position, device, card }) => {
  const language = useLocalization()?.language ?? 'es';
  const format = language !== 'es' ? '(,ddd).ddd' : '(.ddd),ddd';
  const distanceUnit = useAttributePreference('distanceUnit');
  const [valueOdometer, setValueOdometer] = useState('');
  const [valueHours, setValueHours] = useState('');

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (position?.attributes?.hasOwnProperty('odometer')) {
      let newValue = distanceFromMeters(position?.attributes?.odometer, distanceUnit).toFixed(1);
      if (language === 'es') {
        newValue = newValue.replace('.', ',');
      }
      setValueOdometer(newValue);
    } else {
      setValueOdometer('');
    }
    if (position?.attributes?.hasOwnProperty('hours')) {
      let newValue = (position.attributes.hours / 3600000).toFixed(2);
      if (language === 'es') {
        newValue = newValue.replace('.', ',');
      }
      setValueHours(newValue);
    } else {
      setValueHours('');
    }
  }, [position, device]);

  return (
    <Card elevation={4} className={classes.card}>
      <CardHeader
        title={(
          <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
            {t(card) ?? 'Odometer'}
          </Typography>
          )}
        align="center"
        action={(
          <IconButton
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
          </IconButton>
          )}
        sx={{
          backgroundColor: theme.palette.colors.primary,
          color: '#f5f5f5',
          paddingY: 0.1,
        }}
      />
      <CardContent className={classes.cardContent}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div style={{ padding: '5px 1px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {position?.attributes?.hasOwnProperty('odometer') && (
                    <>
                      <IconButton aria-label="edit" onClick={() => setOpen(true)} size="large">
                        <EditNoteIcon fontSize="inherit" />
                      </IconButton>
                      <Odometer
                        style={{ fontSize: 17 }}
                        value={valueOdometer}
                        theme="car"
                        animation="count"
                        duration={100}
                        format={format}
                      />
                      <div style={{ fontSize: 17, paddingLeft: 4 }}>
                        {distanceUnit ?? 'km'}
                      </div>

                    </>

                  )}
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div style={{ paddingTop: 8, textAlign: 'center', marginRight: 12 }}>
                  <b>Horas de Motor: </b>
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div style={{ padding: '2px 1px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {position?.attributes?.hasOwnProperty('hours') && (
                    <>
                      <Odometer
                        style={{ fontSize: 18 }}
                        value={valueHours}
                        theme="car"
                        animation="count"
                        duration={100}
                        format={format}
                      />
                      <div style={{ fontSize: 18, paddingLeft: 5 }}>
                        {t('sharedHourAbbreviation') ?? 'h'}
                      </div>

                    </>

                  )}
                </div>
                )}
            />
          </ListItem>
        </List>
      </CardContent>
      {open && (<OdometerEdit deviceId={device.id} valueOdometer={distanceFromMeters(position?.attributes?.odometer, distanceUnit).toFixed(3) ?? ''} open={open} setOpen={setOpen} loading={loading} setLoading={setLoading} />)}
    </Card>
  );
};

export default CardOdometer;
