/* eslint-disable no-unused-vars */
import React, {
  useState, useCallback, useEffect,
  useRef,
} from 'react';
import { showLoading } from 'react-global-loading';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import BottomMenu from '../common/components/BottomMenu';
import StatusCard from '../common/components/StatusCard';
import { commandsActions, devicesActions } from '../store';
import usePersistedState from '../common/util/usePersistedState';
import EventsDrawer from './EventsDrawer';
import useFilter from './useFilter';
import MainToolbar from './MainToolbar';
import MainMap from './MainMap';
import { useAttributePreference } from '../common/util/preferences';
import InfoBottom from '../common/components/InfoBottom';
import ModalLayout from '../common/components/ModalLayout';
import CommandModalPage from '../settings/CommandModalPage';
import { useTranslation } from '../common/components/LocalizationProvider';
import DeviceModalPage from '../settings/DeviceModalPage';
import DeviceListSimple from './DeviceListSimple';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
      left: 0,
      top: 0,
      height: `calc(100% - ${theme.spacing(3)})`,
      width: theme.dimensions.drawerWidthDesktop,
      margin: theme.spacing(1.5),
      zIndex: 3,
    },
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
    },
  },
  header: {
    pointerEvents: 'auto',
    zIndex: 6,
  },
  footer: {
    pointerEvents: 'auto',
    zIndex: 5,
  },
  middle: {
    flex: 1,
    display: 'grid',
  },
  contentMap: {
    pointerEvents: 'auto',
    gridArea: '1 / 1',
  },
  contentList: {
    height: '100%',
    pointerEvents: 'auto',
    gridArea: '1 / 1',
    zIndex: 3,
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const t = useTranslation();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const mapOnSelect = useAttributePreference('mapOnSelect', true);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const showCommandModal = useSelector((state) => state.commands.showCommandModal);
  const showEditModal = useSelector((state) => state.devices.showEditModal);
  const positions = useSelector((state) => state.session.positions);
  const globalLoading = useSelector((state) => state.session.globalLoading);

  const [filteredPositions, setFilteredPositions] = useState([]);

  const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId);

  const [filteredDevices, setFilteredDevices] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = usePersistedState('filter', {
    statuses: [],
    groups: [],
  });
  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);

  const [expandGroups, setExpandGroups] = usePersistedState('expandGroups', true);
  const [enableGroups, setEnableGroups] = usePersistedState('enableGroups', true);
  const [sortBy, setSortBy] = usePersistedState('sortBy', null);
  const [sortStatus, setSortStatus] = usePersistedState('sortStatus', false);

  const [devicesOpen, setDevicesOpen] = useState(desktop);
  const [eventsOpen, setEventsOpen] = useState(false);

  const [isTabActive, setIsTabActive] = useState(!document.hidden);

  useEffect(() => {
    let timeoutId = null;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        timeoutId = setTimeout(() => {
          setIsTabActive(false);
        }, 3000);
      } else {
        clearTimeout(timeoutId);
        setIsTabActive(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  // useFilter(keyword, filter, filterSort, filterMap, positions, setFilteredDevices, setFilteredPositions);
  const worker = useRef(null);
  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);

  useEffect(() => {
    if (!worker.current) {
      worker.current = new Worker(URL.createObjectURL(
        new Blob(
          [
            `
            self.onmessage = ({ data: { devices, filter, filterSort, keyword, groups, positions, filterMap } }) => {
              const deviceGroups = (device) => {
                const groupIds = [];
                let { groupId } = device;
                while (groupId) {
                  groupIds.push(groupId);
                  groupId = groups[groupId]?.groupId || 0;
                }
                return groupIds;
              };
            
              const filteredDevices = Object.values(devices)
                .filter((device) => !filter.statuses.length || filter.statuses.includes(device.status))
                .filter((device) => !filter.groups.length || deviceGroups(device).some((id) => filter.groups.includes(id)))
                .filter((device) => {
                  const lowerCaseKeyword = keyword.toLowerCase();
                  return [device.name, device.uniqueId, device.phone, device.model, device.contact].some((s) => s && s.toLowerCase().includes(lowerCaseKeyword));
                });
            
              switch (filterSort) {
                case 'name':
                  filteredDevices.sort((device1, device2) => device1.name.localeCompare(device2.name));
                  break;
                case 'lastUpdate':
                  filteredDevices.sort((device1, device2) => {
                    const time1 = device1.lastUpdate ? new Date(device1.lastUpdate).getTime() : 0;
                    const time2 = device2.lastUpdate ? new Date(device2.lastUpdate).getTime() : 0;
                    return time2 - time1;
                  });
                  break;
                default:
                  break;
              }
            
              const filteredPositions = filterMap
                ? filteredDevices.map((device) => positions[device.id]).filter(Boolean)
                : Object.values(positions);
            
              self.postMessage({ filteredDevices, filteredPositions });
            };            
    `,
          ],
          { type: 'text/javascript' },
        ),
      ), { name: 'filterMainWorker' });
    }
    // Configurar el listener para los mensajes del worker
    worker.current.onmessage = ({ data: { filteredDevices, filteredPositions } }) => {
      setFilteredDevices(filteredDevices);
      setFilteredPositions(filteredPositions);
    };
    // Limpiar el worker cuando el componente se desmonte
    return () => {
      worker.current?.terminate();
      worker.current = null;
    };
  }, []);

  useEffect(() => {
    if (isTabActive && worker.current) {
      worker.current.postMessage({ devices, filter, filterSort, keyword, groups, positions, filterMap });
    }
  }, [devices, filter, filterSort, keyword, groups, positions, filterMap, isTabActive]);

  useEffect(() => {
    if (globalLoading) {
      showLoading(true);
    }
  }, [globalLoading]);

  return (
    <div className={classes.root}>
      {desktop && (
        <MainMap
          filteredPositions={filteredPositions}
          selectedPosition={selectedPosition}
          onEventsClick={onEventsClick}
          isTabActive={isTabActive}
        />
      )}
      <div className={classes.sidebar}>
        {desktop && (
        <div className={classes.footer}>
          <BottomMenu />
        </div>
        )}
        <Paper square elevation={3} className={classes.header}>
          <MainToolbar
            filteredDevices={filteredDevices}
            devicesOpen={devicesOpen}
            setDevicesOpen={setDevicesOpen}
            keyword={keyword}
            setKeyword={setKeyword}
            filter={filter}
            setFilterSort={setFilterSort}
            filterMap={filterMap}
            setFilter={setFilter}
            setFilterMap={setFilterMap}
            expandGroups={expandGroups}
            setExpandGroups={setExpandGroups}
            enableGroups={enableGroups}
            setEnableGroups={setEnableGroups}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortStatus={sortStatus}
            setSortStatus={setSortStatus}
          />
        </Paper>
        <div className={classes.middle}>
          {!desktop && (
            <div className={classes.contentMap}>
              <MainMap
                filteredPositions={filteredPositions}
                selectedPosition={selectedPosition}
                onEventsClick={onEventsClick}
                isTabActive={isTabActive}
              />
            </div>
          )}
          <Paper square className={classes.contentList} style={devicesOpen ? { border: `1px solid ${theme.palette.colors.primary}` } : { visibility: 'hidden' }}>
            <DeviceListSimple
              devices={filteredDevices}
              setFilterMap={setFilterMap}
              expandGroups={expandGroups}
              setExpandGroups={setExpandGroups}
              filter={filter}
              setFilter={setFilter}
              enableGroups={enableGroups}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortStatus={sortStatus}
              setSortStatus={setSortStatus}
              isTabActive={isTabActive}
            />
          </Paper>
        </div>
        {desktop && (
        <div className={classes.footer}>
          <InfoBottom />
        </div>
        )}
      </div>
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
      {(selectedDeviceId && isTabActive) && (
        <StatusCard
          deviceId={selectedDeviceId}
          position={selectedPosition}
          onClose={() => dispatch(devicesActions.selectId(null))}
          desktopPadding={theme.dimensions.drawerWidthDesktop}
        />
      )}
      {(showCommandModal && selectedDeviceId) && (
        <ModalLayout title={t('commandConsoleHeader') || 'Command Console'} width={900} open={showCommandModal} handleClose={() => dispatch(commandsActions.showCommandModal(false))}>
          <CommandModalPage selectedDeviceId={selectedDeviceId} />
        </ModalLayout>
      )}
      {(showEditModal && selectedDeviceId) && (
        <ModalLayout title={t('sharedEdit') || 'Edit'} open={showEditModal} width={900} handleClose={() => dispatch(devicesActions.showEditModal(false))}>
          <DeviceModalPage handleClose={() => dispatch(devicesActions.showEditModal(false))} />
        </ModalLayout>
      )}
    </div>
  );
};

export default MainPage;
