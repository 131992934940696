import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Table, TableBody, TableCell, TableHead, TableRow, styled, Container, Backdrop, FormControl, TextField, InputAdornment,
} from '@mui/material';
import {
  BounceLoader,
} from 'react-spinners';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import {
  formatDistance, formatHours, formatVolume, formatTime,
} from '../common/util/formatter';
import ReportFilter from './components/ReportFilter';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import ColumnSelect from './components/ColumnSelect';
import usePersistedState from '../common/util/usePersistedState';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import MapPositions from '../map/MapPositions';
import MapView from '../map/core/MapView';
import MapCamera from '../map/MapCamera';
import AddressValue from '../common/components/AddressValue';
import TableShimmer from '../common/components/TableShimmer';
import MapGeofence from '../map/MapGeofence';
import scheduleReport from './common/scheduleReport';
import TablePaginationFooter from './components/TablePaginationFooter';

const CustomContainer = styled(Container)({
  maxWidth: 1200,
});

const columnsArray = [
  ['startTime', 'reportStartTime'],
  ['startOdometer', 'positionOdometer'],
  ['address', 'positionAddress'],
  ['endTime', 'reportEndTime'],
  ['duration', 'reportDuration'],
  ['engineHours', 'reportEngineHours'],
  ['spentFuel', 'reportSpentFuel'],
];
const columnsMap = new Map(columnsArray);

const StopReportPage = () => {
  const navigate = useNavigate();
  const classes = useReportStyles();
  const t = useTranslation();

  const distanceUnit = useAttributePreference('distanceUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [columns, setColumns] = usePersistedState('stopColumns', ['startTime', 'endTime', 'startOdometer', 'address']);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [descending, setDescending] = useState(false);
  const [stopTime, setStopTime] = useState(5);

  const handleChange = (event) => {
    setDescending(event.target.checked);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = useCatch(async ({ deviceId, from, to, type }) => {
    const query = new URLSearchParams({ deviceId, from, to, stopTime, descending });
    if (type === 'export') {
      setLoading(true);
      try {
        await fetch(`/api/reports/stops/xlsx?${query.toString()}`)
          .then((response) => response.blob())
        // .then((response) => window.location.assign(response.url));
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            throw Error(error);
          });
      } finally {
        setLoading(false);
      }

      // window.location.assign(`/api/reports/stops/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reports/stops/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/stops?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          setItems(await response.json());
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  const handleSchedule = useCatch(async (deviceIds, groupIds, report) => {
    report.type = 'stops';
    const error = await scheduleReport(deviceIds, groupIds, report);
    if (error) {
      throw Error(error);
    } else {
      navigate('/reports/scheduled');
    }
  });

  const formatValue = (item, key) => {
    switch (key) {
      case 'startTime':
      case 'endTime':
        return formatTime(item[key], 'minutes', hours12);
      case 'startOdometer':
        return formatDistance(item[key], distanceUnit, t);
      case 'duration':
        return formatHours(item[key]);
      case 'engineHours':
        return formatHours(item[key]);
      case 'spentFuel':
        return formatVolume(item[key], volumeUnit, t);
      case 'address':
        return (<AddressValue latitude={item.latitude} longitude={item.longitude} originalAddress={item[key]} />);
      default:
        return item[key];
    }
  };

  const validateNumber = (value) => /^[1-9]\d{0,2}$/.test(value);

  const handleStopTimeChange = (e) => {
    const inputValue = e.target.value;
    if (validateNumber(inputValue)) {
      setStopTime(inputValue);
    }
  };

  return (
    <>
      <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportStops']}>
        <div className={classes.container}>
          {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              <MapPositions
                positions={[{
                  deviceId: selectedItem.deviceId,
                  fixTime: selectedItem.startTime,
                  latitude: selectedItem.latitude,
                  longitude: selectedItem.longitude,
                }]}
                titleField="fixTime"
              />
            </MapView>
            <MapCamera latitude={selectedItem.latitude} longitude={selectedItem.longitude} />
          </div>
          )}
          <CustomContainer>
            <div className={classes.containerMain}>
              <div className={classes.header}>
                <ReportFilter handleSubmit={handleSubmit} handleSchedule={handleSchedule} multiDevice>
                  <div className={classes.filterItem}>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={descending}
                            onChange={handleChange}
                            name="checked"
                            color="primary"
                          />
                        )}
                        label="Descending"
                      />
                    </FormGroup>
                  </div>
                  <div className={classes.filterItem}>
                    <FormControl fullWidth>
                      <TextField
                        id="standard-number"
                        label="Tiempo Parada"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TimerOutlinedIcon
                                style={{ padding: 2, marginRight: 10 }}
                              />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">min.</InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        pattern="[1-9]\d{0,2}$"
                        value={stopTime}
                        onChange={handleStopTimeChange}
                      />
                    </FormControl>
                  </div>
                  <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} />
                </ReportFilter>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnAction} />
                    {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (<TableShimmer columns={columns.length + 1} />) : (
                    <>
                      {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                        <TableRow key={item.positionId}>
                          <TableCell className={classes.columnAction} padding="none">
                            {selectedItem === item ? (
                              <IconButton size="small" onClick={() => setSelectedItem(null)}>
                                <GpsFixedIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <IconButton size="small" onClick={() => setSelectedItem(item)}>
                                <LocationSearchingIcon fontSize="small" />
                              </IconButton>
                            )}
                          </TableCell>
                          {columns.map((key) => (
                            <TableCell key={key}>
                              {formatValue(item, key)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={columns.length + 1} />
                      </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
                <TablePaginationFooter
                  count={items.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Table>
            </div>
          </CustomContainer>
        </div>
      </PageLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <BounceLoader
          // margin={7}
          size={80}
          color="#d63636"
        />
      </Backdrop>
    </>
  );
};

export default StopReportPage;
