/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { debounce } from 'lodash';
import { showLoading } from 'react-global-loading';
import { useSnackbar } from 'notistack';
import store, { devicesActions, sessionActions } from './store';
import { useEffectAsync } from './reactHelper';
import alarm from './resources/alarm.mp3';
import { eventsActions } from './store/events';
import useFeatures from './common/util/useFeatures';
import { useAttributePreference } from './common/util/preferences';
import { vehicleIconKey } from './map/core/preloadImages';

const getStatus = ({ position, device }) => {
  const dev = device || store.getState().devices.items[position.deviceId];
  let vStatus = 'unknown';
  if (dev?.lastUpdate) {
    let itemStatus = dev.status;
    const positionValid = position?.attributes?.sat > 3;
    const currentTime = new Date();
    const pastTime = new Date(currentTime - 10 * 60 * 1000);
    const lastUpdate = new Date(dev?.lastUpdate);
    if (lastUpdate <= pastTime) {
      itemStatus = 'offline';
    }
    if (itemStatus === 'offline') {
      vStatus = 'offline';
    }
    if (
      !position?.attributes?.ignition &&
      (itemStatus === 'online' || itemStatus === 'unknown')
    ) {
      vStatus = 'parking';
    }
    if (position?.attributes?.ignition && itemStatus === 'offline') {
      vStatus = 'ignition-offline';
    }
    if (
      !positionValid &&
      (itemStatus === 'online' || itemStatus === 'unknown')
    ) {
      vStatus = 'no-gps';
    }
    if (
      positionValid === undefined &&
      position?.attributes === undefined &&
      (itemStatus === 'online' || itemStatus === 'unknown')
    ) {
      vStatus = 'parking';
    }
    if (!position?.attributes?.ignition && itemStatus === 'offline') {
      vStatus = 'parking-offline';
    }
    if (
      !position?.attributes?.ignition &&
      itemStatus === 'offline' &&
      !positionValid
    ) {
      vStatus = 'offline-no-gps';
    }
    if (
      position?.attributes?.ignition &&
      (itemStatus === 'online' || itemStatus === 'unknown')
    ) {
      vStatus = 'ignition';
    }
    if (
      position?.attributes?.ignition &&
      !positionValid &&
      (itemStatus === 'online' || itemStatus === 'unknown')
    ) {
      vStatus = 'ignition-no-gps';
    }
  }
  const statusv = vehicleIconKey(vStatus);
  return statusv;
};

const logoutCode = 4000;

const SocketController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const authenticated = useSelector((state) => !!state.session.user);
  const globalLoading = useSelector((state) => state.session.globalLoading);

  // const socketRef = useRef();

  const soundEvents = useAttributePreference('soundEvents', '');
  const soundAlarms = useAttributePreference('soundAlarms', 'sos');

  const features = useFeatures();

  const interval = useRef(null);
  const updateDevices = useRef([]);
  const updatePositions = useRef([]);
  const updateEvents = useRef([]);

  const handleAlertVariant = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  const socketRef = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = new Worker(URL.createObjectURL(
        new Blob(
          [
            `
      self.onmessage = function(event) {
        const socket = new WebSocket(event.data.url);
        socket.onopen = function() {
          // Envia un mensaje al hilo principal cuando el WebSocket se abre
          self.postMessage({ type: 'open' });
        };
        socket.onclose = function(event) {
          // Envia un mensaje al hilo principal cuando el WebSocket se cierra
          self.postMessage({ type: 'close', code: event.code });
        };
        socket.onmessage = function(event) {
          // Envia los datos del WebSocket al hilo principal
          self.postMessage({ type: 'message', data: event.data });
        };
      };
    `,
          ],
          { type: 'text/javascript' },
        ),
      ), { name: 'socketWorker' });
    }
    // Limpiar el worker cuando el componente se desmonte
    return () => {
      socketRef.current?.terminate();
      socketRef.current = null;
    };
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      if (updatePositions.current.length > 0) {
        dispatch(sessionActions.updatePositions(updatePositions.current));
        updatePositions.current.forEach((position) => {
          const statusv = getStatus({ position });
          dispatch(
            devicesActions.updateStatusV({
              id: position.deviceId,
              positionId: position.id,
              lastUpdate: position.deviceTime,
              statusv,
            }),
          );
        });
        updatePositions.current = [];
      }
      if (updateDevices.current.length > 0) {
        const devToUpd = updateDevices.current.map((dev) => {
          const position = store.getState().session.positions[dev.id];
          const posTime = new Date(position?.deviceTime);
          const lastTime = new Date(dev.lastUpdate);
          const lastUpdate =
            lastTime <= posTime ? position?.deviceTime : dev?.lastUpdate;
          const positionId =
            dev.positionId <= position?.id ? position?.id : dev?.positionId;
          const statusv = getStatus({
            position,
            device: { ...dev, positionId, lastUpdate },
          });
          return { ...dev, positionId, lastUpdate, statusv };
        });
        dispatch(devicesActions.update(devToUpd));
        updateDevices.current = [];
      }
      if (updateEvents.current.length > 0) {
        if (!features.disableEvents) {
          dispatch(eventsActions.add(updateEvents.current));
        }
        new Audio(alarm).play();
        updateEvents.current.slice(0, 3).forEach((ev) => {
          handleAlertVariant(ev.attributes.message, 'warning');
        });
        updateEvents.current = [];
      }
    }, 3500); // Check every 3.5 second

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    if (socketRef.current) {
      const connect = () => {
        // Inicia el Worker de Web con la URL del WebSocket
        socketRef.current.postMessage({
          type: 'connect',
          url: `${protocol}//${window.location.host}/api/socket`,
        });
      };

      // Inicia el Worker de Web con la URL del WebSocket
      // Conecta por primera vez
      connect();
      socketRef.current.onmessage = async (event) => {
        const message = event.data;
        if (message.type === 'open') {
          dispatch(sessionActions.updateSocket(true));
        } else if (message.type === 'close') {
          dispatch(sessionActions.updateSocket(false));
          if (event.code !== logoutCode) {
            try {
              const devicesResponse = await fetch('/api/devices');
              if (devicesResponse.ok) {
                dispatch(devicesActions.update(await devicesResponse.json()));
              }
              const positionsResponse = await fetch('/api/positions');
              if (positionsResponse.ok) {
                dispatch(
                  sessionActions.updatePositions(await positionsResponse.json()),
                );
              }
              if (
                devicesResponse.status === 401 ||
                positionsResponse.status === 401
              ) {
                navigate('/login');
              }
            } catch (error) {
              // ignore errors
            }
            setTimeout(() => connectSocket(), 30000);
          }
        } else if (message.type === 'message') {
          // ... (Tu código de manejo de datos aquí)
          const data = JSON.parse(message.data);
          // const data = message.data;
          if (data.devices) {
            if (data.devices.length === 1) {
              updateDevices.current.push(data.devices[0]);
            } else {
              dispatch(devicesActions.update(data.devices));
            }
          }
          if (data.positions) {
            if (data.positions.length === 1) {
              updatePositions.current.push(data.positions[0]);
            } else {
              dispatch(sessionActions.updatePositions(data.positions));
            }
            if (globalLoading) {
              dispatch(sessionActions.updateGlobalLoading(false));
              showLoading(false);
            }
          }
          if (data.events) {
            data.events.forEach((obj) => {
              if (
                !store
                  .getState()
                  .events.items.some((prevObj) => prevObj.id === obj.id) &&
                !updateEvents.current.some((prevObj) => prevObj.id === obj.id)
              ) {
                updateEvents.current.push(obj);
              }
            });
          }
        }
      };
    }
  };

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close(logoutCode);
        }
      };
    }
    return null;
  }, [authenticated]);

  return <div />;
};

export default connect()(SocketController);
