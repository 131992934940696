import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Container, Button, Accordion, AccordionDetails, AccordionSummary, Skeleton, Typography, TextField, Grid, LinearProgress, Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCatch, useEffectAsync } from '../../reactHelper';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { devicesActions } from '../../store';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const EditItemViewModal = ({
  children, endpoint, item, setItem, defaultItem, validate, onItemSaved, handleClose, hideButtons,
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.devices.selectedId);
  const [loading, setLoading] = useState(false);
  const handleLoading = (status) => setLoading(status);

  useEffectAsync(async () => {
    if (!item) {
      if (id) {
        const response = await fetch(`/api/${endpoint}/${id}`);
        if (response.ok) {
          setItem(await response.json());
        } else {
          throw Error(await response.text());
        }
      } else {
        setItem(defaultItem || {});
      }
    }
  }, [id, item, defaultItem]);

  const handleSave = useCatch(async () => {
    let url = `/api/${endpoint}`;
    if (id) {
      url += `/${id}`;
    }
    handleLoading(true);

    const response = await fetch(url, {
      method: !id ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (response.ok) {
      if (onItemSaved) {
        onItemSaved(await response.json());
      }
      const refreshResp = await fetch('/api/devices');
      if (refreshResp.ok) {
        dispatch(devicesActions.refresh(await refreshResp.json()));
      } else {
        throw Error(await refreshResp.text());
      }
      handleLoading(false);
      handleClose();
    } else {
      handleLoading(false);
      throw Error(await response.text());
    }
  });

  return (
    <Container maxWidth="md" className={classes.container}>
      {item ? children : (
        <Accordion defaultExpanded>
          <AccordionSummary>
            <Typography variant="subtitle1">
              <Skeleton width="12em" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {[...Array(3)].map((_, i) => (
              <Skeleton key={-i} width="100%">
                <TextField />
              </Skeleton>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      { !hideButtons && (
      <>
        <div className={classes.buttons}>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={handleClose}
            disabled={!item || loading}
          >
            {t('sharedCancel')}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={!item || !validate() || loading}
          >
            {t('sharedSave')}
          </Button>
        </div>
        {loading && (
        <Grid item xs={12}>
          <Box sx={{ width: '100%', paddingY: 0 }}>
            <LinearProgress />
          </Box>
        </Grid>
        )}
      </>
      )}

    </Container>
  );
};

export default EditItemViewModal;
