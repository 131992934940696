import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { getBatteryStatus } from '../../util/formatter';

// eslint-disable-next-line no-unused-vars
const CardNetwork = ({ classes, theme, t, handleClose, position, device, card }) => (
  <Card elevation={4} className={classes.card}>
    <CardHeader
      title={(
        <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
          {t(card) ?? 'Network'}
        </Typography>
          )}
      align="center"
      action={(
        <IconButton
          onClick={handleClose}
        >
          <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
        </IconButton>
          )}
      sx={{
        backgroundColor: theme.palette.colors.primary,
        color: '#f5f5f5',
        paddingY: 0.1,
      }}
    />
    <CardContent className={classes.cardContent}>
      <List dense className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>Señal: </b>
                {' '}
                <span className={position?.attributes?.rssi ? classes[getBatteryStatus(position?.attributes?.rssi)] : null}>
                  { position?.attributes?.rssi ? `${position?.attributes?.rssi} %` : '' }
                </span>
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>Operador de Red: </b>
                {' '}
                { position?.attributes?.operator ?? '' }
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>Tecnología Acceso: </b>
                {' '}
                { position?.attributes?.networkTechnology ?? '' }
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>Banda: </b>
                {' '}
                { position?.attributes?.networkBand ?? position?.network?.radioType ?? '' }
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>ICCID: </b>
                {' '}
                { position?.attributes?.iccid ?? '' }
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>IMSI: </b>
                {' '}
                { position?.attributes?.imsi ?? '' }
              </div>
                )}
          />
        </ListItem>
      </List>
    </CardContent>
  </Card>
);

export default CardNetwork;
