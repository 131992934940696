/* eslint-disable no-unused-vars */
import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffectAsync } from '../../reactHelper';

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LinkFieldSelect = ({
  label,
  endpointAll,
  endpointLinked,
  baseId,
  keyBase,
  keyLink,
  group,
  notsave,
  selectData,
  setSelectData,
  keyGetter = (item) => item.id,
  titleGetter = (item) => item.name,
}) => {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState();
  const [linked, setLinked] = useState(notsave ? selectData : []);

  useEffectAsync(async () => {
    if (active) {
      const response = await fetch(endpointAll);
      if (response.ok) {
        await sleep(1e3);
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [active]);

  useEffectAsync(async () => {
    if (active && !notsave) {
      const response = await fetch(endpointLinked);
      if (response.ok) {
        await sleep(1e3);
        // if (!group) {
        //   setLinked(Object.values(devices).filter((e) => e.groupId === Number(baseId)));
        // } else {
        //   setLinked(await response.json());
        // }
        setLinked(await response.json());
        // setSelectedGroup(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [active]);

  const createBody = (linkId) => {
    const body = {};
    body[keyBase] = Number(baseId);
    body[keyLink] = Number(linkId);
    return body;
  };

  const onChange = async (value) => {
    const oldValue = linked.map((it) => keyGetter(it));
    const newValue = value.map((it) => keyGetter(it));

    if (!newValue.find((it) => it < 0) && !notsave) {
      const results = [];
      newValue.filter((it) => !oldValue.includes(it)).forEach((added) => {
        results.push(fetch(group ? '/api/groups/device?deleteGroup=false' : '/api/permissions', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(createBody(added)),
        }));
      });
      oldValue.filter((it) => !newValue.includes(it)).forEach((removed) => {
        results.push(fetch(group ? '/api/groups/device?deleteGroup=true' : '/api/permissions', {
          method: group ? 'POST' : 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(createBody(removed)),
        }));
      });
      await Promise.all(results);
      setLinked(value);
    } else {
      setSelectData(value);
    }
  };

  const handleAutocompleteBlur = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      limitTags={2}
      loading={active && !items}
      isOptionEqualToValue={(i1, i2) => keyGetter(i1) === keyGetter(i2)}
      options={items || []}
      getOptionLabel={(item) => titleGetter(item)}
      renderOption={(props, item, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {titleGetter(item)}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(active && !items) ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onBlur={handleAutocompleteBlur}
        />
      )}
      value={(items && (notsave ? selectData : linked)) || []}
      onChange={(_, value) => onChange(value)}
      open={open}
      onOpen={() => {
        setOpen(true);
        setActive(true);
      }}
      // onClose={() => setOpen(false)}
      multiple
    />
  );
};

export default LinkFieldSelect;
