/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minHeight: 360,
  maxHeight: 750,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  padding: 0,
  borderRadius: 3,
};

const ModalLayout = ({ title, width, open, handleClose, children }) => {
  const theme = useTheme();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableEscapeKeyDown
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 400,
        },
      }}
    >
      <Card
        sx={{ ...style }}
        style={{
          width: useMediaQuery('(max-width:900px)') ? '92%' : width || 900,
        //   overflow: 'auto',
        //   scrollbarWidth: 'thin',
        }}
      >
        <CardHeader
          title={(
            <Typography sx={{ fontSize: '1.2rem' }}>{title}</Typography>
            )}
          align="center"
          action={(
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: '#f5f5f5' }} />
            </IconButton>
            )}
          sx={{ backgroundColor: theme.palette.colors.primary, color: '#f5f5f5', paddingY: 1 }}
        />
        <CardContent
          style={{
            minHeight: 360,
            maxHeight: 720,
            overflow: 'auto',
            scrollbarWidth: 'thin',
          }}
        >
          {children}
        </CardContent>
      </Card>

    </Modal>
  );
};

export default ModalLayout;
