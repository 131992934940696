/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  IconButton, Menu, MenuItem, useMediaQuery, useTheme, Tooltip, styled, Container, Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import RemoveDialog from '../../common/components/RemoveDialog';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useAdministrator } from '../../common/util/permissions';
import ModalLayout from '../../common/components/ModalLayout';
// import { devicesActions } from '../../store';
import NotificationPageModal from '../NotificationPageModal';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },
}));

const CustomContainer = styled(Container)({
  maxWidth: 1200,
});

const CollectionActions = ({
  itemId, item, editPath, endpoint, setTimestamp, customActions, readonly, notificationModalClose, isPoi, setOpenPoi, setPoi, setOpenGeofence, setGeofence,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  // const admin = useAdministrator();

  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [removing, setRemoving] = useState(false);
  const [modal, setModal] = useState(false);

  const [itemEdit, setItemEdit] = useState(null);

  const handleClose = () => {
    setModal(false);
    if (notificationModalClose) notificationModalClose();
  };

  const handleEdit = async () => {
    if (endpoint === 'notifications') {
      if (itemId) {
        const response = await fetch(`/api/notifications/${itemId}`);
        if (response.ok) {
          setItemEdit(await response.json());
          setModal(true);
        } else {
          throw Error(await response.text());
        }
      }
    } else if (endpoint === 'geofences') {
      if (item.id) {
        const response = await fetch(`/api/geofences/${item.id}`);
        if (response.ok) {
          const res = await response.json();
          if (isPoi) {
            setPoi(res);
            setOpenPoi(true);
          } else {
            setGeofence(res);
            setOpenGeofence(true);
          }
        } else {
          throw Error(await response.text());
        }
      }
    } else {
      navigate(`${editPath}/${itemId}`);
      setMenuAnchorEl(null);
    }
  };

  const handleRemove = () => {
    setRemoving(true);
    setMenuAnchorEl(null);
  };

  const handleCustom = (action) => {
    action.handler(itemId);
    setMenuAnchorEl(null);
  };

  const handleRemoveResult = (removed) => {
    setRemoving(false);
    if (removed) {
      setTimestamp(Date.now());
    }
  };

  return (
    <Box>
      <CustomContainer>
        {phone ? (
          <>
            <IconButton size="small" onClick={(event) => setMenuAnchorEl(event.currentTarget)}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)}>
              {customActions && customActions.map((action) => (
                <MenuItem onClick={() => handleCustom(action)} key={action.key}>{action.title}</MenuItem>
              ))}
              {!readonly && (
              <>
                <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>

                <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
              </>
              )}
            </Menu>
          </>
        ) : (
          <div className={classes.row}>
            {customActions && customActions.map((action) => (
              <Tooltip title={action.title} key={`${action.key}cnx`}>
                <IconButton size="small" onClick={() => handleCustom(action)} key={action.key}>
                  {action.icon}
                </IconButton>
              </Tooltip>
            ))}
            {!readonly && (
            <>
              <Tooltip title="Editar" key={`${itemId.key}edt`}>
                <IconButton size="small" onClick={handleEdit}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <span>
                  <IconButton
                    style={{
                      color: theme.palette.error.light,
                    }}
                    onClick={handleRemove}
                    // disabled={!admin}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>

            </>
            )}
          </div>
        )}
        <RemoveDialog style={{ transform: 'none' }} open={removing} endpoint={endpoint} itemId={itemId} onResult={(handleRemoveResult)} />
        {endpoint === 'notifications' && (
        <ModalLayout title={t('configAlert') || 'Notification'} width={950} open={modal} handleClose={handleClose}>
          <NotificationPageModal handleClose={handleClose} itemEdit={itemEdit} editPath={editPath} />
        </ModalLayout>
        )}
      </CustomContainer>
    </Box>
  );
};

export default CollectionActions;
