import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'events',
  initialState: {
    items: [],
  },
  reducers: {
    add(state, action) {
      const newItems = action.payload.filter(
        (item) => !state.items.some((existingItem) => existingItem.id === item.id),
      );
      newItems.sort((a, b) => new Date(b.eventTime) - new Date(a.eventTime));
      state.items.unshift(...newItems);
      state.items = state.items.slice(0, 50);
    },
    delete(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    deleteAll(state) {
      state.items = [];
    },
  },
});

export { actions as eventsActions };
export { reducer as eventsReducer };
