export default {
  sidebarWidth: '30%',
  sidebarWidthTablet: '52px',
  drawerWidthDesktop: '360px',
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '150px',
  eventsDrawerWidth: '320px',
  bottomBarHeight: 56,
  popupMapOffset: 0,
  popupMaxWidth: 270,
  popupImageHeight: 75,
  cardContentMaxHeight: '30vh',
};
