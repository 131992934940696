import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FormControl, InputLabel, Select, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, Link, IconButton, styled, Container, Backdrop,
} from '@mui/material';
import {
  BounceLoader,
} from 'react-spinners';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { formatSpeed, formatTime } from '../common/util/formatter';
import ReportFilter from './components/ReportFilter';
import { prefixString } from '../common/util/stringUtils';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import usePersistedState from '../common/util/usePersistedState';
import ColumnSelect from './components/ColumnSelect';
import { useCatch, useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import MapView from '../map/core/MapView';
import MapGeofence from '../map/MapGeofence';
import MapPositions from '../map/MapPositions';
import MapCamera from '../map/MapCamera';
import scheduleReport from './common/scheduleReport';
import TablePaginationFooter from './components/TablePaginationFooter';
import { devicesActions, reportsActions } from '../store';

const CustomContainer = styled(Container)({
  maxWidth: 1200,
});

const columnsArray = [
  ['alertTime', 'positionFixTime'],
  ['deviceId', 'sharedDevice'],
  ['type', 'sharedType'],
  ['geofenceId', 'sharedGeofence'],
  ['attributes', 'commandData'],
];
const columnsMap = new Map(columnsArray);

const AlarmReportPage = () => {
  const navigate = useNavigate();
  const classes = useReportStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const devices = useSelector((state) => state.devices.items);
  const geofences = useSelector((state) => state.geofences.items);

  const speedUnit = useAttributePreference('speedUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [allEventTypes, setAllEventTypes] = useState([['allEvents', 'eventAll']]);

  const [columns, setColumns] = usePersistedState('eventColumns', ['alertTime', 'deviceId', 'type', 'attributes']);
  const [eventTypes, setEventTypes] = useState(['allEvents']);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [position, setPosition] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffectAsync(async () => {
    if (selectedItem) {
      const response = await fetch(`/api/positions?id=${selectedItem.positionId}`);
      if (response.ok) {
        const positions = await response.json();
        if (positions.length > 0) {
          setPosition(positions[0]);
        }
      } else {
        throw Error(await response.text());
      }
    } else {
      setPosition(null);
    }
  }, [selectedItem]);

  useEffectAsync(async () => {
    dispatch(devicesActions.selectId(null));
    dispatch(devicesActions.selectIds([]));
    dispatch(reportsActions.updatePeriod('thisWeek'));
    const response = await fetch('/api/notifications/types');
    if (response.ok) {
      const types = await response.json();
      setAllEventTypes([...allEventTypes, ...types.map((it) => [it.type, prefixString('event', it.type)])]);
    } else {
      throw Error(await response.text());
    }
    // ! Call the default data from backend
    const from = moment().startOf('week').toISOString();
    const to = moment().endOf('week').toISOString();
    const query = new URLSearchParams({ from, to });
    eventTypes.forEach((it) => query.append('type', it));
    setLoading(true);
    try {
      const response = await fetch(`/api/reports/alerts?${query.toString()}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCatch(async ({ deviceIds, groupIds, from, to, type }) => {
    const query = new URLSearchParams({ from, to });
    deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    groupIds.forEach((groupId) => query.append('groupId', groupId));
    eventTypes.forEach((it) => query.append('type', it));
    if (type === 'export') {
      setLoading(true);
      try {
        await fetch(`/api/reports/events/xlsx?${query.toString()}`)
          .then((response) => response.blob())
        // .then((response) => window.location.assign(response.url));
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            throw Error(error);
          });
      } finally {
        setLoading(false);
      }

      // window.location.assign(`/api/reports/events/xlsx?${query.toString()}`);
    } else if (type === 'mail') {
      const response = await fetch(`/api/reports/events/mail?${query.toString()}`);
      if (!response.ok) {
        throw Error(await response.text());
      }
    } else {
      setLoading(true);
      try {
        const response = await fetch(`/api/reports/alerts?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          setItems(await response.json());
        } else {
          throw Error(await response.text());
        }
      } finally {
        setLoading(false);
      }
    }
  });

  const handleSchedule = useCatch(async (deviceIds, groupIds, report) => {
    report.type = 'events';
    if (eventTypes[0] !== 'allEvents') {
      report.attributes.types = eventTypes.join(',');
    }
    const error = await scheduleReport(deviceIds, groupIds, report);
    if (error) {
      throw Error(error);
    } else {
      navigate('/reports/scheduled');
    }
  });

  const formatValue = (item, key) => {
    switch (key) {
      case 'alertTime':
        return formatTime(item[key], 'seconds', hours12);
      case 'type':
        return t(prefixString('event', item[key]));
      case 'deviceId':
        return devices[item.deviceId].name || '';
      case 'geofenceId':
        if (item[key] > 0) {
          const geofence = geofences[item[key]];
          return geofence && geofence.name;
        }
        return null;
      case 'maintenanceId':
        return item[key] > 0 ? item[key] > 0 : null;
      case 'attributes':
        switch (item.type) {
          case 'alarm':
            return t(prefixString('alarm', item.attributes.alarm));
          case 'deviceOverspeed':
            return formatSpeed(item.attributes.speed, speedUnit, t);
          case 'deviceOverspeed2':
            return formatSpeed(item.attributes.speed2, speedUnit, t);
          case 'deviceOverspeed3':
            return formatSpeed(item.attributes.speed3, speedUnit, t);
          case 'driverChanged':
            return item.attributes.driverUniqueId;
          case 'media':
            return (<Link href={`/api/media/${devices[item.deviceId]?.uniqueId}/${item.attributes.file}`} target="_blank">{item.attributes.file}</Link>);
          case 'commandResult':
            return item.attributes.result;
          default:
            return '';
        }
      default:
        return item[key];
    }
  };

  return (
    <>
      <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'sharedAlarms']}>
        <div className={classes.container}>
          {selectedItem && (
          <div className={classes.containerMap}>
            <MapView>
              <MapGeofence />
              {position && <MapPositions positions={[position]} titleField="fixTime" />}
            </MapView>
            {position && <MapCamera latitude={position.latitude} longitude={position.longitude} />}
          </div>
          )}
          <CustomContainer>
            <div className={classes.containerMain}>
              <div className={classes.header}>
                <ReportFilter handleSubmit={handleSubmit} handleSchedule={handleSchedule} showOnly emptySearch multiDevice>
                  <div className={classes.filterItem}>
                    <FormControl fullWidth>
                      <InputLabel>{t('reportEventTypes')}</InputLabel>
                      <Select
                        label={t('reportEventTypes')}
                        value={eventTypes}
                        onChange={(event, child) => {
                          let values = event.target.value;
                          const clicked = child.props.value;
                          if (values.includes('allEvents') && values.length > 1) {
                            values = [clicked];
                          }
                          setEventTypes(values);
                        }}
                        multiple
                      >
                        {allEventTypes.map(([key, string]) => (
                          <MenuItem key={key} value={key}>{t(string)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <ColumnSelect columns={columns} setColumns={setColumns} columnsArray={columnsArray} />
                </ReportFilter>
              </div>

              <Table style={{ maxWidth: '1920px', margin: '0 auto', padding: '0 5px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnAction} />
                    {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (<TableShimmer columns={columns.length + 1} />) : (
                    <>
                      {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                        <TableRow key={item.id}>
                          <TableCell className={classes.columnAction} padding="none">
                            {item.positionId ? selectedItem === item ? (
                              <IconButton size="small" onClick={() => setSelectedItem(null)}>
                                <GpsFixedIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <IconButton size="small" onClick={() => setSelectedItem(item)}>
                                <LocationSearchingIcon fontSize="small" />
                              </IconButton>
                            ) : ''}
                          </TableCell>
                          {columns.map((key) => (
                            <TableCell key={key}>
                              {formatValue(item, key)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={columns.length + 1} />
                      </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
                <TablePaginationFooter
                  count={items.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Table>
            </div>
          </CustomContainer>
        </div>
      </PageLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <BounceLoader
          // margin={7}
          size={80}
          color="#d63636"
        />
      </Backdrop>
    </>
  );
};

export default AlarmReportPage;
