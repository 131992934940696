/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, styled, Container, Backdrop,
} from '@mui/material';
import moment from 'moment';
import {
  BounceLoader,
} from 'react-spinners';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { useCatch } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TablePaginationFooter from './components/TablePaginationFooter';
import ReportFilterOffDevices from './components/ReportFilterOffDevices';

const CustomContainer = styled(Container)({
  maxWidth: 2200,
});

const DevicesOffReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);
  //   const admin = useAdministrator();

  const [columns, setColumns] = useState(['Nombre', 'IMEI', 'Model', 'Ultimo Reporte', 'Voltaje', 'Voltaje Bateria (V)', 'Nivel Bateria', 'Ubicacion']);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [timeLoading, setTimeLoading] = useState(0);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSubmit = useCatch(async ({ deviceIds, offlineTime, type }) => {
    const queyObjet = {
      devicesIds: deviceIds,
      offlineTime,
    };
    const jsonData = JSON.stringify(queyObjet);
    // const objetCodif = encodeURIComponent(jsonData).toString();

    // const deviceId = deviceIds;
    // const query = new URLSearchParams({ deviceId, offlineTime });
    // const query = new URLSearchParams(objetCodif);

    // deviceIds.forEach((deviceId) => query.append('deviceId', deviceId));
    if (type === 'export') {
      setLoading(true);
      try {
        await fetch('/api/reports/disconnected-device/xlsx', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: jsonData,
        }).then((response) => response.blob())
        // .then((response) => window.location.assign(response.url));
          .then((blob) => {
            setLoading(false);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            throw Error(error);
          });
      } finally {
        setLoading(false);
      }

      // window.location.assign(`/api/reports/disconnected-device/xlsx?${query.toString()}`);
    } else {
      if (items.length === 0) {
        setLoading(true);
      }
      setIsLoading(true);
      try {
        const response = await fetch('/api/reports/disconnected-device', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: jsonData,
        });
        if (response.ok) {
          const data = await response.json();
          setItems(data);
        } else {
          throw Error(await response.text());
        }
      } finally {
        setIsLoading(false);
        setLoading(false);
      }
    }
  });

  const linkChange = (latitude, longitude) => {
    window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude}%2C${longitude}`, '_blank');
  };

  return (
    <>
      <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportDeviceOffline']}>

        <div className={classes.container}>
          <CustomContainer>
            <div className={classes.containerMain}>
              <div className={classes.header}>
                <ReportFilterOffDevices handleSubmit={handleSubmit} />
              </div>
              <Table style={{ maxWidth: '1920px', margin: '0 auto', padding: '0 5px' }}>
                <TableHead>
                  <TableRow>
                    {columns.map((key) => (<TableCell key={key}>{positionAttributes[key]?.name || key}</TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <TableRow key={item.imei}>
                      <TableCell className={classes.limitedText}>{item.deviceName}</TableCell>
                      <TableCell>{item.imei}</TableCell>
                      <TableCell>{item.model}</TableCell>
                      <TableCell>{item.lastReport ? moment(item.lastReport).format('DD-MM-YYYY HH:mm:ss') : '--'}</TableCell>
                      <TableCell>{`${item.power === null ? '--' : `${item.power.toFixed(2)} V`} `}</TableCell>
                      <TableCell>{`${item.battery === null ? '--' : `${item.battery.toFixed(2)}  V`}`}</TableCell>
                      <TableCell>{`${item.batteryLevel === null ? '--' : `${item.batteryLevel} %`}`}</TableCell>
                      <TableCell><a href="#" onClick={() => linkChange(item.latitude, item.longitude)}>Google Map</a></TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={columns.length + 2} />
                  </TableRow>
                  )}
                </TableBody>
                <TablePaginationFooter
                  count={items.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Table>
            </div>
          </CustomContainer>
        </div>

      </PageLayout>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <BounceLoader
          // margin={7}
          size={80}
          color="#d63636"
        />
      </Backdrop>
    </>
  );
};

export default DevicesOffReportPage;
