import React, { useState } from 'react';
import {
  Table, TableHead, TableRow, TableCell, TableBody, styled, Container,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { formatTime } from '../common/util/formatter';
import ReportFilter from './components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import { useCatch, useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import { usePreference } from '../common/util/preferences';
import TablePaginationFooter from './components/TablePaginationFooter';
import { devicesActions, reportsActions } from '../store';
import SettingsMenu from '../settings/components/SettingsMenu';

const CustomContainer = styled(Container)({
  maxWidth: 1200,
});

const columnsArray = [
  ['email', 'userEmail'],
  ['createdAt', 'reportStartTime'],
  ['logoutAt', 'reportEndTime'],
  ['duration', 'reportDuration'],
  ['ip', 'reportIp'],
  ['country', 'reportCountry'],
  ['isp', 'reportIsp'],
];
const columnsMap = new Map(columnsArray);

const UserLogsReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const hours12 = usePreference('twelveHourFormat');

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState(['email', 'createdAt', 'logoutAt', 'duration', 'ip', 'country', 'isp']);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffectAsync(async () => {
    dispatch(devicesActions.selectId(null));
    dispatch(devicesActions.selectIds([]));
    dispatch(reportsActions.updatePeriod('thisWeek'));

    // ! Call the default data from backend
    const from = moment().startOf('week').toISOString();
    const to = moment().endOf('week').toISOString();
    const query = new URLSearchParams({ from, to });
    setLoading(true);
    try {
      const response = await fetch(`/api/reports/users-log?${query.toString()}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCatch(async ({ from, to }) => {
    const query = new URLSearchParams({ from, to });
    setLoading(true);
    try {
      const response = await fetch(`/api/reports/users-log?${query.toString()}`, {
        headers: { Accept: 'application/json' },
      });
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  });

  const formatValue = (item, key) => {
    switch (key) {
      case 'createdAt':
        return formatTime(item[key], 'seconds', hours12);
      case 'logoutAt':
        return formatTime(item[key], 'seconds', hours12);
      default:
        return item[key];
    }
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'loginLogs']}>
      <div className={classes.container}>
        <CustomContainer>
          <div className={classes.containerMain}>
            <div className={classes.header}>
              <ReportFilter handleSubmit={handleSubmit} showOnly emptySearch ignoreDevice />
            </div>

            <Table style={{ maxWidth: '1920px', margin: '0 auto', padding: '0 5px' }}>
              <TableHead>
                <TableRow>
                  {columns.map((key) => (<TableCell key={key}>{t(columnsMap.get(key))}</TableCell>))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (<TableShimmer columns={columns.length + 1} />) : (
                  <>
                    {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                      <TableRow key={item.id}>
                        {columns.map((key) => (
                          <TableCell key={key}>
                            {formatValue(item, key)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={columns.length + 1} />
                    </TableRow>
                    )}
                  </>
                )}
              </TableBody>
              <TablePaginationFooter
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </div>
        </CustomContainer>
      </div>
    </PageLayout>
  );
};

export default UserLogsReportPage;
