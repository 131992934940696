import {
  useId, useCallback, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import maplibregl from 'maplibre-gl';
import { map } from './core/MapView';
import { getPopupContent } from './MapPositionsHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import useFeatures from '../common/util/useFeatures';

const MapPositions = ({ positions, onClick }) => {
  const id = useId();
  const clusters = `${id}-clusters`;
  const direction = `${id}-direction`;
  const devices = useSelector((state) => state.devices.items);
  const mapFollow = useSelector((state) => state.session.server.attributes?.mapFollow || false);
  const [positionIndex, setpositionIndex] = useState();
  const hours12 = usePreference('twelveHourFormat');
  const speedUnit = useAttributePreference('speedUnit');
  const t = useTranslation();
  const features = useFeatures();
  const theme = useTheme();

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';
  const selectedDevicePopup = useRef();
  const selectedDeviceContentElement = useRef();

  const onMapClick = useCallback((event) => {
    if (!event.defaultPrevented && onClick) {
      onClick();
    }
  }, [onClick]);

  const onMarkerClick = useCallback((event) => {
    event.preventDefault();
    const feature = event.features[0];
    if (onClick) {
      onClick(feature.properties.id, feature.properties.index);
      setpositionIndex(feature.properties.index);
    }
  }, [onClick]);

  // render popup
  useEffect(() => {
    if (positions && positionIndex && onMarkerClick) {
      if (!selectedDevicePopup.value) {
        // eslint-disable-next-line no-undef
        selectedDeviceContentElement.value = document.createElement('div');
        selectedDeviceContentElement.value.id = `selected-device-popup_${positions[positionIndex].deviceId}`;
        selectedDeviceContentElement.value.innerHTML = getPopupContent(devices[positions[positionIndex].deviceId], positions[positionIndex], { hours12, speedUnit, t }, features, mapFollow, true);

        // console.log(selectedDeviceContentElement);
        selectedDevicePopup.value = new maplibregl.Popup()
          .setDOMContent(selectedDeviceContentElement.value)
          .setLngLat([positions[positionIndex].longitude, positions[positionIndex].latitude])
          .addTo(map);

        // console.log(selectedDeviceContentElement);

        selectedDeviceContentElement.value.addEventListener('click', (e) => {
          const targetId = e.target.id.split('_')[1];
          switch (targetId) {
            case 'google-street-view':
              // eslint-disable-next-line no-undef
              window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${positions[positionIndex].latitude}%2C${positions[positionIndex].longitude}&heading=${positions[positionIndex].course}`, '_blank');
              break;
            default:
              break;
          }
        });
      }
    }
    return () => {
      if (selectedDeviceContentElement.value) {
        selectedDeviceContentElement.value.removeEventListener('click', () => { });
      }
      selectedDevicePopup.value?.remove();

      selectedDeviceContentElement.value = null;
      selectedDevicePopup.value = null;
    };
  }, [positions[positionIndex], selectedDevicePopup]);

  // popup refresh content
  useEffect(() => {
    if (positions[positionIndex] && selectedDeviceContentElement.value) {
      selectedDeviceContentElement.value.innerHTML = getPopupContent(devices[positions[positionIndex].deviceId], positions[positionIndex], { hours12, speedUnit, t }, mapFollow, features, true);
    }
  }, [positions[positionIndex], mapFollow, selectedDevicePopup]);

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      // cluster: mapCluster,
      // clusterMaxZoom: 14,
      // clusterRadius: 50,
    });
    map.addLayer({
      id,
      type: 'circle',
      source: id,

      paint: {
        'circle-radius': 3.5,
        'circle-color': theme.palette.colors.mapPointCircle,
        'circle-stroke-width': 0.5,
        'circle-stroke-color': theme.palette.colors.mapPointCircleStroke,
      },
    });

    map.on('mouseenter', id, onMouseEnter);
    map.on('mouseleave', id, onMouseLeave);
    map.on('click', id, onMarkerClick);
    map.on('mouseenter', clusters, onMouseEnter);
    map.on('mouseleave', clusters, onMouseLeave);
    map.off('click', onMapClick);

    return () => {
      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('click', id, onMarkerClick);
      map.on('mouseenter', clusters, onMouseEnter);
      map.on('mouseleave', clusters, onMouseLeave);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
      if (map.getLayer(direction)) {
        map.removeLayer(direction);
      }
    };
  }, [clusters, direction, onMarkerClick, selectedDevicePopup, selectedDeviceContentElement]);

  useEffect(() => {
    map.getSource(id)?.setData({
      type: 'FeatureCollection',
      features: positions.map((position, index) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [position.longitude, position.latitude],
        },
        properties: {
          index,
          id: position.id,
        },
      })),
    });
  }, [onMarkerClick, positions, selectedDevicePopup, selectedDeviceContentElement]);

  return null;
};

export default MapPositions;
