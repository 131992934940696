/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { devicesActions } from '../store';
import { useEffectAsync } from '../reactHelper';
import DeviceRow from './DeviceRow';
import DeviceRowSimple from './DeviceRowSimple';
import usePersistedState from '../common/util/usePersistedState';

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'relative',
    margin: 0,
  },
}));

const calcListHeight = (totalItems, totalGroups, height) => {
  const calc = (height * totalItems + 1);
  if (totalGroups > 9 && calc > 600) return 601;
  if (totalGroups > 7 && calc > 1200) return 1201;
  if (totalGroups > 5 && calc > 1800) return 1801;
  if (totalGroups > 3 && calc > 2400) return 2401;
  if (calc > 3000) return 3002;
  return calc;
};

const DeviceListSimple = ({
  devices, setFilterMap, expandGroups, setExpandGroups, filter, setFilter, enableGroups,
  sortBy, sortStatus, isTabActive,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [detailItemEnable] = useState(process.env.REACT_APP_DETAIL_LIST === 'enable');
  const [isExpanded, setIsExpanded] = usePersistedState('isExpanded', []);
  const [checked, setChecked] = useState([]);

  const groups = useSelector((state) => state.groups.items);

  const [itemsGroup, setItemsGroup] = useState([]);
  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const workerRef = useRef();

  useEffect(() => {
    workerRef.current = new Worker(URL.createObjectURL(
      new Blob(
        [
          `
          self.onmessage = ({ data: { devices, groups, sortBy, sortStatus, enableGroups } }) => {
            const devicesWithGroup = [];
            const devicesWithoutGroup = [];
          
            const groupsSet = new Set(Object.keys(groups));
          
            let devicesValues = Object.values(devices);
            if (sortBy) {
              switch (sortBy) {
                case 'asc':
                  // Sort by name in ascending order
                  devicesValues = devicesValues.slice().sort((a, b) => a.name.localeCompare(b.name));
                  break;
                case 'desc':
                  // Sort by name in descending order
                  devicesValues = devicesValues.slice().sort((a, b) => b.name.localeCompare(a.name));
                  break;
                case 'status':
                  // Sort by name in descending order
                  devicesValues = devicesValues.slice().sort((a, b) => b.name.localeCompare(a.name));
                  break;
                default:
                  break;
              }
            }
            if (sortStatus) {
              const order = ['ignition', 'parking', 'ignition-no-gps', 'no-gps', 'offline-no-gps', 'ignition-offline', 'parking-offline', 'offline', 'unknown'];
              // Sort the array based on the order of statusv values
              devicesValues = devicesValues.slice().sort((a, b) => order.indexOf(a.statusv) - order.indexOf(b.statusv));
            }
        
            for (const item of devicesValues) {
              if (enableGroups && groupsSet.has(''+item.groupId)) {
                devicesWithGroup.push(item);
              } else {
                devicesWithoutGroup.push(item);
              }
            }
            const groupedItems = {};
            if (enableGroups) {
              for (const groupId in groups) {
                if (Object.prototype.hasOwnProperty.call(groups, groupId)) {
                  const groupName = groups[groupId].name;
                  const { id } = groups[groupId];
        
                  const groupItems = devicesWithGroup
                    .filter((device) => device.groupId === groups[groupId].id)
                    .map(({ id, name, status, disabled, uniqueId, statusv, lastUpdate }) => ({ id, name, status, disabled, uniqueId, statusv, lastUpdate }));
        
                  groupedItems[groupName] = { id, groupItems };
                }
              }
            }
            const itemsArr = !enableGroups ? [] : Object.entries(groupedItems)
              .filter(([groupName, items]) => items.groupItems.length > 0)
              .map(([groupName, items]) => ({ groupName, items: items.groupItems, id: items.id }));
        
            if (devicesWithoutGroup.length > 0) {
              itemsArr.push({
                groupName: '',
                items: devicesWithoutGroup.map(({ id, name, status, disabled, uniqueId, statusv, lastUpdate }) => ({ id, name, status, disabled, uniqueId, statusv, lastUpdate })),
              });
            }
          
            self.postMessage({ itemsArr });
          };
  `,
        ],
        { type: 'text/javascript' },
      ),
    ), { name: 'listSimpleWorker' });

    workerRef.current.onmessage = ({ data: { itemsArr } }) => {
      setItemsGroup(itemsArr);
    };
    return () => {
      workerRef.current.terminate();
    };
  }, []);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffect(() => {
    if (isTabActive === true) {
      workerRef.current.postMessage({ devices, groups, sortBy, sortStatus, enableGroups });
    }
  }, [groups, devices, enableGroups, sortBy, sortStatus, isTabActive]);

  useEffect(() => {
    if (expandGroups === true) {
      setIsExpanded([]);
    }
    if (expandGroups === false) {
      setIsExpanded(itemsGroup.map((_, index) => index));
    }
  }, [expandGroups]);

  useEffect(() => {
    if (isExpanded.length > 0) {
      setExpandGroups(null);
    } else {
      setExpandGroups(true);
    }
  }, [isExpanded]);

  const handleAccordionChange = (value) => {
    const currentIndex = isExpanded.indexOf(value);
    const newChecked = [...isExpanded];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setIsExpanded(newChecked);
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    dispatch(devicesActions.selectId(null));
    let newGroups = [];
    if (currentIndex === -1) {
      newGroups = [...filter.groups, +value];
    } else {
      newGroups = filter.groups.filter((g) => g !== +value);
    }
    setFilter({ ...filter, groups: newGroups });
    setChecked(newGroups);
    if (newGroups.length) setFilterMap(true);
    else setFilterMap(false);
  };

  const getListRow = (detail) => {
    if (detail) {
      return (DeviceRow);
    }
    return (DeviceRowSimple);
  };

  return (
    <AutoSizer className={classes.list}>
      {({ height, width }) => (
        <Box
          sx={{
            width,
            height,
          }}
          style={{ overflowY: 'auto', scrollbarWidth: 'thin', bgcolor: 'background.paper', padding: 0 }}
        >
          {enableGroups && itemsGroup.map((group, key) => {
            const labelId = `checkbox-list-label-${key}`;
            return (
              <div key={`div-device-list-group-${key}`}>
                <Accordion
                  expanded={isExpanded.indexOf(key) === -1}
                  component="nav"
                  key={`div-device-list-accordion-${key}`}
                  style={{ marginRight: 4, alignContent: 'center' }}
                  slotProps={{ transition: { unmountOnExit: true, timeout: 50 } }}
                >
                  <AccordionSummary
                    expandIcon={(
                      <ExpandMoreIcon
                        onClick={() => handleAccordionChange(key)}
                      />
                      )}
                    aria-controls="panel1a-content"
                    // id={`panel1a-header${groups[key].id}`}
                    style={{ backgroundColor: grey[50], paddingLeft: 25, height: 28 }}
                  >
                    {group.id && (
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(group.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={() => handleToggle(group.id)}
                        style={{ marginTop: -6 }}
                      />
                    )}
                    <Typography
                      onClick={() => handleAccordionChange(key)}
                      style={{ flex: 9, fontSize: '14pt' }}
                    >
                      {group.groupName}

                    </Typography>
                    <Chip
                      onClick={() => handleAccordionChange(key)}
                      label={group.items.length}
                      variant="outlined"
                      color="primary"
                    />
                  </AccordionSummary>

                  <AccordionDetails style={{ margin: 0, padding: 0 }}>
                    <FixedSizeList
                      width="100%"
                      height={calcListHeight(group.items.length, itemsGroup.length, (detailItemEnable ? 98 : 60))}
                      itemCount={group.items.length}
                      itemData={group.items}
                      itemSize={detailItemEnable ? 98 : 60}
                      overscanCount={10}
                      filter={filter}
                      style={{ backgroundColor: grey[100], overflowY: 'disable', scrollbarWidth: 'none' }}
                    >
                      {getListRow(!!detailItemEnable)}
                    </FixedSizeList>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
          {(!enableGroups && itemsGroup.length > 0) && (
          <div key="div-device-list-group">
            <FixedSizeList
              width="100%"
              height={height}
              itemCount={itemsGroup[0].items.length}
              itemData={itemsGroup[0].items}
              itemSize={detailItemEnable ? 98 : 60}
              overscanCount={15}
              filter={filter}
              style={{ backgroundColor: grey[100], scrollbarWidth: 'thin' }}
            >
              {getListRow(!!detailItemEnable)}
            </FixedSizeList>
          </div>
          )}
        </Box>
      )}

    </AutoSizer>
  );
};

export default DeviceListSimple;
