import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import { formatNotificationTitle } from '../common/util/formatter';
import PageLayout from '../common/components/PageLayout';
import useFeatures from '../common/util/useFeatures';
import LinkFieldSelect from '../common/components/LinkFieldSelect';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  card: {
    textAlign: 'center',
    padding: (3, 3, 3, 6),
    background: theme.palette.colors.primary,
  },
}));

const DeviceConnectionsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const { id } = useParams();
  const device = useSelector((state) => state.devices.items[id]);

  const features = useFeatures();

  return (
    <>
      <div className={classes.root}>
        {device && (
          <PageLayout
            menu={<SettingsMenu />}
            breadcrumbs={['settingsTitle', 'sharedDevice', 'sharedConnections']}
          >
            <Container maxWidth="xs" className={classes.container}>
              <Card className={classes.card}>
                <Typography fontSize="20px" color="#ffffff">
                  {device.name}
                </Typography>
                <Typography fontSize="12px" color="#ffffff">
                  {device.uniqueId}
                </Typography>
              </Card>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('sharedConnections')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <LinkField
                    endpointAll="/api/geofences"
                    endpointLinked={`/api/geofences?deviceId=${id}`}
                    baseId={id}
                    keyBase="deviceId"
                    keyLink="geofenceId"
                    label={t('sharedGeofences')}
                  />
                  <LinkFieldSelect
                    endpointAll="/api/notifications"
                    endpointLinked={`/api/notifications?deviceId=${id}`}
                    baseId={id}
                    keyBase="deviceId"
                    keyLink="notificationId"
                    titleGetter={(it) => formatNotificationTitle(t, it)}
                    label={t('sharedNotifications')}
                  />
                  {!features.disableDrivers && (
                    <LinkField
                      endpointAll="/api/drivers"
                      endpointLinked={`/api/drivers?deviceId=${id}`}
                      baseId={id}
                      keyBase="deviceId"
                      keyLink="driverId"
                      label={t('sharedDrivers')}
                    />
                  )}
                  {!features.disableComputedAttributes && (
                    <LinkField
                      endpointAll="/api/attributes/computed"
                      endpointLinked={`/api/attributes/computed?deviceId=${id}`}
                      baseId={id}
                      keyBase="deviceId"
                      keyLink="attributeId"
                      titleGetter={(it) => it.description}
                      label={t('sharedComputedAttributes')}
                    />
                  )}
                  <LinkField
                    endpointAll="/api/commands"
                    endpointLinked={`/api/commands?deviceId=${id}`}
                    baseId={id}
                    keyBase="deviceId"
                    keyLink="commandId"
                    titleGetter={(it) => it.description}
                    label={t('sharedSavedCommands')}
                  />
                  {!features.disableMaintenance && (
                    <LinkField
                      endpointAll="/api/maintenance"
                      endpointLinked={`/api/maintenance?deviceId=${id}`}
                      baseId={id}
                      keyBase="deviceId"
                      keyLink="maintenanceId"
                      label={t('sharedMaintenance')}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </Container>
          </PageLayout>
        )}
      </div>
      {/* <RemoveDialog
         open={removing}
         endpoint="devices"
         itemId={deviceId}
         onResult={(removed) => handleRemove(removed)}
       /> */}
    </>
  );
};

export default DeviceConnectionsPage;
