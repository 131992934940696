import { createSlice } from '@reduxjs/toolkit';

const initSelectedCards = ['cardResume', 'cardNetwork', 'cardLocation', 'cardPower', 'cardEvents', 'cardOdometer', 'cardEngineCut'];

const { reducer, actions } = createSlice({
  name: 'statusCard',
  initialState: {
    selectedCards: (!process.env.REACT_APP_MALAGACAR || process.env.REACT_APP_MALAGACAR !== 'enable') ? initSelectedCards : ['cardStatusCar', ...initSelectedCards],
    showContent: true,
    showLabel: true,
  },
  reducers: {
    selectIds(state, action) {
      state.selectedCards = action.payload;
    },
    addCard(state, action) {
      state.selectedCards.push(action.payload);
    },
    deleteCard(state, action) {
      state.selectedCards = state.selectedCards.filter((item) => item !== action.payload);
    },
    showContent(state, action) {
      state.showContent = action.payload;
    },
    showLabel(state, action) {
      state.showLabel = action.payload;
    },
    toggleShowLabel(state) {
      state.showLabel = !state.showLabel;
    },
  },
});

export { actions as statusCardActions };
export { reducer as statusCardReducer };
