/* eslint-disable react/react-in-jsx-scope */
// material-ui

import {
  FormControl, MenuItem, Select, Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReactCountryFlag from 'react-country-flag';
import { useSelector } from 'react-redux';
import { useLocalization } from '../common/components/LocalizationProvider';

// ==============================|| Language Selector ||============================== //

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 320,
    backgroundColor: '#e9e9e9ea',
  },
}));

const Language = () => {
  const classes = useStyles();
  // const t = useTranslation();
  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], country: values[1].country, name: values[1].name }));
  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);

  return (
    <div style={{ position: 'fixed', top: 5, right: 10 }}>
      <form>
        {languageEnabled && (
        <FormControl fullWidth size="small">
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            style={{ backgroundColor: '#ffffff7a', border: 0, fontSize: '11pt' }}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {languageList.map((it) => (
              <MenuItem
                style={{ fontSize: '10pt' }}
                key={it.code}
                value={it.code}
              >
                <Box component="span" sx={{ mr: 1 }}>
                  <ReactCountryFlag countryCode={it.country} svg />
                </Box>
                {`(${it.code?.toUpperCase() || ''}) ${it.name}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        )}
      </form>
    </div>
  );
};

export default Language;
