import {
  formatPercentage,
  formatSpeed,
  formatTime,
  formatVoltage,
} from '../common/util/formatter';
import { prefixString } from '../common/util/stringUtils';

export const getBatteryStatus = (batteryLevel, charge = 0) => {
  if (batteryLevel <= 30) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-negative">
            ${charge ? 'battery_charging_20' : 'battery_1_bar'}
          </span> ${formatPercentage(batteryLevel)}
        </div>
      `;
  }

  if (batteryLevel <= 40) {
    return `
      <div class="selected-device-popup-icon-wrapper">
        <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
          ${charge ? 'battery_charging_30' : 'battery_2_bar'}
        </span> ${formatPercentage(batteryLevel)}
      </div>
      `;
  }

  if (batteryLevel <= 60) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            ${charge ? 'battery_charging_50' : 'battery_3_bar'}
          </span> ${formatPercentage(batteryLevel)}
        </div>
      `;
  }

  if (batteryLevel <= 80) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            ${charge ? 'battery_charging_60' : 'battery_4_bar'}
          </span> ${formatPercentage(batteryLevel)}
        </div>
      `;
  }

  if (batteryLevel < 90) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            ${charge ? 'battery_charging_80' : 'battery_5_bar'}
          </span> ${formatPercentage(batteryLevel)}
        </div>
      `;
  }

  if (batteryLevel >= 90 && batteryLevel < 100) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            ${charge ? 'battery_charging_90' : 'battery_6_bar'}
          </span> ${formatPercentage(batteryLevel)}
        </div>
      `;
  }

  if (batteryLevel === 100) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-icons selected-device-popup-icon selected-device-popup-icon-positive">
            ${charge ? 'battery_charging_full' : 'battery_full'}
          </span> ${formatPercentage(batteryLevel)}
        </div>
      `;
  }

  return `
      <div class="selected-device-popup-icon-wrapper selected-device-popup-icon-gray">
        <span class="material-symbols-outlined selected-device-popup-icon">
          battery_unknown
        </span> ${'N/A'}
      </div>
    `;
};

export const getSpeedStatus = (speed, unit, t) => {
  if (speed === 0) {
    return `
        <div class="selected-device-popup-icon-wrapper selected-device-popup-icon-gray">
          <span class="material-icons selected-device-popup-icon">
            speed
          </span> ${formatSpeed(0, unit, t)}
        </div>
      `;
  }

  if (speed > 0) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-icons selected-device-popup-icon selected-device-popup-icon-positive">
            speed
          </span> ${formatSpeed(speed, unit, t)}
        </div>
      `;
  }

  return `
      <div class="selected-device-popup-icon-wrapper selected-device-popup-icon-gray">
        <span class="material-icons selected-device-popup-icon">
          speed
        </span> ${'N/A'}
      </div>
    `;
};

export const getSatelliteStatus = (satNum) => {
  if (satNum <= 4) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-icons selected-device-popup-icon selected-device-popup-icon-negative">
            satellite_alt
          </span> ${satNum}
        </div>
      `;
  }

  if (satNum > 4) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-icons selected-device-popup-icon selected-device-popup-icon-positive">
            satellite_alt
          </span> ${satNum}
        </div>
      `;
  }

  return `
      <div class="selected-device-popup-icon-wrapper selected-device-popup-icon-gray">
        <span class="material-icons selected-device-popup-icon">
          satellite_alt
        </span> ${'N/A'}
      </div>
    `;
};

export const getExternalVoltageStatus = (volt, t) => {
  if (volt >= 0) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-icons selected-device-popup-icon selected-device-popup-icon-positive">
            electrical_services
          </span> ${formatVoltage(volt, t)}
        </div>
      `;
  }

  return `
      <div class="selected-device-popup-icon-wrapper selected-device-popup-icon-gray">
        <span class="material-icons selected-device-popup-icon">
          electrical_services
        </span> ${'N/A'}
      </div>
    `;
};

export const getRSSIStatus = (rssi) => {
  if (rssi <= 10) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-negative">
            signal_cellular_null
          </span> ${formatPercentage(rssi)}
        </div>
      `;
  }

  if (rssi <= 25) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            signal_cellular_1_bar
          </span> ${formatPercentage(rssi)}
        </div>
      `;
  }

  if (rssi <= 50) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            signal_cellular_2_bar
          </span> ${formatPercentage(rssi)}
        </div>
      `;
  }

  if (rssi < 90) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            signal_cellular_3_bar
          </span> ${formatPercentage(rssi)}
        </div>
      `;
  }

  if (rssi >= 90) {
    return `
        <div class="selected-device-popup-icon-wrapper">
          <span class="material-symbols-outlined selected-device-popup-icon selected-device-popup-icon-positive">
            signal_cellular_4_bar
          </span> ${formatPercentage(rssi)}
        </div>
      `;
  }

  return `
      <div class="selected-device-popup-icon-wrapper selected-device-popup-icon-gray">
        <span class="material-symbols-outlined selected-device-popup-icon">
          signal_cellular_nodata
        </span> ${'N/A'}
      </div>
    `;
};

export const getAddress = (longitude, latitude, deviceId) => {
  // eslint-disable-next-line no-undef
  const linkElement = document.getElementById(`selected-device_address_${deviceId}`);
  const query = new URLSearchParams({ latitude, longitude });
  fetch(`/api/server/geocode?${query.toString()}`)
    .then((response) => (response.ok && response.text()))
    .then((text) => {
      if (text) {
        linkElement.innerHTML = `<a target="_blank" href="https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}">${text}</a>`;
      }
    });
};

export const getPopupContent = (device, position, formatter, mapFollow, features, hideOptions) => (`
    <div class="selected-device-popup">
  
      <div class="selected-device-popup-section">
        <p class="selected-device-popup-title">${device.name}</p>
        <p class="selected-device-popup-subtitle">${device.uniqueId}</p>
      </div>
  
      <div class="selected-device-popup-section">
        <p class="selected-device-popup-time-title">
          ${formatTime(position.deviceTime, 'seconds', formatter.hours12)}
        </p>
      </div>

      <div class="selected-device-popup-section selected-device-popup-icons-section">
        ${getBatteryStatus(position.attributes.batteryLevel, position.attributes.charge)}
        ${getSatelliteStatus(position.attributes.sat)}
        ${getSpeedStatus(position.speed, formatter.speedUnit, formatter.t)}
        ${getExternalVoltageStatus(position.attributes.power, formatter.t)}
        ${getRSSIStatus(position.attributes.rssi)}
      </div>

      ${hideOptions === true ? '' : `
      <div class="selected-device-popup-section">
        <p id="selected-device_address_${device.id}" class="selected-device-popup-address-link">
          ${formatter.t('sharedShowAddress')}
        </p>
      </div>
      `}  
      
      <div class="selected-device-popup-action-buttons">
        <div class="selected-device-popup-tooltip">
          <span id="selected-device_google-street-view_${device.id}" class="material-icons"> public </span>
          <span class="selected-device-popup-tooltiptext">Google Street View</span>
        </div>
        ${hideOptions === true ? '' : `
          <div class="selected-device-popup-tooltip">
            <span id="selected-device_replay_${device.id}" class="material-icons"> replay </span>
            <span class="selected-device-popup-tooltiptext">${formatter.t('reportReplay')}</span>
          </div>

          <div class="selected-device-popup-tooltip">
            <span id="selected-device_info_${device.id}" class="material-icons"> info </span>
            <span class="selected-device-popup-tooltiptext">${formatter.t('sharedInfoTitle')}</span>
          </div>

          <div class="selected-device-popup-tooltip">
            <span id="selected-device_commands_${device.id}" class="material-icons"> outbox </span>
            <span class="selected-device-popup-tooltiptext">${formatter.t('commandConsoleHeader')}</span>
          </div>
          
          <div class="selected-device-popup-tooltip">
            <span id="selected-device_edit_${device.id}" class="material-icons"> edit </span>
            <span class="selected-device-popup-tooltiptext">${formatter.t('sharedEdit')}</span>
          </div>
          
          <div class="selected-device-popup-tooltip">
            ${mapFollow ? `<span id="selected-device_follow_${device.id}" class="material-icons selected-device-button-active"> my_location </span>
            ` : `<span id="selected-device_follow_${device.id}" class="material-icons"> location_searching </span>`}
            <span class="selected-device-popup-tooltiptext">${formatter.t('deviceFollow')}</span>
          </div>
        `}  
      </div>
          
    </div>
  `);

export const getPopupCompact = (device, position, hours12) => (`
  <div class="selected-device-popup">  
    <div class="selected-device-popup-section" style="text-align: center">
      <p><b>${device?.name}</b></p>
      <p>${formatTime(position?.deviceTime, 'seconds', hours12)}</p>
    </div>  
  </div>
`);

export const getEventContentValue = (event, t, speedUnit) => {
  if (event?.type && event?.attributes) {
    switch (event.type) {
      case 'deviceOverspeed':
        return formatSpeed(event.attributes.speed, speedUnit, t);
      case 'deviceOverspeed2':
        return formatSpeed(event.attributes.speed2, speedUnit, t);
      case 'deviceOverspeed3':
        return formatSpeed(event.attributes.speed3, speedUnit, t);
      default:
        return '';
    }
  }
  return '';
};

// eslint-disable-next-line no-unused-vars
export const getPopupEventContent = (positionSelected, eventSelected, address, geofenceName, t, hours12, speedUnit) => (`
  <p style="color: #6d6d6d;">
    ${formatTime(positionSelected?.deviceTime, 'seconds', hours12)}
  </p>
  <p>
    <b>
    ${(eventSelected?.type !== 'alarm') ? (
    `${t(prefixString('event', eventSelected?.type))}`
  ) : (
    `${t(prefixString('alarm', eventSelected?.attributes?.alarm))}`
  )}
    ${getEventContentValue(eventSelected, t, speedUnit) ? `<br>(${getEventContentValue(eventSelected, t, speedUnit)})` : ` ${geofenceName}`}
    </b>
  </p>
  <div class="selected-device-popup-tooltip">
    <p style="padding-top: 5px;">
      <a style="color: #383838; text-decoration: none;" target="_blank" href="https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${positionSelected.latitude}%2C${positionSelected.longitude}&heading=${positionSelected.course}">${address}</a>
    </p>
    <span class="selected-device-popup-tooltiptext">Google Street View</span>
  </div>
`);

// eslint-disable-next-line no-unused-vars
export const getPopupAlarmContent = (alarm, t, speedUnit) => (`
  <div class="selected-device-popup-tooltip">
    <p style="padding-top: 5px;">
      <a style="color: #383838; text-decoration: none;" target="_blank" href="https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${alarm.position?.latitude}%2C${alarm.position?.longitude}&heading=${alarm.position.course}">
      <p>
      <b>
      ${(alarm?.type !== 'alarm') ? (
    `${t(prefixString('event', alarm?.type))}`
  ) : (
    `${t(prefixString('alarm', alarm?.attributes?.alarm))}`
  )}
      ${getEventContentValue(alarm, t, speedUnit) ? `<br>(${getEventContentValue(alarm, t, speedUnit)})` : ' '}
      </b>
    </p>
      </a>
    </p>
    <span class="selected-device-popup-tooltiptext">Google Street View</span>
  </div>
`);
