/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography, Autocomplete, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { devicesActions, reportsActions } from '../../store';
import SplitButton from '../../common/components/SplitButton';
// import SelectField from '../../common/components/SelectField';
import { useRestriction } from '../../common/util/permissions';
import SelectField from '../../common/components/SelectField';
import { useEffectAsync } from '../../reactHelper';
import InfinitiScroll from '../../settings/common/InfinitiScroll';

const ReportFilterPois = ({
  handleSubmit, handleSchedule, showOnly, multiDevice, emptySearch,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const accordionRef = useRef(null);

  const readonly = useRestriction('readonly');

  const deviceId = useSelector((state) => state.devices.selectedId);
  const deviceIds = useSelector((state) => state.devices.selectedIds);
  const groupIds = useSelector((state) => state.reports.groupIds);
  // const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);
  const [button, setButton] = useState('json');

  const [tipoPois, setTipoPois] = useState(0);
  const [tipoDevicesPois, setTipoDevicesPois] = useState();
  const [devicesPois, setDevicesPois] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [always, setAlways] = useState(false);

  const disabled = (!selectedItems.length);

  const handleClick = (type) => {
    const selectedFrom = moment(from, moment.HTML5_FMT.DATETIME_LOCAL);
    const selectedTo = moment(to, moment.HTML5_FMT.DATETIME_LOCAL);
    const selectIdsDevices = [];
    Object.values(selectedItems).forEach((dev) => {
      selectIdsDevices.push(dev.id);
    });

    // console.log(selectedItems);

    handleSubmit({
      deviceIds: selectIdsDevices,
      geofenceId: tipoPois,
      from: selectedFrom.toISOString(),
      to: selectedTo.toISOString(),
      type,
    });
  };

  useEffectAsync(async () => {
    if (tipoPois) {
      const response = await fetch(`/api/geofences/${tipoPois}`);
      if (response.ok) {
        setTipoDevicesPois(await response.json());
      } else {
        throw Error(await response.text());
      }
    }
  }, [tipoPois]);

  useEffectAsync(async () => {
    if (tipoDevicesPois) {
      const data = tipoDevicesPois.configuration.devicesIds;
      if (data.length > 0) {
        const query = new URLSearchParams();
        data.forEach((data) => query.append('id', data));
        const responseDev = await fetch(`/api/devices?${query.toString()}`);
        if (responseDev.ok) {
          setDevicesPois(await responseDev.json());
        }
      }
    }
  }, [tipoDevicesPois]);

  // useEffect(() => {
  //   if (devicesPois) {
  //     const data = Object.values(devicesPois).map((item) => ({
  //       value: item.id,
  //       label: item.name,
  //     }));
  //     setSelectDev(data);
  //   }
  // }, [devicesPois]);

  // const onChanges = (e, values) => {
  //   const selectDeviceIDs = [];
  //   values.map((item) => (
  //     selectDeviceIDs.push(item.value)
  //   ));
  //   dispatch(devicesActions.selectIds(selectDeviceIDs));
  // };

  // const handleAutocompleteBlur = () => {
  //   setIsOpen(false);
  // };

  const onChangesTipoPois = (value) => {
    if (value !== tipoPois) {
      setAlways(false);
      setTipoPois(value);
      // dispatch(devicesActions.selectIds([]));
      setSelectedItems([]);
    }
  };

  const handleAccordionChange = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <FormControl fullWidth>
          <SelectField
            emptyValue={null}
            value={tipoPois}
            onChange={(event) => onChangesTipoPois(event.target.value)}
            endpoint="/api/geofences?type=poi"
            label={t('sharedType')}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth>
          <div ref={accordionRef}>
            <Accordion
              disabled={!tipoPois}
              expanded={isOpen}
              onChange={handleAccordionChange}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('deviceTitle')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <InfinitiScroll selectedItems={selectedItems} setSelectedItems={setSelectedItems} pois={devicesPois} always={always} setAlways={setAlways} enableAlways />
              </AccordionDetails>
            </Accordion>
          </div>
          {/* <Autocomplete
            FreeSolo
            disabled={!tipoPois}
            multiple
            limitTags={1}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            options={!selectDev ? [{ label: 'Loading...', id: 0 }] : selectDev}
            getOptionLabel={(selectDev) => selectDev.label || ''}
            value={deviceIds}
            onChange={onChanges}
            renderInput={(param) => (
              <TextField
                {...param}
                label={t('reportDevice')}
                variant="outlined"
                onBlur={handleAutocompleteBlur}
              />
            )}
          /> */}
          {/* <InputLabel>{t(multiDevice ? 'deviceTitle' : 'reportDevice')}</InputLabel>
          <Select
            label={t(multiDevice ? 'deviceTitle' : 'reportDevice')}
            value={multiDevice ? deviceIds : deviceId || ''}
            onChange={(e) => dispatch(multiDevice ? devicesActions.selectIds(e.target.value) : devicesActions.selectId(e.target.value))}
            multiple={multiDevice}
          >
            {Object.values(devices).sort((a, b) => a.name.localeCompare(b.name)).map((device) => (
              <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
            ))}
          </Select> */}
        </FormControl>
      </div>
      <div className={classes.filterItem}>
        <TextField
          label={t('reportFrom')}
          type="datetime-local"
          value={from}
          onChange={(e) => dispatch(reportsActions.updateFrom(e.target.value))}
          fullWidth
        />
      </div>
      <div className={classes.filterItem}>
        <TextField
          label={t('reportTo')}
          type="datetime-local"
          value={to}
          onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
          fullWidth
        />
      </div>

      {/* <div className={classes.filterItem}>
        <TextField
          label={t('reportTo')}
          type="datetime-local"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: minDate,
              max: maxDate,
            },
          }}
          value={to}
          onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
          fullWidth
        />
      </div> */}
      {/* {children} */}
      <div className={classes.filterItem}>
        {showOnly ? (
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            disabled={disabled && !emptySearch}
            onClick={() => handleClick('json')}
          >
            <Typography variant="button" noWrap>{t('reportShow')}</Typography>
          </Button>
        ) : (
          <SplitButton
            fullWidth
            variant="outlined"
            color="secondary"
            disabled={disabled}
            onClick={handleClick}
            selected={button}
            setSelected={(value) => setButton(value)}
            options={readonly ? {
              json: t('reportShow'),
              export: t('reportExport'),
            } : {
              json: t('reportShow'),
              export: t('reportExport'),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ReportFilterPois;
