/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Breadcrumbs,
  Container,
  Divider,
  Drawer as Drawer2,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { devicesActions } from '../../store';
import { useTranslation } from './LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  desktopRoot: {
    height: '100%',
    display: 'flex',
  },
  mobileRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  desktopDrawer: {
    width: theme.dimensions.drawerWidthDesktop,
    backgroundColor: theme.palette.colors.primary,
    color: theme.palette.primary.contrastText,
  },
  mobileDrawer: {
    width: theme.dimensions.drawerWidthTablet,
    backgroundColor: theme.palette.colors.primary,
    color: theme.palette.primary.contrastText,
  },
  mobileToolbar: {
    zIndex: 1,
  },
  content: {
    flexGrow: 1,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
}));

const PageTitle = ({ breadcrumbs }) => {
  const theme = useTheme();
  const t = useTranslation();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  // if (desktop) {
  //   return (
  //     <Typography style={{ color: theme.palette.primary.contrastText }} variant="h6" noWrap>{t(breadcrumbs[0])}</Typography>
  //   );
  // }
  return (
    <Breadcrumbs style={{ color: theme.palette.primary.contrastText }}>
      {breadcrumbs.slice(0, -1).map((breadcrumb) => (
        <Typography style={{ color: theme.palette.primary.contrastText }} variant="h6" key={breadcrumb}>{t(breadcrumb)}</Typography>
      ))}
      <Typography style={{ color: theme.palette.primary.contrastText }} variant="h6">{t(breadcrumbs[breadcrumbs.length - 1])}</Typography>
    </Breadcrumbs>
  );
};

const PageLayout = ({ menu, breadcrumbs, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const drawerWidth = 250;

  const dispatch = useDispatch();
  const showSidebar = useSelector((state) => state.devices.showSidebar);

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const [openDrawer, setOpenDrawer] = useState(false);

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const handleDrawerOpen = () => {
    dispatch(devicesActions.showSidebar(true));
  };

  const handleDrawerClose = () => {
    dispatch(devicesActions.showSidebar(false));
  };

  return desktop ? (
    <div className={classes.desktopRoot}>
      <Drawer
        variant="permanent"
        open={showSidebar}
       // onClose={() => setOpenDrawer(!openDrawer)}
        classes={{ paper: classes.desktopDrawer }}
      >
        {/* <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            sx={{ mr: 2 }}
            onClick={() => setOpenDrawer(false)}
          >
            <ArrowBackIcon />
          </IconButton>
          <PageTitle breadcrumbs={breadcrumbs} />
        </Toolbar> */}
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={showSidebar ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            style={{ color: theme.palette.primary.contrastText }}
            sx={{
              marginRight: 1,
              // ...(openDrawer && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {menu}
      </Drawer>
      <div className={classes.content}>
        <AppBar className={classes.mobileToolbar} position="static" color="inherit">
          <Toolbar style={{ color: theme.palette.primary.contrastText, backgroundColor: theme.palette.colors.primary }}>
            {/* <IconButton color="inherit" edge="start" sx={{ mr: 2 }} onClick={() => setOpenDrawer(true)}>
              <MenuIcon style={{ color: theme.palette.primary.contrastText }} />
            </IconButton> */}
            <PageTitle breadcrumbs={breadcrumbs} />
          </Toolbar>
        </AppBar>
        {children}
      </div>
    </div>
  ) : (
    <div className={classes.mobileRoot}>
      <Drawer2
        variant="temporary"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        classes={{ paper: classes.mobileDrawer }}
      >
        {menu}
      </Drawer2>
      <AppBar className={classes.mobileToolbar} position="static" color="inherit">
        <Toolbar style={{ color: theme.palette.primary.contrastText, backgroundColor: theme.palette.colors.primary }}>
          <IconButton color="inherit" edge="start" sx={{ mr: 2 }} onClick={() => setOpenDrawer(true)}>
            <MenuIcon style={{ color: theme.palette.primary.contrastText }} />
          </IconButton>
          <PageTitle breadcrumbs={breadcrumbs} />
        </Toolbar>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default PageLayout;
