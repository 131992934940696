/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Pagination,
  Tooltip,
  Container,
  styled,
  useTheme,
  TableContainer,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse';
import { makeStyles } from '@mui/styles';
import LinkIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import GetAppIcon from '@mui/icons-material/GetApp';
import TablePagination from '@mui/material/TablePagination';
import { devicesActions } from '../store';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import { usePreference } from '../common/util/preferences';
import { formatTime } from '../common/util/formatter';
import { useDeviceReadonly } from '../common/util/permissions';
import TablePaginationFooter from '../reports/components/TablePaginationFooter';

const CustomContainer = styled(Container)({
  maxWidth: 1200,
});

const exportDevicesToCSV = (devices) => {
  const csv = Papa.unparse(devices);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = 'devices.csv';

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    position: 'absolute',
    marginLeft: '100px',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: '#f9f9f9',
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  item: {
    color: '#555',
    padding: '10px 0',
    textAlign: 'center',
  },
  itemSelected: {
    backgroundColor: '#1976d2',
    color: 'white',
  },
  limitedText: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  table: {
    minWidth: 720,
  },
}));

const DevicesPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const theme = useTheme();
  const hours12 = usePreference('twelveHourFormat');

  const dispatch = useDispatch();
  const dataPage = useSelector((state) => state.devices.dataPage);
  const dataRowPage = useSelector((state) => state.devices.dataRowPage);

  const deviceReadonly = useDeviceReadonly();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [orderDirection, setOrderDirection] = useState('asc');

  // const [page, setPage] = useState(dataPage.page);
  // const [rowsPerPage, setRowsPerPage] = useState(dataPage.rowsPerPage);

  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 25;

  // function formatEuropeanDateTime(dateString) {
  //   const date = new Date(dateString);
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = date.getFullYear();
  //   const hour = String(date.getHours()).padStart(2, '0');
  //   const minute = String(date.getMinutes()).padStart(2, '0');
  //   return `${day}/${month}/${year} ${hour}:${minute}`;
  // }

  // function sortItemsByName(a, b) {
  //   if (orderDirection === 'asc') {
  //     return a.name.localeCompare(b.name);
  //   }
  //   return b.name.localeCompare(a.name);
  // }

  function handleNameColumnClick() {
    if (orderDirection === 'asc') {
      setOrderDirection('desc');
    } else {
      setOrderDirection('asc');
    }
  }

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const actionConnections = {
    key: 'connections',
    title: t('sharedConnections'),
    icon: <LinkIcon fontSize="small" />,
    handler: (deviceId) => navigate(`/settings/device/${deviceId}/connections`),
  };

  // const sortedItems = [...items]
  //   .sort(sortItemsByName)
  //   .filter(filterByKeyword(searchKeyword));

  // const paginatedItems = sortedItems.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage,
  // );

  const handleChangePage = (event, newPage) => {
    dispatch(devicesActions.dataPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(devicesActions.dataRowPage(+event.target.value));
    dispatch(devicesActions.dataPage(0));
  };

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'deviceTitle']}
    >
      <CustomContainer>
        <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  onClick={handleNameColumnClick}
                  onKeyDown={handleNameColumnClick}
                  role="button"
                  tabIndex={0}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {t('sharedName')}
                  <Tooltip title="Ordenar alfabéticamente">
                    <SortIcon
                      fontSize="small"
                      style={{ marginLeft: '5px', paddingBottom: 1, verticalAlign: 'middle' }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>{t('deviceIdentifier')}</TableCell>
                {/* <TableCell>{t('groupParent')}</TableCell> */}
                <TableCell>{t('deviceLastUpdate')}</TableCell>
                <TableCell>{t('deviceStatus')}</TableCell>
                <TableCell>{t('sharedPhone')}</TableCell>
                <TableCell>{t('deviceModel')}</TableCell>
                <TableCell style={{ paddingLeft: '4px', paddingTop: '14px' }}>
                  <Tooltip title="Exportar a CSV">
                    <GetAppIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => exportDevicesToCSV(items)}
                    />
                  </Tooltip>
                </TableCell>
                {/* <TableCell>{t('deviceContact')}</TableCell>
            <TableCell>{t('userExpirationTime')}</TableCell> */}
                <TableCell className={classes.columnAction} />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (items.filter(filterByKeyword(searchKeyword))
                .slice(dataPage * dataRowPage, dataPage * dataRowPage + dataRowPage)
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={classes.limitedText}>{item.name}</TableCell>
                    <TableCell>{item.uniqueId}</TableCell>
                    <TableCell>{formatTime(item.lastUpdate, 'minutes', hours12)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {item.disabled ? (
                        <Tooltip title="Deshabilitado" key={`${item.id}dbl`}>
                          <CancelIcon style={{ color: theme.palette.error.light }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Habilitado" key={`${item.id}ebl`}>
                          <CheckCircleIcon style={{ color: 'blue' }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>{item.phone || 'No listado'}</TableCell>
                    <TableCell>{item.model || 'No listado'}</TableCell>
                    <TableCell className={classes.columnAction} padding="none">
                      <CollectionActions
                        itemId={item.id}
                        editPath="/settings/device"
                        endpoint="devices"
                        setTimestamp={setTimestamp}
                        customActions={[actionConnections]}
                        readonly={deviceReadonly}
                      />
                    </TableCell>
                  </TableRow>
                ))

              ) : (
                <TableShimmer columns={7} endAction />
              )}
            </TableBody>
            <TablePaginationFooter
              count={items.length}
              rowsPerPage={dataRowPage}
              page={dataPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </CustomContainer>
    </PageLayout>
  );
};
  <CollectionFab editPath="/settings/device" />;

export default DevicesPage;
