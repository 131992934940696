/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Container, Fab,
  styled,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { useEffectAsync } from '../reactHelper';
import { prefixString } from '../common/util/stringUtils';
import { formatBoolean } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import ModalLayout from '../common/components/ModalLayout';
import NotificationPageModal from './NotificationPageModal';
import { devicesActions } from '../store';
import { useRestriction } from '../common/util/permissions';
import TablePaginationFooter from '../reports/components/TablePaginationFooter';
import GeofencesValue from '../common/components/GeofencesValue';
import { speedFromKnots } from '../common/util/converter';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      bottom: `calc(${theme.dimensions.bottomBarHeight}px + ${theme.spacing(2)})`,
    },
  },
}));

const CustomContainer = styled(Container)({
  maxWidth: 1200,
});

const NotificationsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const showNotificationModal = useSelector((state) => state.devices.showNotificationModal);
  const readonly = useRestriction('readonly');
  const [modal, setModal] = useState(false);

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // useEffect(() => { dispatch(devicesActions.showNotificationModal(false)); }, []);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/notifications');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const formatList = (prefix, value) => {
    if (value) {
      return value
        .split(/[, ]+/)
        .filter(Boolean)
        .map((it) => t(prefixString(prefix, it)))
        .join(', ');
    }
    return '';
  };

  const handleClose = () => {
    setModal(false);
    setTimestamp(Date.now());
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedNotifications']}>
      <CustomContainer>
        <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('notificationType')}</TableCell>
              <TableCell>{t('notificationAlways')}</TableCell>
              <TableCell>{t('sharedAlarms')}</TableCell>
              <TableCell>{t('notificationNotificators')}</TableCell>
              <TableCell>{t('sharedDescription')}</TableCell>
              <TableCell>{t('sharedGeofences')}</TableCell>
              <TableCell>{t('attributeSpeedLimit')}</TableCell>
              <TableCell>{t('alarmTow')}</TableCell>
              <TableCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.filter(filterByKeyword(searchKeyword))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{t(prefixString('event', item.type))}</TableCell>
                  <TableCell>{formatBoolean(item.always, t)}</TableCell>
                  <TableCell>{formatList('alarm', item.attributes.alarms)}</TableCell>
                  <TableCell>{formatList('notificator', item.notificators)}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.configuration?.geofences && (<GeofencesValue geofenceIds={item.configuration?.geofences} />)}</TableCell>
                  <TableCell>{ item.configuration?.deviceAttribute?.speed && `${speedFromKnots(item.configuration?.deviceAttribute?.speed, 'kmh')} km/h`}</TableCell>
                  <TableCell>{item.configuration?.deviceAttribute?.remolqueDistance && `${item.configuration?.deviceAttribute?.remolqueDistance} m`}</TableCell>
                  <TableCell className={classes.columnAction} padding="none">
                    <CollectionActions
                      itemId={item.id}
                      notificationModalClose={handleClose}
                      editPath="/settings/notification"
                      endpoint="notifications"
                      setTimestamp={setTimestamp}
                    />
                  </TableCell>
                </TableRow>
              )) : (<TableShimmer columns={5} endAction />)}
          </TableBody>
          <TablePaginationFooter
            count={items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Table>

        {(modal) && (
        <ModalLayout title={t('configAlert') || 'Notification'} width={950} open={modal} handleClose={handleClose}>
          <NotificationPageModal handleClose={handleClose} />
        </ModalLayout>
        )}
        {(!readonly) && (
        <Fab size="medium" color="primary" className={classes.fab} onClick={() => setModal(true)}>
          <AddIcon />
        </Fab>
        )}

      </CustomContainer>
    </PageLayout>
  );
};

export default NotificationsPage;
