import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import React from 'react';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import UsbIcon from '@mui/icons-material/Usb';
import UsbOffIcon from '@mui/icons-material/UsbOff';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import {
  formatPercentage,
  formatVoltage,
} from '../../util/formatter';

// eslint-disable-next-line no-unused-vars
const CardPower = ({ classes, theme, t, handleClose, position, device, card }) => (
  <Card elevation={4} className={classes.card}>
    <CardHeader
      title={(
        <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
          {t(card) ?? 'Power'}
        </Typography>
          )}
      align="center"
      action={(
        <IconButton
          onClick={handleClose}
        >
          <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
        </IconButton>
          )}
      sx={{
        backgroundColor: theme.palette.colors.primary,
        color: '#f5f5f5',
        paddingY: 0.1,
      }}
    />
    <CardContent className={classes.cardContent}>
      <List dense className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>Voltaje: </b>
                {' '}
                {position?.attributes?.hasOwnProperty('power') && position?.attributes?.hasOwnProperty('power') && (
                <span style={{ paddingLeft: 4 }} className={position.attributes.power === 0 ? classes.unknown : ''}>
                  {`${formatVoltage(position.attributes.power, t)}`}
                </span>
                )}
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <b>Nivel Bateria: </b>
                {' '}
                {position?.attributes?.hasOwnProperty('batteryLevel') && (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 6 }}>
                    <span className={position.attributes.batteryLevel > 70 ? classes.positive : position.attributes.batteryLevel > 30 ? classes.medium : classes.negative}>
                      {`${formatPercentage(position.attributes.batteryLevel)}`}
                    </span>
                    {position.attributes.batteryLevel > 70 ? (
                      position.attributes.charge
                        ? (<BatteryChargingFullIcon className={classes.positive} />)
                        : (<BatteryFullIcon className={classes.positive} />)
                    ) : position.attributes.batteryLevel > 30 ? (
                      position.attributes.charge
                        ? (<BatteryCharging60Icon className={classes.medium} />)
                        : (<Battery60Icon className={classes.medium} />)
                    ) : (
                      position.attributes.charge
                        ? (<BatteryCharging20Icon className={classes.negative} />)
                        : (<Battery20Icon className={classes.negative} />)
                    )}
                  </div>
                )}
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>Voltaje Bateria: </b>
                {' '}
                {position?.attributes?.hasOwnProperty('battery') && position?.attributes?.hasOwnProperty('battery') && (
                <span style={{ paddingLeft: 4 }} className={position.attributes.battery === 0 ? classes.unknown : ''}>
                  {`${formatVoltage(position.attributes.battery, t)}`}
                </span>
                )}
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>
                  Sensor Luz:
                  {' '}
                  {position?.attributes?.hasOwnProperty('lightSensor') && position?.attributes?.hasOwnProperty('lightSensor') && (
                  <span style={{ paddingLeft: 4 }} className={position.attributes.lightSensor === 0 ? classes.unknown : ''}>
                    {`${formatVoltage(position.attributes.lightSensor, t)}`}
                  </span>
                  )}
                </b>
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div>
                <b>Voltaje Solar: </b>
                {' '}
                {position?.attributes?.hasOwnProperty('solarPanel') && position?.attributes?.hasOwnProperty('solarPanel') && (
                <span style={{ paddingLeft: 4 }} className={position.attributes.solarPanel === 0 ? classes.unknown : ''}>
                  {`${formatVoltage(position.attributes.solarPanel, t)}`}
                </span>
                )}
              </div>
                )}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listItemText}
            primary={(
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <b>Cargando: </b>
                {' '}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 6 }}>
                  {
                      position?.attributes?.charge ? (
                        <UsbIcon className={classes.positive} />
                      ) : (
                        <UsbOffIcon className={classes.unknown} />
                      )
                    }
                  <SolarPowerIcon className={position?.attributes?.chargeSolar ? classes.positive : classes.unknown} />
                </div>
              </div>
                )}
          />
        </ListItem>
      </List>
    </CardContent>
  </Card>
);

export default CardPower;
