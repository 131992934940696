import React from 'react';
import {
  Divider, List, ListItemButton, ListItemIcon, ListItemText, useTheme,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import TimelineIcon from '@mui/icons-material/Timeline';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import BarChartIcon from '@mui/icons-material/BarChart';
import GpsOffOutlinedIcon from '@mui/icons-material/GpsOffOutlined';
import RouteIcon from '@mui/icons-material/Route';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useAdministrator, useRestriction } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => {
  const theme = useTheme();
  return (
    <ListItemButton
      key={link}
      component={Link}
      to={link}
      selected={selected}
      style={{
        backgroundColor: selected && 'rgba(0, 0, 0, 0.1)',
      }}
    >
      <ListItemIcon style={{ color: theme.palette.colors.icon }}>{icon}</ListItemIcon>
      <ListItemText style={{ color: theme.palette.colors.icon }}>{title}</ListItemText>
    </ListItemButton>
  );
};

const ReportsMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  const admin = useAdministrator();
  const readonly = useRestriction('readonly');

  return (
    <>
      <List>
        <MenuItem
          title={t('sharedStart')}
          link="/"
          icon={<HomeOutlinedIcon />}
          selected={location.pathname === '/'}
        />
        <MenuItem
          title={t('reportCombined')}
          link="/reports/combined"
          icon={<StarIcon />}
          selected={location.pathname === '/reports/combined'}
        />
        <MenuItem
          title={t('reportRoute')}
          link="/reports/route"
          icon={<TimelineIcon />}
          selected={location.pathname === '/reports/route'}
        />
        <MenuItem
          title={t('reportEvents')}
          link="/reports/event"
          icon={<NotificationsActiveIcon />}
          selected={location.pathname === '/reports/event'}
        />
        <MenuItem
          title={t('sharedAlarms')}
          link="/reports/alarm"
          icon={<NotificationImportantIcon />}
          selected={location.pathname === '/reports/alarm'}
        />
        <MenuItem
          title={t('reportTrips')}
          link="/reports/trip"
          icon={<PlayCircleFilledIcon />}
          selected={location.pathname === '/reports/trip'}
        />
        <MenuItem
          title={t('reportStops')}
          link="/reports/stop"
          icon={<PauseCircleFilledIcon />}
          selected={location.pathname === '/reports/stop'}
        />
        <MenuItem
          title={t('reportPOIS')}
          link="/reports/pois"
          icon={<ShareLocationIcon />}
          selected={location.pathname === '/reports/pois'}
        />
        <MenuItem
          title={t('reportDeviceOffline')}
          link="/reports/offdevices"
          icon={<GpsOffOutlinedIcon />}
          selected={location.pathname === '/reports/offdevices'}
        />
        <MenuItem
          title={t('reportSummary')}
          link="/reports/summary"
          icon={<FormatListBulletedIcon />}
          selected={location.pathname === '/reports/summary'}
        />
        <MenuItem
          title={t('reportChart')}
          link="/reports/chart"
          icon={<TrendingUpIcon />}
          selected={location.pathname === '/reports/chart'}
        />
        <MenuItem
          title={t('reportReplay')}
          link="/replay"
          icon={<RouteIcon />}
        />
      </List>
      {(admin || !readonly) && (
        <>
          <Divider />
          <List>
            <MenuItem
              title={t('reportScheduled')}
              link="/reports/scheduled"
              icon={<EventRepeatIcon />}
            />
            {admin && (
              <MenuItem
                title={t('statisticsTitle')}
                link="/reports/statistics"
                icon={<BarChartIcon />}
                selected={location.pathname === '/reports/statistics'}
              />
            )}
          </List>
        </>
      )}
    </>
  );
};

export default ReportsMenu;
