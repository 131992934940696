import { useTheme } from '@mui/styles';
import { useId, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { map, setMarkerEnd, setMarkerInit } from './core/MapView';
import { findFonts } from './core/mapUtil';
import { usePreference } from '../common/util/preferences';

const dashArraySequence = [
  [0, 4, 3],
  [0.5, 4, 2.5],
  [1, 4, 2],
  [1.5, 4, 1.5],
  [2, 4, 1],
  [2.5, 4, 0.5],
  [3, 4, 0],
  [0, 0.5, 3, 3.5],
  [0, 1, 3, 3],
  [0, 1.5, 3, 2.5],
  [0, 2, 3, 2],
  [0, 2.5, 3, 1.5],
  [0, 3, 3, 1],
  [0, 3.5, 3, 0.5],
];

let step = 0;
let handle = 0;

const MapRoutePath = ({ name, positions, coordinates, color, animatedRoute }) => {
  const id = useId();
  const theme = useTheme();
  const hours12 = usePreference('twelveHourFormat');

  const reportColor = useSelector((state) => {
    const position = positions?.find(() => true);
    if (position) {
      const attributes = state.devices.items[position.deviceId]?.attributes;
      if (attributes) {
        const color = attributes['web.reportColor'];
        if (color) {
          return color;
        }
      }
    }
    return theme.palette.colors.mapLine;
  });

  const animateDashArray = (timestamp) => {
    // Update line-dasharray using the next value in dashArraySequence. The
    // divisor in the expression `timestamp / 50` controls the animation speed.
    const newStep = parseInt(
      (timestamp / 50) % dashArraySequence.length,
      10,
    );

    if (newStep !== step && animatedRoute) {
      map.setPaintProperty(
        `${id}-line-dashed`,
        'line-dasharray',
        dashArraySequence[step],
      );
      step = newStep;
    }

    // Request the next frame of the animation.
    handle = requestAnimationFrame(animateDashArray);
  };

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      source: id,
      id: `${id}-line`,
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 5,
      },
    });
    if (name) {
      map.addLayer({
        source: id,
        id: `${id}-title`,
        type: 'symbol',
        layout: {
          'text-field': '{name}',
          'text-font': findFonts(map),
          'text-size': 12,
        },
        paint: {
          'text-halo-color': 'white',
          'text-halo-width': 1,
        },
      });
    }

    return () => {
      cancelAnimationFrame(handle);
      step = 0;
      handle = 0;
      if (map.getLayer(`${id}-line-background`)) {
        map.removeLayer(`${id}-line-background`);
      }
      if (map.getLayer(`${id}-line-dashed`)) {
        map.removeLayer(`${id}-line-dashed`);
      }
      if (map.getLayer(`${id}-title`)) {
        map.removeLayer(`${id}-title`);
      }
      if (map.getLayer(`${id}-line`)) {
        map.removeLayer(`${id}-line`);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);

  useEffect(() => {
    setMarkerInit(null);
    setMarkerEnd(null);
    if (!coordinates) {
      coordinates = positions?.map((item) => [item.longitude, item.latitude]) || [];
    }
    const devicesTime = positions?.map((item) => item.deviceTime);
    if (coordinates.length > 0 && devicesTime) {
      setMarkerInit(coordinates[0], devicesTime[0], hours12);
    }
    if (coordinates.length > 1 && devicesTime) {
      setMarkerEnd(coordinates[coordinates.length - 1], devicesTime[devicesTime.length - 1], hours12);
    }
    map.getSource(id)?.setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: {
        name,
        color: color || reportColor,
      },
    });
  }, [theme, positions, coordinates, color, reportColor]);

  useEffect(() => {
    if (animatedRoute) {
      map.addLayer({
        type: 'line',
        source: id,
        id: `${id}-line-background`,
        paint: {
          'line-color': '#ffeb3ba6',
          'line-width': 3,
          'line-opacity': 0.3,
        },
      });

      // add a line layer with line-dasharray set to the first value in dashArraySequence
      map.addLayer({
        type: 'line',
        source: id,
        id: `${id}-line-dashed`,
        paint: {
          'line-color': '#ffeb3ba6',
          'line-width': 3,
          'line-dasharray': [0, 4, 3],
        },
      });
      animateDashArray(0);
    } else {
      cancelAnimationFrame(handle);
    }
    return () => {
      cancelAnimationFrame(handle);
      step = 0;
      handle = 0;
      if (map.getLayer(`${id}-line-background`)) {
        map.removeLayer(`${id}-line-background`);
      }
      if (map.getLayer(`${id}-line-dashed`)) {
        map.removeLayer(`${id}-line-dashed`);
      }
    };
  }, [animatedRoute]);

  return null;
};

export default MapRoutePath;
