import {
  loadImage, prepareIcon, prepareIconWithoutBackground,
} from './mapUtil';

import directionSvg from '../../resources/images/direction.svg';
import LocationArrowSvg from '../../resources/images/icon/location-arrow.svg';
import LocationArrowIgnitionSvg from '../../resources/images/icon/location-arrow-ignition.svg';
import LocationArrowOfflineSvg from '../../resources/images/icon/location-arrow-offline.svg';
import LocationArrowUnknownSvg from '../../resources/images/icon/location-arrow-unknown.svg';
import LocationArrowNoGpsSvg from '../../resources/images/icon/location-arrow-no-gps.svg';
import vehicleNoGps from '../../resources/images/icon/vehicle-no-gps.svg';
import vehicleParking from '../../resources/images/icon/vehicle-parking.svg';
import vehicleParkingOffline from '../../resources/images/icon/vehicle-parking_offline.svg';
import vehicleOfflineNoGps from '../../resources/images/icon/vehicle-offline-no-gps.svg';
import backgroundSvg from '../../resources/images/background.svg';
import animalSvg from '../../resources/images/icon/animal.svg';
import bicycleSvg from '../../resources/images/icon/bicycle.svg';
import boatSvg from '../../resources/images/icon/boat.svg';
import busSvg from '../../resources/images/icon/bus.svg';
import carSvg from '../../resources/images/icon/car.svg';
import craneSvg from '../../resources/images/icon/crane.svg';
import defaultSvg from '../../resources/images/icon/default.svg';
import helicopterSvg from '../../resources/images/icon/helicopter.svg';
import motorcycleSvg from '../../resources/images/icon/motorcycle.svg';
import offroadSvg from '../../resources/images/icon/offroad.svg';
import personSvg from '../../resources/images/icon/person.svg';
import pickupSvg from '../../resources/images/icon/pickup.svg';
import planeSvg from '../../resources/images/icon/plane.svg';
import scooterSvg from '../../resources/images/icon/scooter.svg';
import shipSvg from '../../resources/images/icon/ship.svg';
import tractorSvg from '../../resources/images/icon/tractor.svg';
import trainSvg from '../../resources/images/icon/train.svg';
import tramSvg from '../../resources/images/icon/tram.svg';
import trolleybusSvg from '../../resources/images/icon/trolleybus.svg';
import truckSvg from '../../resources/images/icon/truck.svg';
import vanSvg from '../../resources/images/icon/van.svg';

export const mapIcons = {
  animal: animalSvg,
  bicycle: bicycleSvg,
  boat: boatSvg,
  bus: busSvg,
  car: carSvg,
  crane: craneSvg,
  default: defaultSvg,
  helicopter: helicopterSvg,
  motorcycle: motorcycleSvg,
  offroad: offroadSvg,
  person: personSvg,
  pickup: pickupSvg,
  plane: planeSvg,
  scooter: scooterSvg,
  ship: shipSvg,
  tractor: tractorSvg,
  train: trainSvg,
  tram: tramSvg,
  trolleybus: trolleybusSvg,
  truck: truckSvg,
  van: vanSvg,
  LocationArrow: LocationArrowSvg,
};

export const vehicleStatus = {
  ignition: LocationArrowIgnitionSvg, // TODO ignition arrow up
  // offline: LocationArrowOfflineSvg, // TODO ignition arrow up
  'ignition-no-gps': LocationArrowNoGpsSvg,
  'no-gps': vehicleNoGps,
  parking: vehicleParking,
  'parking-offline': vehicleParkingOffline,
  'offline-no-gps': vehicleOfflineNoGps,
  'ignition-offline': LocationArrowOfflineSvg,
  unknown: LocationArrowUnknownSvg,
  default: LocationArrowSvg,
};

export const mapIconKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'default');
export const vehicleIconKey = (status) => (vehicleStatus.hasOwnProperty(status) ? status : 'default');

export const mapImages = {};

export default async () => {
  const background = await loadImage(backgroundSvg);
  mapImages.background = await prepareIcon(background);
  mapImages.direction = await prepareIcon(await loadImage(directionSvg));
  await Promise.all(
    Object.keys(vehicleStatus).map(
      async (status) => loadImage(vehicleStatus[status]).then(
        async (icon) => mapImages[`vehicle-${status}`] = prepareIconWithoutBackground(icon, 50),
      ),
    ),
  );
};
