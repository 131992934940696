/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalLoading } from 'react-global-loading';
import { LinearProgress, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider } from 'notistack';
import BottomMenu from './common/components/BottomMenu';
import SocketController from './SocketController';
import CachingController from './CachingController';
import { useEffectAsync } from './reactHelper';
import { sessionActions } from './store';

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: 'auto',
  },
  menu: {
    zIndex: 4,
  },
}));

const App = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const newServer = useSelector((state) => state.session.server.newServer);
  const initialized = useSelector((state) => !!state.session.user);

  useEffectAsync(async () => {
    if (!initialized) {
      const response = await fetch('/api/session');
      if (response.ok) {
        dispatch(sessionActions.updateUser(await response.json()));
      } else if (newServer) {
        navigate('/register');
      } else {
        navigate('/login');
      }
    }
    return null;
  }, [initialized]);

  return !initialized ? (<LinearProgress />) : (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <SocketController />
      <CachingController />
      <GlobalLoading backgroundColor="#000000cc">
        <div className="loader-cont">
          <span className="loader" />
          <svg
            style={{ height: 130 }}
            version="1.1"
            id="L3"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <circle fill="#000000c0" stroke="#ffc20dd0" strokeWidth="5" cx="50" cy="50" r="36" style={{ opacity: 0.7 }}>
              <animate attributeName="r" dur="4s" values="36;47;36" keyTimes="0;0.5;1" repeatCount="indefinite" />
              <animate attributeName="opacity" dur="4s" values="1;0.5;0.1;0.5;1" keyTimes="0;0.25;0.5;0.75;1" repeatCount="indefinite" />
            </circle>
          </svg>
        </div>
      </GlobalLoading>
      <div className={classes.page}>
        <Outlet />
      </div>
      {!desktop && (
        <div className={classes.menu}>
          <BottomMenu />
        </div>
      )}
    </SnackbarProvider>
  );
};

export default App;
