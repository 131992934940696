/* eslint-disable no-unused-vars */
import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  LinearProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { devicesActions } from '../../../store';
import ModalLayout from '../ModalLayout';
import EngineCutModal from '../EngineCutModal';
import { useCatch } from '../../../reactHelper';

// eslint-disable-next-line no-unused-vars
const CardEngineCut = ({
  classes,
  theme,
  t,
  handleClose,
  position,
  device,
  card,
}) => {
  const dispatch = useDispatch();
  const showEngineCutModal = useSelector(
    (state) => state.devices.showEngineCutModal,
  );
  const [blockedStatus, setBlockedStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    if (position?.attributes?.hasOwnProperty('blocked')) {
      setBlockedStatus(
        position?.attributes?.blocked === 1 ||
          position?.attributes?.blocked === true,
      );
    } else {
      setBlockedStatus(null);
    }
  }, [position, device]);

  const handleSendCommand = useCatch(async (blocked) => {
    setLoading(true);
    setHasError(false);
    setIsBlocked(blocked);
    let command;
    try {
      command = {
        type: 'custom',
        attributes: {
          data: 'google#',
        },
      };
      const devicesId = [device?.id];
      const response = await fetch('/api/commands/sends', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ devicesId, command }),
      });
      if (response.ok) {
        if (blocked) {
          command.attributes.data = 'relay,0#';
          const resp = await fetch('/api/commands/sends', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ devicesId, command }),
          });
          if (resp.ok) {
            setTimeout(async () => {
              // wait 2 seconds
              command.attributes.data = 'google#';
              const res = await fetch('/api/commands/sends', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ devicesId, command }),
              });
              if (!res.ok) {
                throw Error(await response.text());
              }
            }, 2100);
          } else {
            throw Error(await response.text());
          }
        }
        setLoading(false);
        dispatch(devicesActions.showEngineCutModal(true));
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      setHasError(true);
      setLoading(false);
    }
  });

  return (
    <>
      <Card
        elevation={4}
        className={classes.card}
        style={{ minWidth: '180px' }}
      >
        <CardHeader
          title={(
            <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
              {t(card) ?? 'Engine Cut'}
            </Typography>
          )}
          align="center"
          action={(
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
            </IconButton>
          )}
          sx={{
            backgroundColor: theme.palette.colors.primary,
            color: '#f5f5f5',
            paddingY: 0.1,
          }}
        />
        <CardContent className={classes.cardContent}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText
                className={classes.listItemText}
                primary={(
                  <div style={{ paddingTop: 8, textAlign: 'center' }}>
                    {blockedStatus === false && (
                      <>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <b>Estado: </b>
                          <span
                            className={classes.negative}
                            style={{ paddingLeft: 2 }}
                          >
                            Desactivado
                          </span>
                        </div>
                        <br />
                        <div>
                          <Button
                            variant="contained"
                            color="success"
                            size="medium"
                            sx={{ width: 140 }}
                            onClick={() => handleSendCommand(false)}
                          >
                            CORTAR MOTOR
                          </Button>
                        </div>
                      </>
                    )}
                    {blockedStatus === true && (
                      <>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <b>Estado: </b>
                          <span
                            className={classes.positive}
                            style={{ paddingLeft: 2 }}
                          >
                            Activado
                          </span>
                        </div>
                        <br />
                        <div>
                          <Button
                            variant="contained"
                            color="error"
                            size="medium"
                            sx={{ width: 140 }}
                            onClick={() => handleSendCommand(true)}
                          >
                            ANULAR CORTE
                          </Button>
                        </div>
                      </>
                    )}
                    {loading && (
                      <Box sx={{ width: 136, margin: '0 auto' }}>
                        <LinearProgress
                          color="primary"
                          variant="indeterminate"
                          sx={{ transition: 'none' }}
                        />
                      </Box>
                    )}
                    {blockedStatus === null && (
                      <>
                        <b>Estado: </b>
                        <span
                          className={classes.neutral}
                          style={{ paddingLeft: 2 }}
                        >
                          Desconocido
                        </span>
                      </>
                    )}
                    {hasError && (
                      <p
                        className={classes.negative}
                        style={{ paddingTop: 4, margin: 0, fontSize: '11pt' }}
                      >
                        Error al conectar.
                      </p>
                    )}
                  </div>
                )}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      {showEngineCutModal && (
        <ModalLayout
          title={t(card) ?? 'Engine Cut'}
          open={showEngineCutModal}
          width={420}
          handleClose={() => dispatch(devicesActions.showEngineCutModal(false))}
        >
          <EngineCutModal
            handleClose={() => dispatch(devicesActions.showEngineCutModal(false))}
            position={position}
            device={device}
            classes={classes}
            theme={theme}
            t={t}
            isBlocked={isBlocked}
          />
        </ModalLayout>
      )}
    </>
  );
};

export default CardEngineCut;
