/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl, Typography, Accordion, AccordionDetails, AccordionSummary, OutlinedInput, InputLabel, MenuItem, Select,
} from '@mui/material';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import SplitButton from '../../common/components/SplitButton';
import InfinitiScroll from '../../settings/common/InfinitiScroll';

const ReportFilterOffDevices = ({
  handleSubmit, showOnly, emptySearch,
}) => {
  const classes = useReportStyles();
  const t = useTranslation();

  const [timeValue, setTimeValue] = useState();
  const [button, setButton] = useState('json');
  const [selectedFrom, setSelectedFrom] = useState(0);

  const [always, setAlways] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [value, setValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const disabled = (selectedItems.length === 0 || !selectedFrom || value === null);

  const handleClick = (type) => {
    const selectIdsDevices = [];
    Object.values(selectedItems).forEach((dev) => {
      selectIdsDevices.push(dev.id);
    });
    handleSubmit({
      deviceIds: selectIdsDevices,
      offlineTime: selectedFrom,
      type,
    });
    setIsOpen(false);
  };

  const valueDataTime = (time) => {
    setTimeValue(time);
    switch (value) {
      case 'horas':
        setSelectedFrom(moment.duration(time, 'hours').asMilliseconds());
        break;
      case 'dias':
        setSelectedFrom(moment.duration(time, 'days').asMilliseconds());
        break;
      case 'minutos':
        setSelectedFrom(moment.duration(time, 'minutes').asMilliseconds());
        break;
      case 'segundos':
        setSelectedFrom(moment.duration(time, 'seconds').asMilliseconds());
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <FormControl fullWidth>
          <Accordion
            expanded={isOpen}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon onClick={() => setIsOpen(!isOpen)} />}>
              <Typography variant="subtitle1">
                {t('reportDevice')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <InfinitiScroll selectedItems={selectedItems} setSelectedItems={setSelectedItems} always={always} setAlways={setAlways} />
            </AccordionDetails>
          </Accordion>
        </FormControl>
      </div>
      <div className={classes.filterItem}>
        <FormControl fullWidth>
          {/* <FormLabel>{t('reportDeviceTimeOffline')}</FormLabel> */}

          {/* <RadioButtonReport options={options} value={value} setValue={setValue} /> */}
          <InputLabel>{t('reportDeviceTimeOffline')}</InputLabel>
          <Select
            value={value}
            label={t('reportDeviceTimeOffline')}
            onChange={(e) => setValue(e.target.value)}
          >
            <MenuItem value="horas">Horas</MenuItem>
            <MenuItem value="dias">Dias</MenuItem>
            <MenuItem value="minutos">Minutos</MenuItem>
            <MenuItem value="segundos">Segundos</MenuItem>
          </Select>

          {/* <RadioGroup row>
            <FormControlLabel
              value="json"
              control={<Radio color="primary" />}
              label="seg"
              // onClick={setTimeLavel('Seg')}
            />
            <FormControlLabel
              value="w"
              control={<Radio color="primary" />}
              label="min"
              // onClick={setTimeLavel('Mint')}
            />
            <FormControlLabel
              value="expoert"
              control={<Radio color="primary" />}
              label="h"
              // onClick={setTimeLavel('Hora')}
            />
            <FormControlLabel
              value="exporrt"
              control={<Radio color="primary" />}
              label="días"
              // onClick={setTimeLavel('Días')}
            />
          </RadioGroup> */}

        </FormControl>
      </div>
      <div className={classes.filterItem}>

        <FormControl>
          <InputLabel>
            {value}
          </InputLabel>
          <OutlinedInput
            disabled={value === null}
            style={{
              width: 150,
            }}
            label={value}
            type="number"
            value={timeValue}
            onChange={(e) => valueDataTime(e.target.value)}
            pattern="[1-9]\d{0,2}$"
          />
        </FormControl>
      </div>
      <div>
        <SplitButton
          fullWidth
          variant="outlined"
          color="secondary"
          disabled={disabled}
          onClick={handleClick}
          selected={button}
          setSelected={(value) => setButton(value)}
          options={{
            json: t('reportShow'),
            export: t('reportExport'),
          }}
        />
      </div>
    </div>
  );
};

export default ReportFilterOffDevices;
