import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  Divider, List, ListItemButton, ListItemText,
} from '@mui/material';

import { geofencesActions } from '../store';
import CollectionActions from '../settings/components/CollectionActions';
import { useCatchCallback } from '../reactHelper';
import MapViewPoisModal from '../map/core/MapViewPoisModal';
import MapViewGeofenceModal from '../map/core/MapViewGeofenceModal';

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: '100%',
    overflow: 'auto',
    scrollbarWidth: 'thin',
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
}));

const GeofencesList = ({ onGeofenceSelected, isPoi }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const items = useSelector((state) => state.geofences.items);

  const [poi, setPoi] = useState(null);
  const [openPoi, setOpenPoi] = useState(false);
  const [loadingPoi, setLoadingPoi] = useState(false);

  const [openGeofence, setOpenGeofence] = useState(false);
  const [loadingGeofence, setLoadingGeofence] = useState(false);
  const [geofence, setGeofence] = useState(null);

  const refreshGeofences = useCatchCallback(async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [dispatch]);

  return (
    <>
      <List className={classes.list}>
        {Object.values(items).filter((item) => (isPoi ? item.poi : !item.poi)).map((item, index, list) => (
          <Fragment key={item.id}>
            <ListItemButton key={item.id} onClick={() => onGeofenceSelected(item.id)}>
              <ListItemText primary={item.name} />
              <CollectionActions
                itemId={item.id}
                item={item}
                editPath="/settings/geofence"
                endpoint="geofences"
                setTimestamp={refreshGeofences}
                isPoi={isPoi}
                setOpenPoi={setOpenPoi}
                setPoi={setPoi}
                setOpenGeofence={setOpenGeofence}
                setGeofence={setGeofence}
              />
            </ListItemButton>
            {index < list.length - 1 ? <Divider /> : null}
          </Fragment>
        ))}
      </List>
      {(openPoi && poi) && (<MapViewPoisModal openPoi={openPoi} setOpenPoi={setOpenPoi} loadingPoi={loadingPoi} setLoadingPoi={setLoadingPoi} poi={poi} />)}
      {(openGeofence && geofence) && (<MapViewGeofenceModal geofence={geofence} openGeofence={openGeofence} setOpenGeofence={setOpenGeofence} loadingGeofence={loadingGeofence} setLoadingGeofence={setLoadingGeofence} />)}
    </>
  );
};

export default GeofencesList;
