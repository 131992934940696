/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    selectedId: null,
    selectedIds: [],
    showEditModal: false,
    showEngineCutModal: false,
    showNotificationModal: false,
    showSidebar: false,
    dataPage: 0,
    dataRowPage: 25,
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    update(state, action) {
      action.payload.forEach((item) => state.items[item.id] = { ...item });
    },
    updateStatusV(state, action) {
      state.items[action.payload.id].statusv = action.payload.statusv;
      state.items[action.payload.id].positionId = action.payload.positionId;
      state.items[action.payload.id].lastUpdate = action.payload.lastUpdate;
    },
    select(state, action) {
      state.selectedId = action.payload;
    },
    selectId(state, action) {
      state.selectedId = action.payload;
      state.selectedIds = state.selectedId ? [state.selectedId] : [];
    },
    selectIds(state, action) {
      state.selectedIds = action.payload;
      [state.selectedId] = state.selectedIds;
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
    showEditModal(state, action) {
      state.showEditModal = action.payload;
    },
    showEngineCutModal(state, action) {
      state.showEngineCutModal = action.payload;
    },
    showNotificationModal(state, action) {
      state.showNotificationModal = action.payload;
    },
    showSidebar(state, action) {
      state.showSidebar = action.payload;
    },
    dataPage(state, action) {
      state.dataPage = action.payload;
    },
    dataRowPage(state, action) {
      state.dataRowPage = action.payload;
    },
  },
});

export { actions as devicesActions };
export { reducer as devicesReducer };
