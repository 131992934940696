import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material';
import React from 'react';
import moment from 'moment';
import { formatStatus, formatTime, getStatusColor } from '../../util/formatter';
import { usePreference } from '../../util/preferences';

// eslint-disable-next-line no-unused-vars
const CardResume = ({ classes, theme, t, handleClose, position, device }) => {
  const hours12 = usePreference('twelveHourFormat');
  let itemStatus = device.status;
  if (device.lastUpdate) {
    const now = moment();
    const targetDate = moment(device.lastUpdate);
    const diffInMinutes = now.diff(targetDate, 'minutes');
    if (diffInMinutes > 10) {
      itemStatus = 'offline';
    }
  }
  const status = formatStatus(itemStatus, t);
  // status = moment(device.lastUpdate).fromNow();
  const date = device.lastUpdate
    ? moment(device.lastUpdate).fromNow()
    : '';
  return (
    <Card elevation={4} className={classes.card} style={{ minWidth: '260px' }}>
      <CardHeader
        title={(
          <Typography sx={{ fontSize: '11pt', textAlign: 'left' }}>
            {device?.name?.length > 20 ? `${device?.name.substring(0, 20)}...` : device?.name }
          </Typography>
          )}
        align="center"
        action={(
          <IconButton
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: '#f5f5f5', height: 20 }} />
          </IconButton>
          )}
        sx={{
          backgroundColor: theme.palette.colors.primary,
          color: '#f5f5f5',
          paddingY: 0.1,
        }}
      />
      <CardContent className={classes.cardContent}>
        <List dense className={classes.list}>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>Modelo: </b>
                  {' '}
                  { device.model }
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>ID: </b>
                  {' '}
                  { device.uniqueId }
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>Estado: </b>
                  {' '}
                  <span className={classes[getStatusColor(itemStatus)]}>{status}</span>
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>Último Reporte: </b>
                  {' '}
                  <Tooltip
                    title={formatTime(device?.lastUpdate, 'seconds', hours12)}
                    placement="bottom"
                    leaveDelay={0}
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <span>{date}</span>
                  </Tooltip>
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>Protocolo Enlace: </b>
                  {' '}
                  { position?.protocol }
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>Siguiente Pago: </b>
                  {' '}
                </div>
                )}
            />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.listItemText}
              primary={(
                <div>
                  <b>VIN: </b>
                  {' '}
                </div>
                )}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default CardResume;
