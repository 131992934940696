import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
import React, {
  useRef, useLayoutEffect, useEffect, useState,
} from 'react';
import mapboxgl from 'mapbox-gl';
import { SwitcherControl } from '../switcher/switcher';
import { useAttributePreference, usePreference } from '../../common/util/preferences';
import usePersistedState, { savePersistedState } from '../../common/util/usePersistedState';
import { mapImages } from './preloadImages';
import useMapStyles from './useMapStyles';
import { formatHours, formatTime } from '../../common/util/formatter';
import { getPopupAlarmContent, getPopupEventContent } from '../MapPositionsHelper';
import dimensions from '../../common/theme/dimensions';
import MapViewPoisModal from './MapViewPoisModal';

// eslint-disable-next-line no-undef
const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';
element.style.boxSizing = 'initial';

export const map = new maplibregl.Map({
  container: element,
  attributionControl: false,
});

let markerInit = null;
let markerEnd = null;
let markersStops = [];
let markersAlarms = [];
let markersLabel = [];
let markerEvent = null;

let latLong = null;

export const getMarketInit = () => markerInit;

export const setMarkerInit = (coordinates, deviceTime, time12H) => {
  markerInit?.remove();
  if (coordinates) {
    markerInit?.remove();
    // eslint-disable-next-line no-undef
    const el = document.createElement('div');
    el.className = 'markerInit';
    // Create a popup
    const popup = new maplibregl.Popup({ closeOnClick: true, closeButton: false })
      .setHTML(`
    <h3>Inicio</h3>
    <h3>${formatTime(deviceTime, 'minutes', time12H)}</h3>
    <p style="padding-top: 5px;">
      <a style="color: #383838;" target="_blank" href="https://www.google.com/maps/search/?api=1&query=${coordinates[1]}%2C${coordinates[0]}"}>${'Google Map'}</a>
    </p>
    `);
    el.innerHTML = '<span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAQJJREFUSEvV1r9qAkEQx/HvZBfzAHkjTecTpDWVRHsRDHmC2MVaK+tEEpDzT+UD2NgFUqVJFSIIno4oeoV4cupNkev2OH6fZfd2ZgXjR4zziQduwzzKPcorfdcE0XMmEw9k5z+I3GxDR7Ao0rsen4rEA7lwb8YaAi9kfI0P+U0KnQDsIvUbkQqBbyVBzgCi2AHiHghkcgy6BAB0DjRwvkpXpoegC4FtpPKJUmbg3/eRdIBoe+iAK9GXr92rdIFNqv4hV48Err4eGQDRIt3R8+3/CFgukVptst1vujlozyz8E0OZpX3QrEqFWbFLu1ybN5x1y1xSAN5sWmaSbpLgG/NbxQpDqHoZyZ4scwAAAABJRU5ErkJggg=="></span>';
    markerInit = new maplibregl.Marker({ element: el })
      .setLngLat(coordinates)
      .addTo(map)
      .setPopup(popup);
  }
};

export const getMarketEnd = () => markerEnd;

export const setMarkerEnd = (coordinates, deviceTime, time12H) => {
  markerEnd?.remove();
  if (coordinates) {
    // eslint-disable-next-line no-undef
    const el = document.createElement('div');
    el.className = 'markerEnd';
    // Create a popup
    const popup = new maplibregl.Popup({ closeOnClick: true, closeButton: false })
      .setHTML(`
    <h3>Fin</h3>
    <h3>${formatTime(deviceTime, 'minutes', time12H)}</h3>
    <p style="padding-top: 5px;">
      <a style="color: #383838;" target="_blank" href="https://www.google.com/maps/search/?api=1&query=${coordinates[1]}%2C${coordinates[0]}"}>${'Google Map'}</a>
    </p>
    `);
    el.innerHTML = '<span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAmtJREFUSEvtlc9LVFEUxz/nzo8oiNSZsSwSnJkwiCKIoEUKLUKIoFo4GhFU2D/g2mX/RC0KooWpi0JIaBe4syCoCWV0qCZJGX1Fi4LJuSfe05keo857Fe66qwfnvvM5P79X2OEjO+wfD7AyzxWEBwITiQxD5SLdxpIT4ZyFToEDCo4ocwLPbYTJZBez/uBW5jhkolyvKlcFOoGbySxPPEC5yKhRBlSpKuSNcCIoM4WPAgULFaOkFbIiRGr/WeFxKs2gB1hdYAzoD3D6UuG4wK4g+IZ9PJEhtxmg/HBLVRXKBoZrzhIZpDzLXhOnD8sdhG7XprAGfDDwRpUphLtNAdE4iX2HcVaL9KNeZt5xAbXvhoy9SH02bQqoOVqZ57IIIyLksXxTmBbhXWuavFNk1FfSOkCViFP0MnLP1iUS5YIaBqzlZyrL7U2RKcsi3K8KaVPlqMJb15kaeo0yiHCweQ9+d3C71EHIJdKMewPSUErfAGzT5AaA5ok7cdrVUBSIeeZ/Bbi74C6UwnmUYyJI2xfiTgunVbgkwqu2LiZEsL4MFq0yZYSh5j2AnrYM0yGmqA/4pPAiFmfEnbyNnQo3RSEAtcX8szGNVUhWYlwU4RbQW2+J8hmlRITX1nLGJyd1QKnE7j0VvjctkbuZAtGQcuBeWxSYUehQOFmTEquMpbIMbKtFqliBJRXeY3HEkAK6gPYguKqrINxIZnm4lRbNYLi3BpP70yw3OvtaILMmnBWhBziF0qFCK7DkqivwLGJ52nKEhfWJblBTv+YERRrG/h8QWKX1N7nAIzFcU6gkM6FfrEDn9SaHuvmXl34B6fkmKK0+FikAAAAASUVORK5CYII="></span>';
    markerEnd?.remove();
    markerEnd = new maplibregl.Marker({ element: el })
      .setLngLat(coordinates)
      .addTo(map)
      .setPopup(popup);
  }
};

export const getMarkersStops = () => markersStops;

export const setMarkersStops = (stops, time12H) => {
  markersStops.forEach((marker) => marker.remove());
  markersStops = [];
  if (stops) {
    stops.forEach((stop, index) => {
      // eslint-disable-next-line no-undef
      const el = document.createElement('div');
      el.className = 'marker';
      el.innerHTML = `<span><b>${index + 1}</b></span>`;
      // Create a popup
      const popup = new maplibregl.Popup({ closeOnClick: true, closeButton: false })
        .setHTML(`
        <h3>${formatTime(stop.startTime, 'seconds', time12H)}</h3>
        <p>Parada: ${formatHours(stop.duration)}</p>
        <p style="padding-top: 5px;">
          <a style="color: #383838;" target="_blank" href="https://www.google.com/maps/search/?api=1&query=${stop.latitude}%2C${stop.longitude}"}>${stop.address || 'Google Map'}</a>
        </p>
        `);
      const marker = new maplibregl.Marker({ element: el })
        .setLngLat([stop.longitude, stop.latitude])
        .addTo(map)
        .setPopup(popup);
      markersStops.push(marker);
    });
  }
};

export const getMarkersAlarms = () => markersAlarms;

export const setMarkersAlarms = (alarms, t, hours12, speedUnit) => {
  markersAlarms.forEach((marker) => marker.remove());
  markersAlarms = [];
  if (alarms) {
    let count = 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < alarms.length; i++) {
      const alarm = alarms[i];
      // eslint-disable-next-line no-undef
      const el = document.createElement('div');
      el.className = 'markerAlarm';
      el.innerHTML = `<span><b>${count}</b></span>`;
      count += 1;
      let popupContent = `<p style="color: #6d6d6d;">
      ${formatTime(alarm?.alertTime, 'seconds', hours12)}
        </p> ${getPopupAlarmContent(alarm, t, speedUnit)}`;

      if (i < (alarms.length - 1) && alarms[i].positionId === alarms[i + 1].positionId) {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < alarms.length; j++) {
          popupContent += getPopupAlarmContent(alarms[j], t, speedUnit);
          i += 1;
          if (i === (alarms.length - 1) || alarms[i].positionId !== alarms[i + 1].positionId) break;
        }
      }
      // Create a popup
      const popup = new maplibregl.Popup({ closeOnClick: true, closeButton: false, offset: -8, className: 'markerEvent' })
        .setHTML(popupContent);
      const marker = new maplibregl.Marker({ element: el })
        .setLngLat([alarm.position.longitude, alarm.position.latitude])
        .addTo(map)
        .setPopup(popup);
      markersAlarms.push(marker);
    }
  }
};

export const getMarkerEvent = () => markerEvent;

export const setMarkerEvent = (positionSelected, eventSelected, address, geofenceName, t, hours12, speedUnit) => {
  markerEvent?.remove();
  if (positionSelected?.longitude && positionSelected?.latitude) {
    // Create a popup
    const popup = new maplibregl.Popup({ closeOnClick: true, closeButton: false, offset: -12, className: 'markerEvent' })
      .setHTML(getPopupEventContent(positionSelected, eventSelected, address, geofenceName, t, hours12, speedUnit));
    markerEvent?.remove();
    markerEvent = new maplibregl.Marker({ color: 'red', scale: 0.7 })
      .setLngLat([positionSelected?.longitude, positionSelected?.latitude])
      .addTo(map)
      .setPopup(popup)
      .togglePopup();
    map.jumpTo({
      center: [positionSelected?.longitude, positionSelected?.latitude],
      offset: [0, -dimensions.popupMapOffset / 2],
    });
  }
};

export const getMarkersLabel = () => markersLabel;

export const setMarkersLabel = (features) => {
  markersLabel.forEach((marker) => marker.remove());
  markersLabel = [];
  if (features && features.length > 0) {
    features.forEach((feature) => {
      const { name, vehicleStatus } = feature.properties;
      // eslint-disable-next-line no-undef
      const el = document.createElement('div');
      el.innerHTML = name;
      el.className = 'rounded-label ';
      switch (vehicleStatus) {
        case 'ignition':
        case 'parking':
          el.className += 'rounded-label-online';
          break;
        case 'no-gps':
        case 'ignition-no-gps':
          el.className += 'rounded-label-no-gps';
          break;
        case 'offline':
        case 'ignition-offline':
        case 'offline-no-gps':
        case 'parking-offline':
          el.className += 'rounded-label-offline';
          break;
        default:
          el.className += 'rounded-label-unkown';
      }

      const marker = new maplibregl.Marker({ element: el })
        .setLngLat(feature.coordinates)
        .addTo(map);
      markersLabel.push(marker);
    });
  }
};

let ready = false;
const readyListeners = new Set();

const addReadyListener = (listener) => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = (listener) => {
  readyListeners.delete(listener);
};

const updateReadyValue = (value) => {
  ready = value;
  readyListeners.forEach((listener) => listener(value));
};

const initMap = async () => {
  if (ready) return;
  if (!map.hasImage('background')) {
    Object.entries(mapImages).forEach(([key, value]) => {
      map.addImage(key, value, {
        // eslint-disable-next-line no-undef
        pixelRatio: window.devicePixelRatio,
      });
    });
  }
  setMarkerInit(null);
  setMarkerEnd(null);
  updateReadyValue(true);
};

map.addControl(new maplibregl.NavigationControl());

const switcher = new SwitcherControl(
  () => updateReadyValue(false),
  (styleId) => savePersistedState('selectedMapStyle', styleId),
  () => {
    map.once('styledata', () => {
      const waiting = () => {
        if (!map.loaded()) {
          setTimeout(waiting, 33);
        } else {
          initMap();
        }
      };
      waiting();
    });
  },
);

map.addControl(switcher);

const MapView = ({ children }) => {
  const containerEl = useRef(null);
  const menuRef = useRef();
  const [mapReady, setMapReady] = useState(false);

  const mapStyles = useMapStyles();
  const activeMapStyles = useAttributePreference('activeMapStyles', 'locationIqStreets,osm,carto');
  const [defaultMapStyle] = usePersistedState('selectedMapStyle', usePreference('map', 'locationIqStreets'));
  const mapboxAccessToken = useAttributePreference('mapboxAccessToken');
  const maxZoom = useAttributePreference('web.maxZoom');

  useEffect(() => {
    if (maxZoom) {
      map.setMaxZoom(maxZoom);
    }
  }, [maxZoom]);

  useEffect(() => {
    maplibregl.accessToken = mapboxAccessToken;
  }, [mapboxAccessToken]);

  const [openPoi, setOpenPoi] = useState(false);
  const [loadingPoi, setLoadingPoi] = useState(false);

  const handleClickOpenPoi = () => {
    setLoadingPoi(false);
    setOpenPoi(true);
  };

  const handleGeofence = async () => {
    if (latLong) {
      handleClickOpenPoi();
    }
  };

  useEffect(() => {
    const filteredStyles = mapStyles.filter((s) => s.available && activeMapStyles.includes(s.id));
    const styles = filteredStyles.length ? filteredStyles : mapStyles.filter((s) => s.id === 'osm');
    switcher.updateStyles(styles, defaultMapStyle);
  }, [mapStyles, defaultMapStyle]);

  useEffect(() => {
    const listener = (ready) => setMapReady(ready);
    addReadyListener(listener);

    menuRef.value = document.createElement('div');
    menuRef.value.class = 'selected-device-popup-action-buttons';

    const menuContext = (e) => {
      if (e.lngLat) {
        const coordinates = e.lngLat;
        latLong = e.lngLat;
        const description = `
        <div style="display: flex; justify-content: space-evenly;">
          <a href="${`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${e.lngLat.lat}%2C${e.lngLat.lng}`}" target="_blank" style="color: black; padding: 0 5px;">
            <div class="selected-device-popup-tooltip">
              <span class="material-icons"> public </span>
              <span class="selected-device-popup-tooltiptext">Google Street View</span>
            </div>
          </a>
          <a href="${`https://www.google.com/maps/search/?api=1&query=${e.lngLat.lat}%2C${e.lngLat.lng}`}" target="_blank" style="color: black; padding: 0 5px;">
            <div class="selected-device-popup-tooltip">
              <span class="material-icons"> map </span>
              <span class="selected-device-popup-tooltiptext">Google Map</span>
            </div>
          </a>
          <div class="selected-device-popup-tooltip" style="cursor: pointer">
              <span class="material-icons" id="span_item-poi"> add_location </span>
              <span class="selected-device-popup-tooltiptext">Crear POI</span>
          </div>
        </div>
        `;

        menuRef.value.innerHTML = description;

        // .setDOMContent(menuRef.value);

        const popups = document.getElementsByClassName('maplibregl-popup');
        const popupsM = document.getElementsByClassName('mapboxgl-popup');
        if (popups.length) {
          popups[0].remove();
        }
        if (popupsM.length) {
          popupsM[0].remove();
        }
        const pop = new mapboxgl.Popup({ closeButton: false, closeOnMove: true })
          .setLngLat(coordinates)
          .setMaxWidth('100px')
          .setOffset(1)
          // .setHTML(description)
          .setDOMContent(menuRef.value)
          .addTo(map);
        pop.addClassName('mapboxgl-popup-custom-content');
      } else { latLong = null; }
    };

    menuRef.value.addEventListener('click', async (ev) => {
      const targetId = ev.target.id.split('_')[1];
      switch (targetId) {
        case 'item-poi':
          await handleGeofence();
          break;
        default:
          break;
      }
    });

    map.on('contextmenu', menuContext);

    return () => {
      removeReadyListener(listener);
      menuRef.value?.remove();
      map.off('contextmenu', menuContext);
    };
  }, []);

  useLayoutEffect(() => {
    setMarkerInit(null);
    setMarkerEnd(null);
    setMarkersStops(null);
    setMarkersAlarms(null);
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    map.resize();
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  return (
    <>
      <div style={{ width: '100%', height: '100%' }} ref={containerEl}>
        {mapReady && children}
      </div>
      {openPoi && (<MapViewPoisModal latLong={latLong} openPoi={openPoi} setOpenPoi={setOpenPoi} loadingPoi={loadingPoi} setLoadingPoi={setLoadingPoi} />)}
    </>

  );
};

export default MapView;
