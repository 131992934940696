/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import OutboxIcon from '@mui/icons-material/Outbox';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useTranslation,
  useTranslationKeys,
} from '../common/components/LocalizationProvider';
import { devicesActions } from '../store';
import SelectField from '../common/components/SelectField';
import { prefixString, unprefixString } from '../common/util/stringUtils';
import { useCatch } from '../reactHelper';
import EditItemViewModal from './components/EditItemViewModal';
import { useAttributePreference } from '../common/util/preferences';
import {
  distanceFromMeters,
  distanceToMeters,
  distanceUnitString,
  speedFromKnots,
  speedToKnots,
  speedUnitString,
  volumeFromLiters,
  volumeToLiters,
  volumeUnitString,
} from '../common/util/converter';
import useCommonDeviceAttributes from '../common/attributes/useCommonDeviceAttributes';
// import CalendarPage from './CalendarPage';
import CalendarAlert from './CalendarAlert';
import InfinitiScroll from './common/InfinitiScroll';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const NotificationPageModal = ({ handleClose, itemEdit }) => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleLoading = (status) => setLoading(status);
  const [item, setItem] = useState(itemEdit ? { ...itemEdit, deviceAttribute: itemEdit.configuration?.deviceAttribute || {} } : {});
  const [valid, setValid] = useState();
  const [id, setId] = useState(itemEdit ? itemEdit?.id ?? null : null);
  const devices = useSelector((state) => state.devices.items);
  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const speedUnit = useAttributePreference('speedUnit');
  const distanceUnit = useAttributePreference('distanceUnit');
  const volumeUnit = useAttributePreference('volumeUnit');

  const [always, setAlways] = useState(itemEdit?.always && itemEdit?.configuration?.devicesIds?.length === Object.values(devices).length);
  const [selectedItems, setSelectedItems] = useState([]);

  const steps = [`${t('options1')}`, `${t('options2')}`, `${t('options3')}`];

  // useEffect(() => {
  //   if (itemEdit) {
  //     setItem({ ...itemEdit, deviceAttribute: itemEdit.configuration?.deviceAttribute || {} });
  //     setId(itemEdit?.id ?? null);
  //     if (
  //       itemEdit?.always &&
  //       itemEdit?.configuration?.devicesIds?.length === Object.values(devices).length
  //     ) setAlways(true);
  //   }
  //   return () => {
  //     setItem({});
  //     setId(null);
  //   };
  // }, []);

  // useEffect(() => {
  //   dispatch(devicesActions.showNotificationModal(false));
  // }, []);

  const alarms = useTranslationKeys((it) => it.startsWith('alarm')).map(
    (it) => ({
      key: unprefixString('alarm', it),
      name: t(it),
    }),
  );

  const testNotificators = useCatch(async () => {
    await Promise.all(
      item.notificators.split(/[, ]+/).map(async (notificator) => {
        const response = await fetch(`/api/notifications/test/${notificator}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item),
        });
        if (!response.ok) {
          throw Error(await response.text());
        }
      }),
    );
  });

  const validate = () => item && item.type;

  const compareIdsArray = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    if (set1.size !== set2.size) {
      return false;
    }
    for (const num of set1) {
      if (!set2.has(num)) {
        return false;
      }
    }
    return true;
  };

  const handleSave = useCatch(async () => {
    // ? For multiple endpoint
    const configuration = {
      type: item.type,
      always: always || false,
      deviceAttribute: item.deviceAttribute || {},
      geofences: item?.geofences || item?.configuration?.geofences || [],
      devicesIds: [],
      geofencesRemove: [],
      devicesIdsRemove: [],
    };
    // ? =================================================================
    let payload = {
      type: item.type,
      always,
      calendarId: item.calendarId,
      commandId: item.commandId,
      attributes: item.attributes,
      notificators: item.notificators,
      description: item.description,
      configuration,
    };
    if (item.email) payload.email = item.email;

    let url = '/api/notifications';
    if (id) {
      url += `/${id}`;
      payload = { ...payload, id };
    }
    handleLoading(true);

    const response = await fetch(url, {
      // eslint-disable-next-line no-constant-condition
      method: id ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const resp = await response.json();
      configuration.notificationId = resp.id;
      const devIds = selectedItems.map((it) => it.id);

      if (itemEdit?.configuration?.geofences &&
        compareIdsArray(itemEdit?.configuration?.geofences, configuration?.geofences) &&
        compareIdsArray(Object.values(itemEdit.configuration?.deviceAttribute), Object.values(configuration?.deviceAttribute))) {
        configuration.devicesIds = itemEdit?.configuration?.devicesIds ?
          devIds.filter((n) => !itemEdit?.configuration?.devicesIds?.includes(n)) : devIds;
        configuration.devicesIdsRemove = itemEdit?.configuration?.devicesIds?.filter((n) => !devIds.includes(n)) || item?.configuration?.devicesIds || [];
      } else {
        configuration.devicesIds = devIds;
        configuration.devicesIdsRemove = item?.configuration?.devicesIds || [];
        configuration.geofencesRemove = item?.configuration?.geofences || [];
      }

      await fetch('/api/notifications/multiples', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(configuration),
      });
      handleLoading(false);
      handleClose();
    } else {
      handleLoading(false);
      throw Error(await response.text());
    }
  });

  const handleNext = () => {
    const newArray = [];
    if (id && activeStep === 0) {
      item.configuration?.devicesIds?.forEach((element) => {
        Object.values(devices).forEach((dev) => {
          if (dev.id === element) {
            newArray.push({ id: dev.id, name: dev.name });
          }
        });
      });
      // item.geofences = item?.configuration?.geofences;
      setSelectedItems(newArray);
    }
    if (activeStep === steps.length - 1) handleSave();
    else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateNumber = (value) => /^[1-9]\d{0,2}$/.test(value);
  const validateEmail = (value) => /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(value);

  const updateAttribute = (key, value, type, subtype) => {
    const deviceAttribute = { ...item.deviceAttribute };
    switch (subtype) {
      case 'speed':
        if (validateNumber(value)) {
          deviceAttribute[key] = speedToKnots(Number(value), speedUnit);
        }
        break;
      case 'distance':
        deviceAttribute[key] = distanceToMeters(Number(value), distanceUnit);
        break;
      case 'remolqueDistance':
        deviceAttribute[key] = Number(value);
        break;
      case 'volume':
        deviceAttribute[key] = volumeToLiters(Number(value), volumeUnit);
        break;
      default:
        deviceAttribute[key] = type === 'number' ? Number(value) : value;
        break;
    }
    setItem({ ...item, deviceAttribute });
  };

  const deleteAttribute = (key) => {
    const deviceAttribute = { ...item.deviceAttribute };
    delete deviceAttribute[key];
    setItem({ ...item, deviceAttribute });
  };

  const getAttributeName = (key, subtype) => {
    const definition = { ...commonDeviceAttributes }[key];
    const name = definition ? definition.name : key;
    switch (subtype) {
      case 'speed':
        return `${name} (${speedUnitString(speedUnit, t)})`;
      case 'distance':
        return `${name} (${distanceUnitString(distanceUnit, t)})`;
      case 'remolqueDistance':
        return `${name} (${distanceUnitString('m', t)})`;
      case 'volume':
        return `${name} (${volumeUnitString(volumeUnit, t)})`;
      default:
        return name;
    }
  };

  const getDisplayValue = (value, subtype) => {
    if (value) {
      switch (subtype) {
        case 'speed':
          return speedFromKnots(value, speedUnit);
        case 'distance':
          return distanceFromMeters(value, distanceUnit);
        case 'remolqueDistance':
          return value;
        case 'volume':
          return volumeFromLiters(value, volumeUnit);
        default:
          return value;
      }
    }
    return '';
  };

  const handleChangeType = (e) => {
    const items = { ...item, type: e.target.value };
    if (items.type !== 'geofenceExit' || items.type !== 'geofenceEnter') {
      delete items.geofences;
    }
    if (items.type !== 'deviceOverspeed') {
      delete items.deviceAttribute?.speed;
    }
    if (items.type !== 'alarm') {
      delete items.attributes?.alarms;
    }
    return setItem({ ...items });
  };

  const handleChangeDescrip = (e) => {
    const items = { ...item, description: e.target.value };
    return setItem({ ...items });
  };

  const handleChangeEmail = (e) => {
    const email = e.target.value;
    const data = { ...item };
    if (email && email.length) {
      const value = email.split(';');
      const emailArray = value.map((e) => e.trim());
      const validEmails = emailArray.filter((e) => validateEmail(e));
      const isValid = validEmails.length === emailArray.length;
      data.email = emailArray.join(';');
      setValid(isValid);
    } else {
      delete data.email;
      setValid(true);
    }
    return setItem({ ...data });
  };

  const remolque = (e) => {
    const data = e;
    const rem = data.split(',');
    return rem.filter((el) => el === 'tow').length;
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {loading && (
        <Grid item xs={12}>
          <Box sx={{ width: '100%', paddingY: 0 }}>
            <LinearProgress />
          </Box>
        </Grid>
      )}
      <Stepper sx={{ padding: '0 32px 5px 20px' }} activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 && (
        <Box
          sx={{ width: '100%', minHeight: 350, maxHeight: 500, paddingY: 0 }}
        >
          <EditItemViewModal
            endpoint="notifications"
            item={item}
            setItem={setItem}
            validate={validate}
            handleClose={handleClose}
            hideButtons
          >
            {item && (
              <Grid
                container
                spacing={2}
                alignItems="normal"
                style={{ width: '100%' }}
              >
                <Grid item xs={desktop ? 6 : 12}>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle1">
                        {t('alertType')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <SelectField
                        value={item.type}
                        emptyValue={null}
                        onChange={(e) => handleChangeType(e)}
                        endpoint="/api/notifications/types"
                        keyGetter={(it) => it.type}
                        titleGetter={(it) => t(prefixString('event', it.type))}
                        label={t('sharedType')}
                        disabled={!!itemEdit}
                      />
                      {item.type === 'alarm' && (
                        <SelectField
                          multiple
                          value={
                            item.attributes && item.attributes.alarms
                              ? item.attributes.alarms.split(/[, ]+/)
                              : []
                          }
                          onChange={(e) => setItem({
                            ...item,
                            attributes: {
                              ...item.attributes,
                              alarms: e.target.value.join(),
                            },
                          })}
                          data={alarms}
                          keyGetter={(it) => it.key}
                          label={t('sharedAlarms')}
                        />
                      )}
                      <FormControl key="descrip">
                        <InputLabel>
                          {`${t('sharedDescription')} (${t(
                            'options4',
                          )})`}
                        </InputLabel>
                        <OutlinedInput
                          label={`${t('sharedDescription')} (${t('options4')})`}
                          value={item.description}
                          onChange={handleChangeDescrip}
                          multiline
                          rows={4}
                          variant="outlined"
                          endAdornment={<InputAdornment position="end" />}
                        />
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={desktop ? 6 : 12}>
                  <Accordion defaultExpanded={desktop}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle1">
                        {t('sharedExtraFull')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      {(item.type === 'geofenceEnter' ||
                        item.type === 'geofenceExit') && (
                        <SelectField
                          value={
                            item.geofences ||
                            item.configuration?.geofences ||
                            []
                          }
                          onChange={(event) => setItem({ ...item, geofences: event.target.value })}
                          endpoint="/api/geofences"
                          label={t('sharedGeofences')}
                          multiple
                        />
                      )}
                      {(item.type === 'deviceOverspeed' ||
                        item.type === 'deviceOverspeed2' ||
                        item.type === 'deviceOverspeed3') && (
                        <FormControl key="speed">
                          <InputLabel>
                            {getAttributeName('speedLimit', 'speed')}
                          </InputLabel>
                          <OutlinedInput
                            label={getAttributeName('speedLimit', 'speed')}
                            type="number"
                            value={getDisplayValue(
                              item.deviceAttribute?.speed ||
                                item.configuration?.deviceAttribute?.speed,
                              'speed',
                            )}
                            onChange={(e) => updateAttribute(
                              'speed',
                              e.target.value,
                              'number',
                              'speed',
                            )}
                            endAdornment={(
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  edge="end"
                                  onClick={() => deleteAttribute('speed')}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            )}
                            pattern="[1-9]\d{0,2}$"
                          />
                        </FormControl>
                      )}
                      {item.attributes?.alarms ? remolque(item.attributes?.alarms) === 1 && (
                      <FormControl key="distance2">
                        <InputLabel>{getAttributeName('distanceGrua', 'remolqueDistance')}</InputLabel>
                        <OutlinedInput
                          label={getAttributeName('distanceGrua', 'remolqueDistance')}
                          type="number"
                          value={getDisplayValue(item.deviceAttribute?.remolqueDistance || item.configuration?.deviceAttribute?.remolqueDistance, 'remolqueDistance')}
                          onChange={(e) => updateAttribute('remolqueDistance', e.target.value, 'number', 'remolqueDistance')}
                          endAdornment={(
                            <InputAdornment position="end">
                              <IconButton size="small" edge="end" onClick={() => deleteAttribute('remolqueDistance')}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </InputAdornment>
                            )}
                          pattern="[1-9]\d{0,2}$"
                        />
                      </FormControl>
                      ) : ''}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            )}
          </EditItemViewModal>
        </Box>
      )}
      {activeStep === 1 && (
        <Grid
          container
          spacing={2}
          alignItems="normal"
          style={{ width: '100%', paddingTop: 5 }}
        >
          <Grid item xs={desktop ? 6 : 12}>
            <InfinitiScroll selectedItems={selectedItems} setSelectedItems={setSelectedItems} always={always} setAlways={setAlways} enableAlways />
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Accordion defaultExpanded={desktop}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedCalendar')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SelectField
                  value={item.calendarId || 0}
                  onChange={(event) => setItem({ ...item, calendarId: Number(event.target.value) })}
                  endpoint="/api/calendars"
                  label={t('sharedCalendar')}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('createNewCalendar')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <CalendarAlert />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}

      {activeStep === 2 && (
        <Box
          sx={{ width: '100%', minHeight: 350, maxHeight: 500, paddingY: 0 }}
        >
          <Grid
            container
            spacing={2}
            alignItems="normal"
            style={{ width: '100%', paddingTop: 5 }}
          >
            <EditItemViewModal
              endpoint="notifications"
              item={item}
              setItem={setItem}
              validate={validate}
              handleClose={handleClose}
              hideButtons
            >
              {item && (
                <Grid
                  container
                  spacing={2}
                  alignItems="normal"
                  style={{ width: '100%' }}
                >
                  <Grid item xs={desktop ? 6 : 12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">
                          {t('sharedNotification')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.details}>
                        <SelectField
                          multiple
                          value={
                            item.notificators
                              ? item.notificators.split(/[, ]+/)
                              : []
                          }
                          onChange={(e) => setItem({
                            ...item,
                            notificators: e.target.value.join(),
                          })}
                          endpoint="/api/notifications/notificators"
                          keyGetter={(it) => it.type}
                          titleGetter={(it) => t(prefixString('notificator', it.type))}
                          label={t('notificationNotificators')}
                        />
                        {item.notificators?.includes('command') && (
                          <SelectField
                            value={item.commandId || 0}
                            onChange={(event) => setItem({
                              ...item,
                              commandId: Number(event.target.value),
                            })}
                            endpoint="/api/commands"
                            titleGetter={(it) => it.description}
                            label={t('sharedSavedCommand')}
                          />
                        )}
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={testNotificators}
                        >
                          {t('sharedTestNotificators')}
                        </Button>
                        <FormControl key="email">
                          {/* <InputLabel>{t('reportEmail')}</InputLabel> */}
                          <TextField
                            error={!valid && valid !== undefined}
                            label={t('reportEmail')}
                            value={item.email}
                            onChange={handleChangeEmail}
                            helperText={
                              valid && valid !== undefined
                                ? t('validEmail')
                                : `${t('validEmail')} ${t('revisarEmail')} `
                            }
                          />
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={desktop ? 6 : 12}>
                    <Card sx={{ paddingLeft: 1 }}>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14, mt: 1 }}
                          color="text.secondary"
                        >
                          {t('sharedType')}
                          :
                        </Typography>
                        <Typography component="div" sx={{ pl: 2 }}>
                          {'- '}
                          {t(prefixString('event', item.type))}
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14, mt: 2 }}
                          color="text.secondary"
                        >
                          {t('notificationNotificators')}
                          :
                        </Typography>
                        <Typography component="div" sx={{ pl: 2 }}>
                          {(item?.notificators?.split(/[, ]+/) || []).map(
                            (itm) => (
                              <>
                                {'- '}
                                {t(prefixString('notificator', itm))}
                                <br />
                              </>
                            ),
                          )}
                        </Typography>

                        {item.deviceAttribute?.speed && (
                          <>
                            <Typography
                              sx={{ fontSize: 14, mt: 2 }}
                              color="text.secondary"
                            >
                              {getAttributeName('speedLimit', 'speed')}
                              :
                            </Typography>
                            <Typography component="div" sx={{ pl: 2 }}>
                              {getDisplayValue(
                                item.deviceAttribute?.speed || 0,
                                'speed',
                              )}
                            </Typography>
                          </>
                        )}

                        {item.geofences && (
                          <Typography
                            sx={{ fontSize: 14, mt: 2 }}
                            color="text.secondary"
                          >
                            {t('sharedGeofences')}
                            :
                            {` (${item.geofences.length})`}
                          </Typography>
                        )}

                        <Typography
                          sx={{ fontSize: 14, mt: 2 }}
                          color="text.secondary"
                        >
                          {`${t('commandSend')} ${t('sharedSavedCommand')}:
                  (${
                    item?.notificators?.indexOf('command') !== -1
                      ? t('sharedYes')
                      : t('sharedNo')
                  }) `}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </EditItemViewModal>
          </Grid>
        </Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, bottom: 0 }}>
        <Button
          disabled={activeStep === 0 || loading}
          onClick={handleBack}
          sx={{ mr: 1 }}
          variant="outlined"
        >
          <WestIcon sx={{ fontSize: 28 }} />
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          onClick={handleNext}
          disabled={
            loading || !validate() || (valid === false && activeStep === steps.length - 1)
          }
          variant="outlined"
        >
          {activeStep === steps.length - 1 ? (
            <>
              {id ? 'GUARDAR' : 'CREAR'}
              <OutboxIcon sx={{ marginLeft: 1, fontSize: 28 }} />
            </>
          ) : (
            <EastIcon sx={{ fontSize: 28 }} />
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default NotificationPageModal;
