/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Divider, Typography, IconButton, useMediaQuery, Toolbar, Grid, LinearProgress, Box, Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import MapView from '../map/core/MapView';
import MapCurrentLocation from '../map/MapCurrentLocation';
import MapGeofenceEdit from '../map/draw/MapGeofenceEdit';
import GeofencesList from './GeofencesList';
import { useTranslation } from '../common/components/LocalizationProvider';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import { errorsActions, geofencesActions } from '../store';
import { useCatchCallback } from '../reactHelper';
import { speedToKnots } from '../common/util/converter';
import MapViewGeofenceModal from '../map/core/MapViewGeofenceModal';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  drawer: {
    zIndex: 1,
  },
  drawerPaper: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.dimensions.drawerHeightPhone,
    },
  },
  mapContainer: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  fileInput: {
    display: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 600,
    maxHeight: '90vh',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

  },
}));

const columns = [
  { field: 'geo_name', headerName: 'Nombre', width: 200 },
  { field: 'geo_type', headerName: 'Tipo', width: 110 },
  { field: 'geo_color', headerName: 'Color', width: 110 },
  {
    field: 'geo_speed',
    headerName: 'Velocidad',
    type: 'number',
    width: 90,
  },
];

let rows = [];

const GeofencesPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const [openGeofence, setOpenGeofence] = useState(false);
  const [loadingGeofence, setLoadingGeofence] = useState(false);
  const [geofence, setGeofence] = useState(null);

  const handleClickOpenPoi = () => {
    setLoadingGeofence(false);
    setOpenGeofence(true);
  };

  const handleGeofence = (item) => {
    setGeofence(item);
    handleClickOpenPoi();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setExcelData([]);
    setOpen(false);
  };

  const exportTemplate = () => {
    /* generate worksheet */
    const ws = XLSX.utils.aoa_to_sheet([[
      'Nombre', 'group_name(NO)', 'lat&lng', 'Radio',
      'Color', 'Descripcion', 'description2(NO)',
      'Velocidad_Activa', 'Velocidad', 'Tipo',
      'alarm_trigger(NO)', 'lock_type(NO)',
    ]]);
    /* generate workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, `template-${Date.now()}.xlsx`);
  };

  const handleDropExcel = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const wb = XLSX.read(arrayBuffer, { type: 'array' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (data && data.length > 1) {
        data.shift();
        setExcelData(data);
        rows = data.map((row, id) => ({
          id,
          geo_name: row[0],
          geo_type: row[9],
          geo_color: row[4],
          geo_speed: row[8],
        }));
      }
    };
    // eslint-disable-next-line no-unused-vars
    reader.onerror = (e) => {
      dispatch(errorsActions.push('Error!'));
    };
    if (e.dataTransfer?.files[0]) {
      reader.readAsArrayBuffer(e.dataTransfer.files[0]);
    } else if (e.target?.files) {
      const files = Array.from(e.target.files);
      const [file] = files;
      reader.readAsArrayBuffer(file);
    }
  };

  const formatCoordinates = (coordinates) => {
    // Check if the input is a string
    if (typeof coordinates !== 'string') {
      throw new Error('Input must be a string.');
    }
    // Split the string into an array of coordinates
    const coordinatesArray = coordinates.split(',');
    // Check if the array has an even number of elements
    if (coordinatesArray.length % 2 !== 0) {
      throw new Error('Input string must contain an even number of coordinates.');
    }
    // Create an empty string to store the formatted coordinates
    let formattedCoordinates = '';
    // Iterate over the array of coordinates
    for (let i = 0; i < coordinatesArray.length; i += 2) {
      // Add the current coordinate to the formatted string
      formattedCoordinates += `${coordinatesArray[i]} ${coordinatesArray[i + 1]}, `;
    }
    // Remove the last comma from the formatted string
    formattedCoordinates = formattedCoordinates.slice(0, -2);
    // Return the formatted coordinates
    return formattedCoordinates;
  };

  const handleSaveExcel = async () => {
    try {
      const result = excelData.filter((row) => (row[9] === 'circle' || row[9] === 'polygon' || row[9] === 'route')).map((row, index) => {
        const newGeofence = {
          calendarId: 0,
          name: row[0] || `Default-${index}`,
          description: row[5] || '',
          area: row[9] === 'polygon' ?
            `POLYGON ((${formatCoordinates(row[2])}))` :
            row[9] === 'circle' ?
              `CIRCLE (${formatCoordinates(row[2])}, ${row[3]})` :
              `LINESTRING (${formatCoordinates(row[2])})`,
          attributes: {},
        };
        if (row[7] === 1 && row[8] > 0) newGeofence.attributes.speedLimit = speedToKnots(Number(row[8]), 'kmh');
        if (row[4]) newGeofence.attributes.color = row[4];
        return newGeofence;
      });

      setLoading(true);
      await result.map(async (element) => {
        const response = await fetch('/api/geofences', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(element),
        });
        if (!response.ok) {
          throw Error(await response.text());
        }
      });
      handleClose();
      const setTimeoutId = setTimeout(async () => {
        const response = await fetch('/api/geofences');
        if (response.ok) {
          dispatch(geofencesActions.refresh(await response.json()));
          setLoading(false);
        } else {
          throw Error(await response.text());
        }

        clearTimeout(setTimeoutId);
      }, 1000);
    } catch (error) {
      setLoading(false);
      dispatch(errorsActions.push(error.message));
      const response = await fetch('/api/geofences');
      dispatch(geofencesActions.refresh(await response.json()));
    }
  };

  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedGeofenceId, setSelectedGeofenceId] = useState();

  // const handleFile = (event) => {
  //   const files = Array.from(event.target.files);
  //   const [file] = files;
  //   const reader = new FileReader();
  //   reader.onload = async () => {
  //     const xml = new DOMParser().parseFromString(reader.result, 'text/xml');
  //     const segment = xml.getElementsByTagName('trkseg')[0];
  //     const coordinates = Array.from(segment.getElementsByTagName('trkpt'))
  //       .map((point) => `${point.getAttribute('lat')} ${point.getAttribute('lon')}`)
  //       .join(', ');
  //     const area = `LINESTRING (${coordinates})`;
  //     const newItem = { name: '', area };
  //     try {
  //       const response = await fetch('/api/geofences', {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(newItem),
  //       });
  //       if (response.ok) {
  //         const item = await response.json();
  //         navigate(`/settings/geofence/${item.id}`);
  //       } else {
  //         throw Error(await response.text());
  //       }
  //     } catch (error) {
  //       dispatch(errorsActions.push(error.message));
  //     }
  //   };
  //   reader.onerror = (event) => {
  //     dispatch(errorsActions.push(event.target.error));
  //   };
  //   reader.readAsText(file);
  // };

  const handleFileJSON = useCatchCallback(async (event) => {
    const files = Array.from(event.target.files);
    const [file] = files;
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = JSON.parse(event.target.result);
      try {
        const result = data.features.map((feature, index) => ({
          calendarId: 0,
          name: feature.properties?.NOM || `Geofence-${index}`,
          description: feature.properties?.ACTIVITAT || '',
          area: feature.geometry.type === 'Polygon' ?
            `POLYGON ((${feature.geometry.coordinates[0].map((coord) => coord.reverse().join(' ')).join(', ')}))` :
            `LINESTRING (${feature.geometry.coordinates[0].map((coord) => coord.reverse().join(' ')).join(', ')})`,
        }));

        setLoading(true);
        await result.map(async (element) => {
          const response = await fetch('/api/geofences', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(element),
          });
          if (!response.ok) {
            throw Error(await response.text());
          } else {
            const response = await fetch('/api/geofences');
            if (response.ok) {
              dispatch(geofencesActions.refresh(await response.json()));
              setLoading(false);
            } else {
              throw Error(await response.text());
            }
          }
        });
      } catch (error) {
        setLoading(false);
        dispatch(errorsActions.push(error.message));
        const response = await fetch('/api/geofences');
        dispatch(geofencesActions.refresh(await response.json()));
      }
    };
    reader.onerror = (event) => {
      dispatch(errorsActions.push(event.target.error));
    };
    reader.readAsText(file);
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Drawer
          className={classes.drawer}
          anchor={isPhone ? 'bottom' : 'left'}
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
        >
          {loading && (
          <Grid item xs={12}>
            <Box sx={{ width: '100%', paddingY: 0 }}>
              <LinearProgress sx={{ color: 'primary' }} />
            </Box>
          </Grid>
          )}
          <Toolbar style={{ backgroundColor: theme.palette.colors.primary, color: theme.palette.primary.contrastText }}>
            <IconButton edge="start" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <ArrowBackIcon style={{ color: theme.palette.primary.contrastText }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>{t('sharedGeofences')}</Typography>
            <Tooltip title="EXCEL" arrow>
              <IconButton edge="end" component="span" onClick={handleOpen}>
                <ListAltIcon style={{ color: theme.palette.primary.contrastText }} />
              </IconButton>
            </Tooltip>
            {/* <label htmlFor="upload-gpx">
              <input accept=".gpx" id="upload-gpx" type="file" className={classes.fileInput} onChange={handleFile} />
              <Tooltip title="GPX" arrow>
                <IconButton edge="end" component="span" onClick={() => {}}>
                  <UploadFileIcon style={{ color: theme.palette.primary.contrastText }} />
                </IconButton>
              </Tooltip>
            </label> */}
            <label htmlFor="upload-json">
              <input accept=".json" id="upload-json" type="file" className={classes.fileInput} onChange={handleFileJSON} />
              <Tooltip title="GEOJSON" arrow>
                <IconButton edge="end" component="span" onClick={() => {}}>
                  <UploadFileIcon style={{ color: theme.palette.primary.contrastText }} />
                </IconButton>
              </Tooltip>
            </label>
          </Toolbar>
          <Divider />
          <GeofencesList onGeofenceSelected={setSelectedGeofenceId} />
        </Drawer>
        <div className={classes.mapContainer}>
          <MapView>
            <MapGeofenceEdit selectedGeofenceId={selectedGeofenceId} handleGeofence={handleGeofence} />
          </MapView>
          <MapCurrentLocation />
          <MapGeocoder />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <div className={classes.paper}>

          {!excelData.length && (
            <>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
              >
                <h2 id="simple-modal-title">Importar Excel</h2>
                <IconButton aria-label="fingerprint" color="error" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <p id="simple-modal-description">
                Coloque un archivo de Excel/CSV en el área siguiente para mostrar los datos en una tabla.
              </p>
              <div
                onDrop={handleDropExcel}
                onDragOver={(e) => e.preventDefault()}
                style={{
                  width: '100%',
                  height: '200px',
                  border: '2px dashed #000',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="excel-input">
                  <input accept=".xlsx,.csv" id="excel-input" type="file" className={classes.fileInput} onChange={handleDropExcel} />
                  <Button edge="end" component="span" startIcon={<CloudUploadIcon />}>
                    Cargar Archivo
                  </Button>
                </label>
                <br />
                <Button onClick={exportTemplate} startIcon={<CloudDownloadIcon />}>Plantilla</Button>
              </div>
            </>
          )}
          {excelData.length > 0 && (
            <div style={{ height: '100%', width: '100%' }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 10,
              }}
              >
                <Button variant="contained" onClick={handleClose}>Cancelar</Button>
                <Button variant="contained" color="success" onClick={handleSaveExcel}>Importar</Button>
              </div>

              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </div>
          )}
        </div>
      </Modal>
      {openGeofence && (<MapViewGeofenceModal geofence={geofence} openGeofence={openGeofence} setOpenGeofence={setOpenGeofence} loadingGeofence={loadingGeofence} setLoadingGeofence={setLoadingGeofence} />)}
    </div>
  );
};

export default GeofencesPage;
