import * as React from 'react';
import {
  Box, Grid, Button,
  useMediaQuery, LinearProgress,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const EngineCutModal = ({ handleClose, position, device, classes, theme, t, isBlocked }) => {
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const addressEnabled = useSelector((state) => state.session.server.geocoderEnabled);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [finished, setFinished] = useState(false);
  const [ready, setReady] = useState(false);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  useEffect(() => {
    if (position?.attributes?.sat >= 3 && !!(position?.valid) && !(position?.attributes?.ignition) && device?.status === 'online') {
      setReady(true);
    } else { setReady(false); }
  }, [position, device]);

  const handleSendCommand = async (activate, finish) => {
    setLoading(true);
    const command = {
      type: 'custom',
      attributes: {
        data: activate ? 'relay,1#' : 'relay,0#',
      },
    };
    try {
      const devicesId = [device?.id];
      const response = await fetch('/api/commands/sends', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ devicesId, command }),
      });
      if (response.ok) {
        if (!finish) {
          setHasError(false);
        }
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      if (!finish) {
        setHasError(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading && !finished && position?.attributes?.hasOwnProperty('result')) {
      if (
        !position?.attributes?.result?.toLowerCase().includes('google')
      ) {
        setFinished(true);
        if (position?.attributes?.result?.toLowerCase().includes('ok') ||
          position?.attributes?.result?.toLowerCase().includes('success')) {
          setHasError(false);
          const handleSendGoogle = async () => {
            try {
              const command = {
                type: 'custom',
                attributes: {
                  data: 'google#',
                },
              };
              const devicesId = [device?.id];
              const response = await fetch('/api/commands/sends', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ devicesId, command }),
              });
              if (!response.ok) {
                throw Error(await response.text());
              }
            } catch (error) {
              setHasError(true);
            }
          };
          handleSendGoogle();
        } else {
          setHasError(true);
          handleSendCommand(false, true);
        }
        setLoading(false);
      }
    }
    const showAddress = async () => {
      if (addressEnabled && !finished && position?.latitude && position?.longitude && (position?.latitude !== latitude || position?.longitude !== longitude)) {
        setLatitude(position?.latitude);
        setLongitude(position?.longitude);
        setAddress('');
        const query = new URLSearchParams({ latitude: position?.latitude, longitude: position?.longitude });
        const response = await fetch(`/api/server/geocode?${query.toString()}`);
        if (response.ok) {
          setAddress(await response.text());
        } else {
          setAddress('');
          // throw Error(await response.text());
        }
      }
      if (!addressEnabled || !position?.latitude || !position?.longitude) {
        setLatitude('');
        setLongitude('');
        setAddress('');
      }
    };
    showAddress();
  }, [position]);

  return (
    <Box sx={{ width: '100%', minHeight: 160, maxHeight: 500, paddingY: 0 }}>
      <Box sx={{ width: '100%', paddingY: 0, height: 2 }}>
        {loading && (
        <LinearProgress />
        )}
      </Box>
      {((!finished || loading) && !isBlocked) ? (
        <>
          <Grid
            container
            spacing={2}
            alignItems="normal"
            style={{ width: '100%', paddingTop: 10 }}
          >
            <Grid item xs={desktop ? 6 : 12}>
              <b>Satélites: </b>
              {' '}
              <span className={position && !Number.isNaN(position?.attributes?.sat) ?
                (position?.attributes?.sat >= 3 ? classes.positive : classes.medium) :
                null}
              >
                {position?.attributes?.sat ?? ''}
              </span>
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: desktop ? 'flex-end' : 'flex-start' }}>
                <span style={{ paddingRight: 5 }}><b>Posición Válida: </b></span>
                {position?.valid ? (<CheckCircleOutlineIcon className={classes.positive} />) : (<HighlightOffIcon className={classes.medium} />)}
              </div>
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <b>Estado: </b>
              {' '}
              <span className={position && position?.attributes?.hasOwnProperty('blocked') ?
                (position?.attributes?.ignition ? classes.medium : classes.positive) :
                classes.neutral}
              >
                {position && position?.attributes?.hasOwnProperty('blocked') ?
                  (position?.attributes?.ignition ? 'Encendido' : 'Apagado') :
                  'Desconocido'}
              </span>
            </Grid>
            <Grid item xs={desktop ? 6 : 12}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: desktop ? 'flex-end' : 'flex-start' }}>
                <span style={{ paddingRight: 5 }}><b>Conectado: </b></span>
                {device?.status === 'online' ? (<CheckCircleOutlineIcon className={classes.positive} />) : (<HighlightOffIcon className={classes.medium} />)}
              </div>
            </Grid>
            <Grid item xs={desktop ? 3 : 12}>
              <div>
                <b>Dirección: </b>
              </div>
            </Grid>
            <Grid item xs={desktop ? 9 : 12}>
              <div style={{ minHeight: 82 }}>
                {address}
              </div>
            </Grid>
            <Grid item xs={1}>
              <div style={{ paddingTop: 4 }}>
                {ready ? (<CheckCircleOutlineIcon className={classes.positive} />) : (<HighlightOffIcon className={classes.negative} />)}
              </div>
            </Grid>
            <Grid item xs={11}>
              <p style={{ textAlign: 'justify', padding: '0 4px', margin: 0 }}>
                {ready ?
                  ('El vehículo se encuentra en un estado óptimo para realizar el corte de motor. Pulse en "Cortar Motor"') :
                  ('El vehículo no cumple las condiciones para realizar el corte de forma segura. Si aún quiere realizar el corte, contactenos al 952 66 95 75.')}
              </p>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={handleClose}
              disabled={loading}
            >
              {t('sharedCancel')}
            </Button>
            <Button
              type="button"
              color="error"
              variant="contained"
              onClick={() => handleSendCommand(true)}
              disabled={!ready || loading}
            >
              {t('cardEngineCutAction') || 'Cut Engine'}
            </Button>
          </div>
        </>
      ) : (
        <div>
          <Grid
            container
            spacing={2}
            alignItems="normal"
            style={{ width: '100%', paddingTop: 20 }}
          >
            <Grid item xs={1}>
              <div style={{ paddingTop: 2 }}>
                {!hasError ? (<CheckCircleOutlineIcon className={classes.positive} />) : (<HighlightOffIcon className={classes.negative} />)}
              </div>
            </Grid>
            <Grid item xs={11}>
              <div style={{ minHeight: 120 }}>
                <p style={{ textAlign: 'justify', padding: '0 4px', margin: 0 }}>
                  {!hasError ?
                    (`${!isBlocked ? 'El corte de motor se ha realizado con éxito.' : 'Se ha enviado con éxito el comando para anular el corte de motor.'}`) :
                    ('Ha ocurrido un error al realizar el corte de motor. Si aún quiere realizar el corte, contactenos al 952 66 95 75.')}
                </p>
              </div>

            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              type="button"
              color="primary"
              variant="contained"
              size="large"
              onClick={handleClose}
            >
              Salir
            </Button>
          </div>
        </div>

      )}

    </Box>
  );
};

export default EngineCutModal;
