/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {
  useState, useEffect,
  Fragment,
} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Button, ListItemButton,
  Collapse, ListItem, TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useSelector } from 'react-redux';
import { List } from 'react-virtualized';
import { useTranslation } from '../common/components/LocalizationProvider';

const calcListHeight = (totalItems) => {
  const calc = (26 * totalItems + 2);
  if (calc > 360) return 362;
  return calc;
};

const CommandSelectDevicesModal = ({ handleClose, selectedDevices, setSelectedDevices }) => {
  const t = useTranslation();
  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);

  const [itemsGroup, setItemsGroup] = useState([]);
  const [devicesWithGroup, setDevicesWithGroup] = useState([]);
  const [devicesWithoutGroup, setDevicesWithoutGroup] = useState([]);
  const [filter, setFilter] = useState('');

  const [expanded, setExpanded] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems(selectedDevices);
  }, []);

  useEffect(() => {
    if (!filter) {
      setDevicesWithGroup(Object.values(devices).filter((item) => Object.keys(groups).includes(`${item.groupId}`)));
      setDevicesWithoutGroup(Object.values(devices).filter((item) => !Object.keys(groups).includes(`${item.groupId}`)));
    } else {
      setDevicesWithGroup(Object.values(devices).filter((item) => (Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)));
      setDevicesWithoutGroup(Object.values(devices).filter((item) => (!Object.keys(groups).includes(`${item.groupId}`) && item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)));
    }
  }, [filter]);

  useEffect(() => {
    const itemsArr = [];
    for (const groupId in groups) {
      const groupName = groups[groupId].name;
      const groupID = groups[groupId].id;
      const groupItems = [];

      for (const device of devicesWithGroup) {
        if (device.groupId === groups[groupId].id) {
          groupItems.push({ id: device.id, name: device.name, selected: false });
        }
      }
      itemsArr.push({
        groupName,
        items: groupItems,
        id: groupID,
      });
    }
    const groupItems = [];
    for (const device of devicesWithoutGroup) {
      groupItems.push({ id: device.id, name: device.name, selected: false });
    }
    itemsArr.push({
      groupName: 'Sin Grupo',
      items: groupItems,
      id: 0,
    });
    setItemsGroup(itemsArr);
  }, [devicesWithGroup, devicesWithoutGroup]);

  const handleToggleGroup = (index) => {
    try {
      if (expanded.includes(index)) {
        setExpanded(expanded.filter((item) => item !== index));
      } else {
        setExpanded([...expanded, index]);
      }
    } catch (error) {
      // console.error('handleToggleGroup: ', error);
    }
  };

  const handleToggleItem = (item) => {
    try {
      if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
        setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
      } else {
        setSelectedItems([...selectedItems, { id: item.id, name: item.name }]);
      }
    } catch (error) {
      // console.error('handleToggleItem: ', error);
    }
  };

  const handleToggleGroupItems = (groupIndex) => {
    try {
      const group = itemsGroup[groupIndex];
      const groupItems = group.items;

      if (selectedItems.some((item) => groupItems.some((groupItem) => groupItem.id === item.id))) {
        setSelectedItems(selectedItems.filter((item) => !groupItems.some((groupItem) => groupItem.id === item.id)));
      } else {
        setSelectedItems([...selectedItems, ...groupItems]);
      }
    } catch (error) {
      // console.error('handleToggleGroupItems: ', error);
    }
  };

  const saveSelectedItems = () => {
    setSelectedDevices(selectedItems);
    handleClose();
  };

  // eslint-disable-next-line no-unused-vars
  const isChecked = (id) => (selectedItems.some((it) => it.id === id));

  const listAll = itemsGroup.map((group, groupIndex) => (
    group.items?.length > 0 && (
    <Fragment key={`${group.id}-fragx`}>
      <Box key={`${group.id}box`} sx={{ display: 'flex', alignItems: 'center', padding: 0, cursor: 'pointer' }}>
        <Box onClick={() => handleToggleGroupItems(groupIndex)} sx={{ paddingTop: 1 }}>
          {(selectedItems.filter((item) => group.items.some((it) => it.id === item.id)).length === 0) ?
            <CheckBoxOutlineBlankIcon /> : (
              selectedItems.filter((item) => group.items.some((it) => it.id === item.id)).length === group.items.length ?
                <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />

            )}
        </Box>
        <ListItem onClick={() => handleToggleGroup(groupIndex)} sx={{ justifyContent: 'space-between' }}>
          {group.groupName}
          {expanded.includes(groupIndex) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      </Box>
      <Collapse in={expanded.includes(groupIndex)} timeout="auto" unmountOnExit>
        <List
          width={400}
          height={calcListHeight(group.items.length)}
          rowHeight={26}
          rowCount={group.items.length}
          rowRenderer={({ key, index, style }) => {
            const device = group.items[index];
            return (

              <ListItemButton
                sx={{ paddingLeft: 3, width: 10, height: 10 }}
                key={key}
                style={style}
                onClick={() => handleToggleItem(device)}
              >
                {selectedItems.some(
                  (it) => it.id === device?.id,
                ) ? (
                  <CheckBoxIcon />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}

                <Typography key={key} className="textDeviceList">
                  {device?.name}
                </Typography>

              </ListItemButton>

            );
          }}
          style={{ scrollbarWidth: 'thin', width: '100%' }}
        />
      </Collapse>
    </Fragment>
    )

  ));

  return (
    <Box sx={{ width: '100%', height: '100%' }}>

      <Box sx={{ padding: '0 5px 4px 2px' }}>
        <TextField
          label={t('sharedSearchDevices')}
          value={filter}
          onChange={(event) => (event.target.value ? setFilter(event.target.value.trimStart()) : setFilter(''))}
          fullWidth
          sx={{ padding: 0 }}
        />

      </Box>
      {listAll}
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        color="primary"
        fullWidth
        onClick={saveSelectedItems}
      >
        Aceptar
      </Button>

    </Box>
  );
};

export default CommandSelectDevicesModal;
