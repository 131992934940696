/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import 'maplibre-gl/dist/maplibre-gl.css';
import React, {
  useRef, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GithubPicker } from 'react-color';
import { geofencesActions } from '../../store';
import SelectDevicesList from '../../reports/components/SelectDevicesList';
import useCommonDeviceAttributes from '../../common/attributes/useCommonDeviceAttributes';
import { useAttributePreference } from '../../common/util/preferences';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { speedFromKnots, speedToKnots, speedUnitString } from '../../common/util/converter';

const MapViewGeofenceModal = ({ geofence, openGeofence, setOpenGeofence, loadingGeofence, setLoadingGeofence }) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const speedUnit = useAttributePreference('speedUnit');
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(geofence?.attributes?.color ?? '#00e');
  const [speedValue, setSpeedValue] = useState(geofence?.attributes?.speedLimit ? speedFromKnots(geofence?.attributes?.speedLimit, speedUnit) : '');
  const colorPickerRef = useRef(null);

  const [item, setItem] = useState({ always: geofence?.configuration?.always ?? false });
  const [itemsGroup, setItemsGroup] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [invalidSpeed, setInvalidSpeed] = useState(false);

  const handleCloseGeofence = () => {
    setLoadingGeofence(false);
    setOpenGeofence(false);
    setInvalidSpeed(false);
    setSpeedValue('');
    setSelectedColor('#00e');
    setShowPicker(false);
  };

  const checkValidSpeed = (value) => {
    if (value === '') {
      return true;
    }
    const numberValue = Number(value);
    if (!Number.isNaN(numberValue) && numberValue > 0 && numberValue < 1000) {
      return true;
    }
    return false;
  };

  const handleSpeedChange = (value) => {
    if (checkValidSpeed(value)) {
      setSpeedValue(Number(value));
      setInvalidSpeed(false);
    } else { setSpeedValue(''); }
  };

  const getAttributeName = (key, subtype) => {
    const definition = { ...commonDeviceAttributes }[key];
    const name = definition ? definition.name : key;
    switch (subtype) {
      case 'speed':
        return `${name} (${speedUnitString(speedUnit, t)})`;
      default:
        return name;
    }
  };

  const handleCreateGeofence = async ({ name, description }) => {
    if (name) {
      // ? For multiple endgeofencent
      const configuration = {
        always: item.always || false,
        devicesIds: [],
        devicesIdsRemove: [],
      };
      // ? =================================================================
      const newItem = {
        name,
        description: description || '',
        area: geofence.area,
        calendarId: geofence.calendarId ?? 0,
        attributes: {
          color: selectedColor ?? '#00e',
        },
        poi: false,
        configuration,
      };
      if (speedValue) {
        newItem.attributes.speedLimit = speedToKnots(Number(speedValue), speedUnit);
      }

      let url = '/api/geofences';
      if (geofence.id) {
        url += `/${geofence.id}`;
        newItem.id = geofence.id;
      }
      setLoadingGeofence(true);
      try {
        const response = await fetch(url, {
          method: !geofence.id ? 'POST' : 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newItem),
        });
        if (response.ok) {
          const newGeofence = await response.json();
          configuration.poiId = newGeofence.id;
          let devIds = [];
          if (item.always === true) {
            for (const key in itemsGroup) {
              if (Object.hasOwn(itemsGroup, key)) {
                const group = itemsGroup[key];
                const groupItems = group.items;
                devIds = devIds.concat(groupItems.map((it) => it.id));
              }
            }
          } else {
            devIds = selectedItems.map((it) => it.id);
          }
          configuration.devicesIds = geofence?.configuration?.devicesIds ? devIds.filter((n) => !geofence?.configuration?.devicesIds?.includes(n)) : devIds;
          configuration.devicesIdsRemove = geofence?.configuration?.devicesIds?.filter((n) => !devIds.includes(n)) || [];
          await fetch('/api/geofences/multiples', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(configuration),
          });

          const resp = await fetch('/api/geofences');
          if (resp.ok) {
            dispatch(geofencesActions.update(await resp.json()));
          } else {
            throw Error(await resp.text());
          }
        } else {
          throw Error(await response.text());
        }
        setLoadingGeofence(false);
        const popups = document.getElementsByClassName('maplibregl-popup');
        const popupsM = document.getElementsByClassName('mapboxgl-popup');
        if (popups.length) {
          popups[0].remove();
        }
        if (popupsM.length) {
          popupsM[0].remove();
        }
      } catch (error) {
        //
      }
      handleCloseGeofence();
    }
  };

  return (
    <Dialog
      open={openGeofence}
      onClose={handleCloseGeofence}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const { name, description, speedLimit } = formJson;
          if (checkValidSpeed(speedLimit)) {
            handleCreateGeofence({ name, description });
          } else { setInvalidSpeed(true); }
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {!geofence.id ? 'Nueva Geocerca' : 'Modificar Geocerca'}
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 440, maxHeight: 900, scrollbarWidth: 'thin' }}>

        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="name"
          label="Nombre"
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          defaultValue={geofence.name ?? ''}
          disabled={loadingGeofence}
        />
        <TextField
          margin="dense"
          id="description"
          name="description"
          label="Descripción"
          type="text"
          fullWidth
          variant="outlined"
          size="small"
          defaultValue={geofence.description ?? ''}
          disabled={loadingGeofence}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <TextField
            margin="dense"
            id="speedLimit"
            name="speedLimit"
            label={getAttributeName('speedLimit', 'speed')}
            type="number"
            variant="outlined"
            size="small"
            value={speedValue}
            onChange={(event) => {
              handleSpeedChange(event.target.value);
            }}
            disabled={loadingGeofence}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              padding: '5px',
              justifyContent: 'flex-end',
              position: 'relative',
            }}
          >
            <Typography style={{ marginRight: '10px' }}>
              Color:
            </Typography>
            <Box
              sx={{
                width: '24px',
                height: '24px',
                backgroundColor: selectedColor,
                cursor: 'geofencenter',
                borderRadius: '50%',
              }}
              onClick={() => setShowPicker(!showPicker)}
            />
            {showPicker && (
              <div
                ref={colorPickerRef}
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  bottom: '36px',
                  right: '0px',
                  rotate: '180deg',
                }}
              >
                <GithubPicker
                  triangle="top-left"
                  color={selectedColor}
                  onChangeComplete={(color) => {
                    setSelectedColor(color.hex);
                    setShowPicker(false);
                  }}
                  width="220px"
                />
              </div>
            )}
          </div>
        </div>
        {invalidSpeed && (
          <Typography variant="caption" display="block" gutterBottom color="red" sx={{ paddingLeft: 2 }}>
            Velocidad incorrecta.
          </Typography>
        )}
        <div>
          <SelectDevicesList
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            item={item}
            setItem={setItem}
            itemsGroup={itemsGroup}
            setItemsGroup={setItemsGroup}
            devicesIds={geofence?.configuration?.devicesIds ?? null}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-around' }}>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          onClick={handleCloseGeofence}
          disabled={loadingGeofence}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={loadingGeofence}
        >
          {!geofence.name ? 'Crear' : 'Actualizar'}
        </Button>
      </DialogActions>
      <div style={{ width: '100%', height: 4 }}>
        {loadingGeofence && (<LinearProgress />)}
      </div>
    </Dialog>

  );
};

export default MapViewGeofenceModal;
