import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Toolbar, IconButton, OutlinedInput, Popover, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox, Badge, ListItemButton, ListItemText,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import TuneIcon from '@mui/icons-material/Tune';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
import ReorderIcon from '@mui/icons-material/Reorder';
import SquareIcon from '@mui/icons-material/Square';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import TextRotationDownIcon from '@mui/icons-material/TextRotationDown';
import { useTranslation } from '../common/components/LocalizationProvider';
import DeviceRow from './DeviceRow';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.colors.primary,
    left: 0,
  },
  toolbar2: {
    display: 'flex',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.colors.primary,
    left: 0,
    minHeight: 24,
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
}));

const CurrentSortBy = ({ sortBy, setSortBy, sortStatus, setSortStatus }) => {
  const classes = useStyles();
  return (
    <>
      {(sortBy !== 'asc' && sortBy !== 'desc') && (
        <IconButton onClick={() => setSortBy('asc')} className={classes.iconButton}>
          <SortByAlphaIcon fontSize="medium" />
        </IconButton>
      )}
      {(sortBy === 'asc') && (
      <IconButton onClick={() => setSortBy('desc')} className={classes.iconButton}>
        <TextRotateUpIcon fontSize="medium" sx={{ color: '#35ff35' }} />
      </IconButton>
      )}
      {(sortBy === 'desc') && (
      <IconButton onClick={() => setSortBy(null)} className={classes.iconButton}>
        <TextRotationDownIcon fontSize="medium" sx={{ color: '#35ff35' }} />
      </IconButton>
      )}
      <IconButton onClick={() => setSortStatus(!sortStatus)} className={classes.iconButton}>
        <SquareIcon fontSize="medium" sx={{ color: sortStatus ? '#35ff35' : null }} />
      </IconButton>
    </>
  );
};

const MainToolbar = ({
  filteredDevices,
  devicesOpen,
  setDevicesOpen,
  keyword,
  setKeyword,
  filter,
  setFilter,
  filterMap,
  setFilterMap,
  expandGroups,
  setExpandGroups,
  enableGroups,
  setEnableGroups,
  sortBy,
  setSortBy,
  sortStatus,
  setSortStatus,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);
  const haveGroups = (Object.values(devices).filter((item) => Object.keys(groups).includes(`${item.groupId}`)).length > 0);

  const toolbarRef = useRef();
  const inputRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [devicesAnchorEl, setDevicesAnchorEl] = useState(null);

  const deviceStatusCount = (status) => Object.values(devices).filter((d) => d.status === status).length;

  return (
    <>
      <Toolbar ref={toolbarRef} className={classes.toolbar}>
        <OutlinedInput
          ref={inputRef}
          placeholder={t('sharedSearchDevices')}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onFocus={() => setDevicesAnchorEl(toolbarRef.current)}
          onBlur={() => setDevicesAnchorEl(null)}
          size="small"
          fullWidth
        />
        <IconButton size="small" edge="end" onClick={() => setFilterAnchorEl(inputRef.current)} className={classes.iconButton}>
          <Badge color="info" variant="dot" invisible={!filter.statuses.length && !filter.groups.length}>
            <TuneIcon fontSize="medium" />
          </Badge>
        </IconButton>
        <IconButton edge="end" onClick={() => setDevicesOpen(!devicesOpen)} className={classes.iconButton}>
          {devicesOpen ? <UnfoldLessIcon fontSize="medium" /> : <UnfoldMoreIcon fontSize="medium" />}
        </IconButton>
        <Popover
          open={!!devicesAnchorEl && !devicesOpen}
          anchorEl={devicesAnchorEl}
          onClose={() => setDevicesAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: Number(theme.spacing(2).slice(0, -2)),
          }}
          marginThreshold={0}
          PaperProps={{
            style: { width: `calc(${toolbarRef.current?.clientWidth}px - ${theme.spacing(4)})` },
          }}
          elevation={1}
          disableAutoFocus
          disableEnforceFocus
        >
          {filteredDevices.slice(0, 3).map((_, index) => (
            <DeviceRow key={filteredDevices[index].id} data={filteredDevices} index={index} />
          ))}
          {filteredDevices.length > 3 && (
          <ListItemButton alignItems="center" onClick={() => setDevicesOpen(true)}>
            <ListItemText
              primary={t('notificationAlways')}
              style={{ textAlign: 'center' }}
            />
          </ListItemButton>
          )}
        </Popover>
        <Popover
          open={!!filterAnchorEl}
          anchorEl={filterAnchorEl}
          onClose={() => setFilterAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.filterPanel}>
            <FormControl>
              <InputLabel>{t('deviceStatus')}</InputLabel>
              <Select
                label={t('deviceStatus')}
                value={filter.statuses}
                onChange={(e) => setFilter({ ...filter, statuses: e.target.value })}
                multiple
              >
                <MenuItem value="online">{`${t('deviceStatusOnline')} (${deviceStatusCount('online')})`}</MenuItem>
                <MenuItem value="offline">{`${t('deviceStatusOffline')} (${deviceStatusCount('offline')})`}</MenuItem>
                <MenuItem value="unknown">{`${t('deviceStatusUnknown')} (${deviceStatusCount('unknown')})`}</MenuItem>
              </Select>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={filterMap} onChange={(e) => setFilterMap(e.target.checked)} />}
                label={t('sharedFilterMap')}
              />
            </FormGroup>
          </div>
        </Popover>
      </Toolbar>
      <Toolbar ref={toolbarRef} className={classes.toolbar2} style={devicesOpen ? {} : { display: 'none' }}>
        <IconButton onClick={() => setEnableGroups(!enableGroups)} className={classes.iconButton}>
          {(enableGroups) ? <ListIcon fontSize="medium" /> : <ReorderIcon fontSize="medium" />}
        </IconButton>
        <CurrentSortBy
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortStatus={sortStatus}
          setSortStatus={setSortStatus}
        />
        {(haveGroups && enableGroups) && (
        <IconButton onClick={() => setExpandGroups(!expandGroups)} className={classes.iconButton} style={{ position: 'absolute', right: 14 }} disabled={!devicesOpen}>
          {expandGroups ? <ExpandLessIcon fontSize="medium" /> : <ExpandMoreIcon fontSize="medium" />}
        </IconButton>
        )}
      </Toolbar>
    </>
  );
};

export default MainToolbar;
