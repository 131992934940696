/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sessionActions } from '../store';
import LoginLayout from './LoginLayout';
import { handleLoginTokenListeners, nativeEnvironment, nativePostMessage } from '../common/components/NativeInterface';
import { useCatch, useEffectAsync } from '../reactHelper';

const LoginRemotePage = () => {
  const dispatch = useDispatch();

  const { id, token } = useParams();

  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = '';
      try {
        const expiration = moment().add(6, 'months').toISOString();
        const response = await fetch('/api/session/token', {
          method: 'POST',
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = '';
      }
      nativePostMessage(`login|${token}`);
    }
  };

  const handlePasswordLogin = async () => {
    try {
      const response = await fetch(`/api/session?locale=es&token=${token}`);
      if (response.ok) {
        const user = await response.json();
        generateLoginToken();
        dispatch(sessionActions.updateUser(user));
        dispatch(sessionActions.updateGlobalLoading(true));
        const response2 = await fetch(`/api/session/${id}`);
        if (response2.ok) {
          window.location.replace('/');
        } else {
          throw Error(await response2.text());
        }
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      window.location.replace('/');
    }
  };

  const handleTokenLogin = useCatch(async (token) => {
    const response = await fetch(`/api/session?token=${encodeURIComponent(token)}`);
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
    } else {
      throw Error(await response.text());
    }
  });

  useEffect(() => nativePostMessage('authentication'), []);

  useEffectAsync(async () => {
    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
    }

    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    dispatch(sessionActions.updateUser(null));
    const listener = (token) => handleTokenLogin(token);
    handleLoginTokenListeners.add(listener);
    await handlePasswordLogin();
    return () => handleLoginTokenListeners.delete(listener);
  }, []);

  return (
    <LoginLayout>
      <div style={{ color: '#fff', textAlign: 'center' }}>Redirecting ...</div>
    </LoginLayout>
  );
};

export default LoginRemotePage;
