/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  LinearProgress,
  Divider,
  ListItemButton,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListSubheader,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from '@mui/material';
import { List as ListVirtual } from 'react-virtualized';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddLinkIcon from '@mui/icons-material/AddLink';
import moment from 'moment';
import { green, grey } from '@mui/material/colors';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch } from '../reactHelper';
import { useRestriction } from '../common/util/permissions';
import ModalLayout from '../common/components/ModalLayout';
import CommandSelectDevicesModal from './CommandSelectDevicesModal';
import { vehicleIconKey, vehicleStatus } from '../map/core/preloadImages';

const listStyle = {
  width: '100%',
  bgcolor: 'background.paper',
  position: 'relative',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  height: '100%',
  minHeight: 400,
  maxHeight: 620,
  padding: 0,
};

const useStyles = makeStyles((theme) => ({
  rowBg: {
    backgroundColor: 'white',
  },
  rowBgSelected: {
    backgroundColor: `${theme.palette.colors.primary}30`,
  },
}));

const ListV = ({ selectedDevices, getDevice, classes, handleClick }) => (
  <ListVirtual
    width={300}
    height={520}
    rowHeight={35}
    rowCount={selectedDevices.length}
    rowRenderer={({ key, index, style }) => {
      const device = selectedDevices[index];
      const deviceState = getDevice(device.id);
      let statusv = deviceState.statusv || 'unknown';
      statusv = vehicleIconKey(statusv);
      return (
        <ListItemButton
          className={device.selected ? classes.rowBgSelected : classes.rowBg}
          key={key}
          style={style}
          onClick={() => handleClick(device.id)}
        >
          <ListItemIcon sx={{ minWidth: 'auto', paddingRight: '4px' }}>
            <img
              src={vehicleStatus[statusv]}
              alt={vehicleStatus[statusv]}
              style={{
                width: 28,
                height: 28,
                marginTop: 4,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={(
              <p style={{ fontSize: 12 }} className="textDeviceList">
                {device.name}
              </p>
                          )}
          />
        </ListItemButton>
      );
    }}
    style={{ scrollbarWidth: 'thin', width: '100%' }}
  />
);

const CommandModalPage = ({ selectedDeviceId }) => {
  const t = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const listRef = useRef(null);
  const stateDevices = useSelector((state) => state.devices.items);
  const [totalLogs, setTotalLogs] = useState(0);

  const [loading, setLoading] = useState(false);
  const handleLoading = (status) => setLoading(status);

  const [textCommand, setTextCommand] = useState('');
  const handleTextCommand = (event) => setTextCommand(event.target.value);

  const handleMouseDownSendCommand = (event) => {
    event.preventDefault();
  };

  const [newCommandName, setNewCommandName] = useState('');
  const handleNewCommandName = (event) => setNewCommandName(event.target.value);

  const [newCommandText, setNewCommandText] = useState('');
  const handleNewCommandText = (event) => setNewCommandText(event.target.value);

  const [selectedDId, setSelectedDId] = useState(selectedDeviceId);
  const device = useSelector((state) => state.devices.items[selectedDId]);
  const [selectedDevices, setSelectedDevices] = useState([{ id: device.id, name: device.name, selected: true }]);

  const limitCommands = useRestriction('limitCommands');

  const [showSelectDevicesModal, setShowSelectDevicesModal] = useState(false);
  const [lastCommands, setLastCommands] = useState([]);
  const [commandsSaved, setCommandsSaved] = useState([]);

  const [openAddCommand, setOpenAddCommand] = React.useState(false);

  const handleClickOpenAddCommand = () => {
    setOpenAddCommand(true);
  };

  const handleCloseAddCommand = () => {
    setOpenAddCommand(false);
    setNewCommandName('');
    setNewCommandText('');
  };

  useEffect(() => {
    if (totalLogs !== lastCommands.length) {
      setTotalLogs(lastCommands.length);
      if (listRef.current) {
        listRef.current.scrollIntoView({ behaviour: 'smooth' });
      }
    }
  }, [lastCommands]);

  const fetchCommandSaved = useCatch(async () => {
    const resp = await fetch('/api/commands', {
      headers: { Accept: 'application/json' },
    });
    if (resp.ok) {
      const commSaved = await resp.json();
      commSaved.sort((a, b) => a.description.localeCompare(b.description));
      setCommandsSaved(commSaved);
    } else {
      throw Error(await resp.text());
    }
  });

  const fetchCommandGroups = useCatch(async () => {
    const resp = await fetch('/api/groups', {
      headers: { Accept: 'application/json' },
    });
    if (resp.ok) {
      const commGroups = await resp.json();
      commGroups.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      throw Error(await resp.text());
    }
  });

  useEffect(() => {
    fetchCommandSaved();
    fetchCommandGroups();
  }, []);

  const handleSendCommand = useCatch(async () => {
    if (selectedDevices.length > 0) {
      handleLoading(true);
      let command;
      try {
        command = {
          type: 'custom',
          attributes: {
            data: textCommand.trim(),
          },
        };
        const devicesId = selectedDevices.map((device) => device.id);
        const response = await fetch('/api/commands/sends', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ devicesId, command }),
        });

        if (response.ok) {
          setTextCommand('');
        } else {
          throw Error(await response.text());
        }
      } finally {
        handleLoading(false);
      }
    }
  });

  const handleAddNewCommand = useCatch(async () => {
    handleLoading(true);
    handleCloseAddCommand();
    let command;
    try {
      command = {
        type: 'custom',
        attributes: {
          data: newCommandText,
        },
        description: newCommandName.trim(),
      };
      const response = await fetch('/api/commands', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(command),
      });

      if (response.ok) {
        setTextCommand('');
        const commSaved = [...commandsSaved, await response.json()];
        commSaved.sort((a, b) => a.description.localeCompare(b.description));
        setCommandsSaved(commSaved);
      } else {
        throw Error(await response.text());
      }
    } finally {
      handleLoading(false);
    }
  });

  useEffect(() => {
    const query = new URLSearchParams({
      deviceId: selectedDId,
    });
    setSelectedDevices(selectedDevices.map((dev) => ({ ...dev, selected: dev.id === selectedDId })));
    setLoading(true);
    setLastCommands([]);
    const fetchData = () => {
      fetch(`/api/reports/events/consolelast?${query.toString()}`, {
        headers: { Accept: 'application/json' },
      })
        .then(async (response) => {
          const res = await response.json() || [];
          setLastCommands(res.sort((a, b) => a.id - b.id));
        })
        .catch((error) => {
          throw Error(error);
        })
        .finally(() => setLoading(false));
    };
    fetchData();

    const interval = setInterval(fetchData, 6100);

    return () => clearInterval(interval);
  }, [selectedDId]);

  const handleClick = (id) => { setSelectedDId(id); };

  const getDevice = (id) => stateDevices[id];

  return (
    <>
      <Grid container spacing={1} alignItems="stretch" style={{ width: '100%' }}>
        {desktop && (
        <Grid item xs={3}>
          <Paper sx={{ height: '100%' }}>
            <List sx={{ ...listStyle, height: 520 }}>
              {commandsSaved.map((command) => (
                <Fragment key={`${command.id}-frt`}>
                  <ListItem key={`${command.id}-lst-i`} disablePadding>
                    <ListItemButton onClick={() => setTextCommand(command.attributes?.data)}>
                      <ListItemIcon sx={{ minWidth: 'auto', paddingRight: 1 }}>
                        <SendIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={(
                          <p style={{ wordWrap: 'break-word', fontSize: 12, padding: 1, margin: 0 }}>
                            {command.description}
                          </p>
                        )}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider key={`d-${command.id}+23`} variant="middle" />
                </Fragment>
              ))}
            </List>
            <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 10, bottom: 0 }}>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                color="primary"
                fullWidth
                style={{ flex: 1 }}
                onClick={handleClickOpenAddCommand}
                disabled={limitCommands || loading}
              >
                <AddIcon />
              </Button>
            </Paper>
          </Paper>
        </Grid>
        )}
        <Grid item xs={desktop ? 6 : 12}>
          <Paper sx={{ height: '100%' }}>
            <List sx={{ ...listStyle, height: 520 }} dense>
              <ListSubheader sx={{ textAlign: 'center', padding: 0 }}>
                <ListItemText
                  primary={(
                    <Typography style={{ wordWrap: 'break-word', wordBreak: 'break-all', fontSize: 14, padding: 0, margin: 0 }}>
                      {device.name}
                    </Typography>
                )}
                  secondary={(
                    <Typography style={{ wordWrap: 'break-word', wordBreak: 'break-all', fontSize: 11, padding: 0, margin: 0 }}>
                      {device.uniqueId}
                    </Typography>
                )}
                />

                <Divider key="d-p23" variant="fullWidth" />
              </ListSubheader>
              {lastCommands.map((command) => (
                <Fragment key={`${command.id}-frt2`}>
                  <ListItem key={command.id}>
                    <ListItemText
                      primary={(
                        <Typography style={{ wordWrap: 'break-word', wordBreak: 'break-all', fontSize: 12, padding: 1, margin: 0, display: 'flex', alignItems: 'center' }}>
                          {command.attributes?.result ?
                            <MarkChatReadIcon fontSize="small" sx={{ marginRight: '5px', color: grey[600] }} /> :
                            <CheckCircleOutlineIcon fontSize="small" sx={{ marginRight: '5px', color: lastCommands.indexOf(command) === lastCommands.length - 1 ? grey[600] : green[400] }} />}
                          {command.attributes?.result || command.attributes?.command}
                        </Typography>
                    )}
                      secondary={(
                        <Typography style={{ wordWrap: 'break-word', wordBreak: 'break-all', fontSize: 11, padding: '5px 1px 1px', margin: 0, textAlign: 'right' }}>
                          {moment(command.eventTime).format('DD/MM/YYYY - H:mm:ss')}
                        </Typography>
                    )}
                    />
                  </ListItem>
                  <Divider key={`d-${command.id}+23`} variant="middle" />

                </Fragment>
              ))}
              <li key="wxx2" ref={listRef} />
            </List>
            <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 10, bottom: 0 }}>
              <FormControl sx={{ m: 1 }} variant="outlined" style={{ flex: 5, marginRight: 2 }}>
                <OutlinedInput
                  id="outlined-adornment"
                  type="text"
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="commandSend"
                        onClick={() => setTextCommand('')}
                        onMouseDown={handleMouseDownSendCommand}
                        edge="end"
                      >
                        <BackspaceIcon />
                      </IconButton>
                    </InputAdornment>
                )}
                  placeholder="Ex.: google#"
                  value={textCommand}
                  onChange={handleTextCommand}
                />
              </FormControl>
              <Button
                sx={{ m: 1 }}
                variant="contained"
                color="primary"
                fullWidth
                style={{ flex: 1 }}
                onClick={handleSendCommand}
                disabled={limitCommands || textCommand.trim().length <= 0 || loading || selectedDevices.length <= 0}
              >
                {t('commandSend')}
              </Button>
            </Paper>

          </Paper>

        </Grid>
        {desktop && (
        <Grid item xs={3}>
          <Paper sx={{ height: '100%' }}>
            <ListV selectedDevices={selectedDevices} getDevice={getDevice} classes={classes} handleClick={handleClick} />
            {/* <FixedSizeList
              width="100%"
              height={520}
              itemCount={selectedDevices.length}
              itemData={{ selectedDevices, handleClick }}
              itemSize={35}
              overscanCount={10}
              style={{ scrollbarWidth: 'thin' }}
            >
              {DeviceRowSimple}
            </FixedSizeList> */}
            {/* <List sx={{ ...listStyle, height: 520 }}>
              {selectedDevices.map((device) => (
                <Fragment key={`${device.id}-fragment`}>
                  <ListItem key={`${device.id}list`} disablePadding dense>
                    <ListItemButton onClick={() => setSelectedDId(device.id)} className={device.selected ? classes.rowBgSelected : classes.rowBg}>
                      <ListItemIcon sx={{ minWidth: 'auto', paddingRight: '4px' }}>
                        <DirectionsCarFilledOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={(
                          <p style={{ wordWrap: 'break-word', fontSize: 12, padding: 1, margin: 0 }}>
                            {device.name}
                          </p>
                        )}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider key={`d-${device.id}`} variant="middle" />
                </Fragment>
              ))}
            </List> */}
            <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 10, bottom: 0 }}>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                color="primary"
                fullWidth
                style={{ flex: 1 }}
                onClick={() => setShowSelectDevicesModal(true)}
                disabled={limitCommands || loading}
              >
                <AddLinkIcon />
              </Button>
            </Paper>
          </Paper>
        </Grid>
        )}

        <Grid item xs={12}>
          <Box sx={{ width: '100%', paddingY: 0 }}>
            <LinearProgress sx={{ visibility: loading ? 'visible' : 'hidden' }} />
          </Box>
        </Grid>

        <Dialog open={openAddCommand} onClose={handleCloseAddCommand}>
          <DialogTitle>{t('sharedAdd')}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="nameCommand"
              label={t('sharedName')}
              type="text"
              fullWidth
              variant="outlined"
              value={newCommandName}
              onChange={handleNewCommandName}
            />
            <br />
            <TextField
              margin="dense"
              id="codeCommand"
              label={t('deviceCommand')}
              type="text"
              fullWidth
              variant="outlined"
              value={newCommandText}
              onChange={handleNewCommandText}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddCommand}>{t('sharedCancel')}</Button>
            <Button onClick={handleAddNewCommand} disabled={limitCommands || newCommandName.trim().length <= 0 || newCommandText.trim().length <= 0 || loading}>{t('sharedSave')}</Button>
          </DialogActions>
        </Dialog>
      </Grid>
      {showSelectDevicesModal && (
      <ModalLayout title={t('deviceTitle') || 'Devices'} width={400} open={showSelectDevicesModal} handleClose={() => setShowSelectDevicesModal(false)}>
        <CommandSelectDevicesModal handleClose={() => setShowSelectDevicesModal(false)} selectedDevices={selectedDevices} setSelectedDevices={setSelectedDevices} />
      </ModalLayout>
      )}
    </>
  );
};

export default CommandModalPage;
