/* eslint-disable no-unused-vars */
import { useMediaQuery } from '@mui/material';
import {
  indigo,
  blue, pink,
} from '@mui/material/colors';
import { useTheme } from '@mui/styles';
import maplibregl from 'maplibre-gl';
import {
  useCallback,
  useEffect, useId,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import { commandsActions, devicesActions, sessionActions } from '../store';
import { getAddress, getPopupCompact } from './MapPositionsHelper';
import { map, setMarkersLabel } from './core/MapView';
import { findFonts } from './core/mapUtil';

const MapPositions = ({ positions, onClick, showStatus, selectedPosition, showLabel, isTabActive }) => {
  // const selectedDeviceTimeout = useRef();
  const selectedDeviceAnimation = useRef();
  const selectedDevicePopup = useRef();
  const selectedDeviceContentElement = useRef();

  const id = useId();
  const clusters = `${id}-clusters`;
  const direction = `${id}-direction`;

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const iconScale = useAttributePreference('iconScale', desktop ? 0.75 : 1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const devices = useSelector((state) => state.devices.items);

  const mapCluster = useAttributePreference('mapCluster', true);
  const directionType = useAttributePreference('mapDirection', 'selected');
  const mapFollow = useSelector((state) => state.session.server.attributes?.mapFollow || false);
  const [mapZoom, setMapZoom] = useState(map.getZoom());
  const [mapCenter, setMapCenter] = useState(map.getCenter());

  const [markers, setMarkers] = useState([]);
  const [prevMarkers, setPrevMarkers] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState();

  const hours12 = usePreference('twelveHourFormat');

  useEffect(() => {
    if (isTabActive && JSON.stringify(markers) !== JSON.stringify(prevMarkers)) {
      setMarkersLabel(markers);
      setPrevMarkers(markers);
    }
  }, [markers, isTabActive]);

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';
  const onMapZoom = () => setMapZoom(map.getZoom());
  const onMapMove = () => setMapCenter(map.getCenter());

  const onMapClick = useCallback((event) => {
    if (!event.defaultPrevented && onClick) {
      onClick();
    }
  }, [onClick]);

  const onMarkerClick = useCallback((event) => {
    event.preventDefault();
    const feature = event.features[0];

    if (onClick) {
      onClick(feature.properties.id, feature.properties.deviceId);
    }
  }, [onClick]);

  const onClusterClick = useCallback((event) => {
    event.preventDefault();
    const features = map.queryRenderedFeatures(event.point, {
      layers: [clusters],
    });
    const clusterId = features[0].properties.cluster_id;
    map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (!error) {
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });

        // INFO hack to get the center and zoom after the map has finished moving to show labels
        const setTimeoutId = setTimeout(() => {
          setMapCenter(map.getCenter());

          clearTimeout(setTimeoutId);
        }, 50);
      }
    });
  }, [clusters]);

  // selected device animation
  useEffect(() => {
    if (isTabActive && selectedPosition && selectedDeviceId !== selectedPosition.deviceId) {
      map.setZoom(Math.max(13, map.getZoom()));

      // eslint-disable-next-line no-undef
      selectedDeviceAnimation.value = document.createElement('div');
      // selectedDeviceAnimation.value.className = 'selected-devices-animation';
      selectedDeviceAnimation.value = new maplibregl.Marker({ element: selectedDeviceAnimation.value })
        .setLngLat([selectedPosition.longitude, selectedPosition.latitude])
        .addTo(map);
      // selectedDeviceTimeout.value = setTimeout(() => {
      //   if (selectedDeviceAnimation.value) {
      //     selectedDeviceAnimation.value.remove();
      //     selectedDeviceAnimation.value = null;
      //   }
      // }, 2500);

      setSelectedDeviceId(selectedPosition?.deviceId);
    }

    return () => {
      selectedDeviceAnimation?.value?.remove();
      // clearTimeout(selectedDeviceTimeout.value);
    };
  }, [selectedPosition, isTabActive]);

  // render popup
  useEffect(() => {
    if (isTabActive && selectedPosition && selectedDeviceId === selectedPosition.deviceId) {
      if (!selectedDevicePopup.value) {
        // eslint-disable-next-line no-undef
        selectedDeviceContentElement.value = document.createElement('div');
        selectedDeviceContentElement.value.id = `selected-device-popup_${selectedPosition.deviceId}`;
        selectedDeviceContentElement.value.innerHTML = getPopupCompact(devices[selectedPosition.deviceId], selectedPosition, hours12);

        selectedDevicePopup.value = new maplibregl.Popup({ closeButton: false })
          .setDOMContent(selectedDeviceContentElement.value)
          .setLngLat([selectedPosition.longitude, selectedPosition.latitude])
          .setMaxWidth('280px')
          .setOffset([0, 14])
          .addTo(map);

        selectedDeviceContentElement.value.addEventListener('click', (e) => {
          const targetId = e.target.id.split('_')[1];
          switch (targetId) {
            case 'google-street-view':
              // eslint-disable-next-line no-undef
              window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${selectedPosition.latitude}%2C${selectedPosition.longitude}&heading=${selectedPosition.course}`, '_blank');
              break;
            case 'replay':
              selectedDeviceContentElement.value?.remove();
              navigate('/replay');
              break;
            case 'info':
              navigate(`/position/${selectedPosition.id}`);
              break;
            case 'edit':
              dispatch(devicesActions.showEditModal(true));
              break;
            case 'commands':
              dispatch(commandsActions.showCommandModal(true));
              break;
            case 'follow':
              dispatch(sessionActions.updateMapFollow());
              break;
            case 'address':
              getAddress(selectedPosition.longitude, selectedPosition.latitude, selectedPosition.deviceId);
              break;
            default:
              break;
          }
        });
      }
    }

    return () => {
      if (selectedDeviceContentElement.value) {
        selectedDeviceContentElement.value.removeEventListener('click', () => { });
      }
      selectedDevicePopup.value?.remove();

      selectedDeviceContentElement.value = null;
      selectedDevicePopup.value = null;
    };
  }, [selectedPosition, selectedDeviceId, selectedDevicePopup, isTabActive]);

  // popup refresh content
  useEffect(() => {
    if (isTabActive && selectedPosition && selectedDeviceContentElement.value) {
      selectedDeviceContentElement.value.innerHTML = getPopupCompact(devices[selectedPosition.deviceId], selectedPosition, hours12);
    }
  }, [selectedPosition, mapFollow, isTabActive]);

  useEffect(() => {
    if (isTabActive) {
      map.addSource(id, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        cluster: mapCluster,
        clusterMaxZoom: 12,
        clusterRadius: 50,
      });

      map.addLayer({
        id,
        type: 'symbol',
        source: id,
        filter: ['!has', 'point_count'],
        layout: {
          'icon-image': 'vehicle-{vehicleStatus}',
          'icon-size': iconScale,
          'icon-allow-overlap': true,
          'icon-rotate': [
            'match',
            ['get', 'vehicleStatus'],
            ['no-gps', 'offline-no-gps', 'parking', 'parking-offline'],
            0,
            [
              'case',
              ['==', ['get', 'direction'], true],
              ['+', ['get', 'rotation'], -45],
              0,
            ],
          ],
          'icon-rotation-alignment': 'map',
        },
      });

      map.addLayer({
        id: clusters,
        type: 'circle',
        source: id,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            blue[800],
            50,
            indigo[900],
            100,
            pink[900],
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            24,
            50,
            29,
            100,
            33,
          ],
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff',
        },
      });
      map.addLayer({
        id: `${clusters}-count`,
        type: 'symbol',
        source: id,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': findFonts(map),
          'text-size': 15,
        },
        paint: {
          'text-color': '#fff',
        },
      });

      map.on('mouseenter', id, onMouseEnter);
      map.on('mouseleave', id, onMouseLeave);
      map.on('mouseenter', clusters, onMouseEnter);
      map.on('mouseleave', clusters, onMouseLeave);
      map.on('click', id, onMarkerClick);
      map.on('click', clusters, onClusterClick);
      map.on('click', onMapClick);
      map.on('zoomend', onMapZoom);
      map.on('moveend', onMapMove);
    }

    return () => {
      setMarkersLabel([]);
      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('mouseenter', clusters, onMouseEnter);
      map.off('mouseleave', clusters, onMouseLeave);
      map.off('click', id, onMarkerClick);
      map.off('click', clusters, onClusterClick);
      map.off('click', onMapClick);
      map.off('zoomend', onMapZoom);
      map.off('moveend', onMapMove);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(clusters)) {
        map.removeLayer(clusters);
      }
      if (map.getLayer(`${clusters}-count`)) {
        map.removeLayer(`${clusters}-count`);
      }
      if (map.getLayer(direction)) {
        map.removeLayer(direction);
      }

      // important to remove this layer if it exists
      if (map.getLayer(`selected-device-${id}`)) {
        map.removeLayer(`selected-device-${id}`);
      }

      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [mapCluster, clusters, direction, onMarkerClick, onClusterClick, isTabActive]);

  // selected device
  useEffect(() => {
    if (isTabActive) {
      map.setFilter(id, [
        'all',
        ['!has', 'point_count'],
        ['!=', 'deviceId', selectedPosition?.deviceId ?? 0],
      ]);

      map.addLayer({
        id: `selected-device-${id}`,
        type: 'symbol',
        source: id,
        filter: [
          'all',
          ['!has', 'point_count'],
          ['==', 'deviceId', selectedPosition?.deviceId ?? 0],
        ],
        layout: {
          'icon-image': 'vehicle-{vehicleStatus}',
          'icon-size': iconScale * 1.1,
          'icon-allow-overlap': true,
          'icon-rotate': [
            'match',
            ['get', 'vehicleStatus'],
            ['no-gps', 'offline-no-gps', 'parking', 'parking-offline'],
            0,
            [
              'case',
              ['==', ['get', 'direction'], true],
              ['+', ['get', 'rotation'], -45],
              0,
            ],
          ],
          'icon-rotation-alignment': 'map',
        },
      });
    }
    return () => {
      if (map.getLayer(`selected-device-${id}`)) {
        map.removeLayer(`selected-device-${id}`);
      }
    };
  }, [selectedPosition, isTabActive]);

  const worker = useRef(null);

  useEffect(() => {
    if (isTabActive) {
      if (worker.current) worker.current.postMessage({ devices, positions });
      setMapCenter(map.getCenter());
    }
  }, [devices, positions, isTabActive]);

  useEffect(() => {
    if (!worker.current) {
      worker.current = new Worker(URL.createObjectURL(
        new Blob(
          [
            `
            self.onmessage = function (event) {
              const { devices, positions } = event.data;
              const newData = {
                type: "FeatureCollection",
                features: positions
                  .filter((pos) => devices.hasOwnProperty(pos.deviceId))
                  .map((position) => ({
                    type: "Feature",
                    geometry: {
                      type: "Point",
                      coordinates: [position.longitude, position.latitude],
                    },
                    properties: createFeature(devices, position),
                  })),
              };
              self.postMessage(newData);
            };
            function createFeature(devices, position) {
              const device = devices[position.deviceId];
              const vehicleStatus = device.statusv || "default";
              return {
                id: position.id,
                deviceId: position.deviceId,
                name: device.name,
                // fixTime: formatTime(position.fixTime, "seconds", hours12),
                fixTime: position.fixTime,
                category: 'default',
                color: position.attributes.color || "neutral",
                rotation: position.course,
                direction: true,
                vehicleStatus,
              };
            }
    `,
          ],
          { type: 'text/javascript' },
        ),
      ), { name: 'mapDataWorker' });
    }
    worker.current.onmessage = (event) => {
      map.getSource(id)?.setData(event.data);
      // updateMapDataRef.current();
    };
    // Limpiar el worker cuando el componente se desmonte
    return () => {
      worker.current?.terminate();
      worker.current = null;
    };
  }, []);

  // render labels
  useEffect(() => {
    if (isTabActive) {
      if (showLabel) {
        const features = map.queryRenderedFeatures({
          layers: [id],
          filter: ['!has', 'point_count'],
        });
        setMarkers(features.map((feature) => ({
          coordinates: feature.geometry.coordinates,
          properties: {
            name: feature.properties.name,
            vehicleStatus: feature.properties.vehicleStatus,
          },
        })));
      } else { setMarkers([]); }
      const setTimeoutId = setTimeout(() => {
        setMapCenter(map.getCenter());
        clearTimeout(setTimeoutId);
      }, 9);
    }
  }, [mapZoom, mapCenter, showLabel, isTabActive]);

  return null;
};

export default MapPositions;
