import React, { useState } from 'react';
// import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import SelectField from '../common/components/SelectField';
// import deviceCategories from '../common/util/deviceCategories';
import { useTranslation } from '../common/components/LocalizationProvider';
import useDeviceAttributes from '../common/attributes/useDeviceAttributes';
import { useAdministrator } from '../common/util/permissions';
import useCommonDeviceAttributes from '../common/attributes/useCommonDeviceAttributes';
import EditItemViewModal from './components/EditItemViewModal';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const DeviceModalPage = ({ handleClose }) => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const admin = useAdministrator();

  const commonDeviceAttributes = useCommonDeviceAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);

  const [item, setItem] = useState();

  const validate = () => item && item.name && item.uniqueId;

  return (
    <EditItemViewModal
      endpoint="devices"
      item={item}
      setItem={setItem}
      validate={validate}
      handleClose={handleClose}
    >
      {item && (
        <Grid
          container
          spacing={2}
          alignItems="normal"
          style={{ width: '100%' }}
        >
          <Grid item xs={desktop ? 6 : 12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedRequired')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  value={item.name || ''}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                  label={t('sharedName')}
                />
                <TextField
                  value={item.uniqueId || ''}
                  onChange={(event) => setItem({ ...item, uniqueId: event.target.value })}
                  label={t('deviceIdentifier')}
                  helperText={t('deviceIdentifierHelp')}
                  disabled={!admin}
                />
              </AccordionDetails>
            </Accordion>
            <EditAttributesAccordion
              attributes={item.attributes}
              setAttributes={(attributes) => setItem({ ...item, attributes })}
              definitions={{ ...commonDeviceAttributes, ...deviceAttributes }}
              expanded={desktop}
            />
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Accordion defaultExpanded={desktop}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">{t('sharedExtra')}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  value={item.matricula || ''}
                  onChange={(event) => setItem({ ...item, matricula: event.target.value })}
                  label={t('Matricula')}
                  disabled={!admin}
                />
                <SelectField
                  value={item.groupId || 0}
                  onChange={(event) => setItem({ ...item, groupId: Number(event.target.value) })}
                  endpoint="/api/groups"
                  label={t('groupParent')}
                />
                <TextField
                  value={item.phone || ''}
                  onChange={(event) => setItem({ ...item, phone: event.target.value })}
                  label={t('sharedPhone')}
                  disabled={!admin}
                />
                <TextField
                  value={item.model || ''}
                  onChange={(event) => setItem({ ...item, model: event.target.value })}
                  label={t('deviceModel')}
                  disabled={!admin}
                />
                <TextField
                  value={item.contact || ''}
                  onChange={(event) => setItem({ ...item, contact: event.target.value })}
                  label={t('deviceContact')}
                />
                {/*
                {admin && (
                  <SelectField
                    value={item.category || 'default'}
                    emptyValue={null}
                    onChange={(event) => setItem({ ...item, category: event.target.value })}
                    data={deviceCategories.map((category) => ({
                      id: category,
                      name: t(
                        `category${category.replace(/^\w/, (c) => c.toUpperCase())}`,
                      ),
                    }))}
                    label={t('deviceCategory')}
                  />
                )}
                <SelectField
                  value={item.calendarId || 0}
                  onChange={(event) => setItem({ ...item, calendarId: Number(event.target.value) })}
                  endpoint="/api/calendars"
                  label={t('sharedCalendar')}
                />
                <TextField
                  label={t('userExpirationTime')}
                  type="date"
                  value={
                    (item.expirationTime &&
                      moment(item.expirationTime)
                        .locale('en')
                        .format(moment.HTML5_FMT.DATE)) ||
                    '2099-01-01'
                  }
                  onChange={(e) => setItem({
                    ...item,
                    expirationTime: moment(
                      e.target.value,
                      moment.HTML5_FMT.DATE,
                    )
                      .locale('en')
                      .format(),
                  })}
                  disabled={!admin}
                /> */}

                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={item.disabled}
                      onChange={(event) => setItem({ ...item, disabled: event.target.checked })}
                    />
                  )}
                  label={t('sharedDisabled')}
                  disabled={!admin}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </EditItemViewModal>
  );
};

export default DeviceModalPage;
