/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GithubPicker } from 'react-color';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Slider,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TuneIcon from '@mui/icons-material/Tune';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import HistoryIcon from '@mui/icons-material/History';
import Checkbox from '@mui/material/Checkbox';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import RouteIcon from '@mui/icons-material/Route';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Marker } from 'maplibre-gl';
import MapView, { map, setMarkersAlarms, setMarkersStops } from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapRoutePoints from '../map/MapRoutePoints';
import { formatHours, formatTime } from '../common/util/formatter';
import ReportFilter from '../reports/components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch } from '../reactHelper';
import MapCamera from '../map/MapCamera';
import MapGeofence from '../map/MapGeofence';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import MapPositionsRoutes from '../map/MapPositionsRoutes';
import usePersistedState from '../common/util/usePersistedState';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    zIndex: 3,
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  slider: {
    width: '100%',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControlLabel: {
    height: '100%',
    width: '100%',
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const ReplayPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const timerRef = useRef();
  const selectedDeviceTimeout = useRef();
  const selectedDeviceAnimation = useRef();
  const hours12 = usePreference('twelveHourFormat');
  const speedUnit = useAttributePreference('speedUnit');
  const defaultDeviceId = useSelector((state) => state.devices.selectedId);

  const [positions, setPositions] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [index, setIndex] = useState(0);
  const [selectedDeviceId, setSelectedDeviceId] = useState(defaultDeviceId);
  const [showCard, setShowCard] = useState(false);
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [expanded, setExpanded] = useState(true);
  const [playing, setPlaying] = useState(false);

  const [recordHistory, setRecordHistory] = useState([]);
  const addRecordHistory = (item) => {
    if (!recordHistory.find((r) => r.from === item.from && r.to === item.to)) {
      setRecordHistory([
        { ...item, id: recordHistory.length },
        ...recordHistory,
      ]);
    }
  };
  const [stops, setStops] = useState([]);
  const stopsByDate = stops.reduce((acc, stop) => {
    const date = new Date(stop.startTime).toLocaleDateString();
    if (!acc.has(date)) {
      acc.set(date, []);
    }
    acc.get(date).push(stop);
    return acc;
  }, new Map());

  const deviceName = useSelector((state) => {
    if (selectedDeviceId) {
      const device = state.devices.items[selectedDeviceId];
      if (device) {
        return device.name;
      }
    }
    return null;
  });

  const [stopTime, setStopTime] = useState(5);

  const validateNumber = (value) => /^[1-9]\d{0,2}$/.test(value);

  const handleStopTimeChange = (e) => {
    const inputValue = e.target.value;
    if (validateNumber(inputValue)) {
      setStopTime(inputValue);
    }
  };

  useEffect(() => {
    if (playing && positions.length > 0) {
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, 500);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, positions]);

  useEffect(() => {
    if (index >= positions.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, positions]);

  useEffect(() => {
    const coord = positions.map((item) => [item.longitude, item.latitude]).filter((value, index, self) => index === self.findIndex((t) => (
      t[0] === value[0] && t[1] === value[1]
    )));
    setCoordinates(coord);
  }, [positions]);

  const onPointClick = useCallback(
    (_, index) => {
      setIndex(index);
    },
    [setIndex],
  );

  const onMarkerClick = useCallback(
    (positionId) => {
      setShowCard(!!positionId);
    },
    [setShowCard],
  );

  const getAlarmPosition = (alarms, positions) => {
    const results = [];
    alarms.forEach((alarm) => {
      const position = positions.find((pos) => pos.id === alarm.positionId);
      if (position) {
        results.push({ ...alarm, position });
      }
    });
    return results;
  };

  const handleSubmit = useCatch(async ({ deviceId, from, to }) => {
    setSelectedDeviceId(deviceId);
    setFrom(from);
    setTo(to);
    addRecordHistory({ from, to });
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/positions?${query.toString()}`);
    if (response.ok) {
      setIndex(0);
      const positions = await response.json();
      setMarkersStops(null);
      setMarkersAlarms(null);
      setPositions(positions);
      if (positions.length) {
        setExpanded(false);

        let stops = 0;
        let count = 0;
        let stopt = false;
        const stopsList = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < positions.length; i++) {
          if (
            (positions[i - 1]?.attributes.ignition === 0 ||
              positions[i - 1]?.attributes.ignition === false) &&
            positions[i - 1]?.speed === 0 &&
            positions[i]?.speed === 0
          ) {
            count +=
              Date.parse(positions[i]?.deviceTime) -
              Date.parse(positions[i - 1]?.deviceTime);
            if (!stopt) {
              if (count >= stopTime * 60 * 1000) {
                // eslint-disable-next-line no-plusplus, no-unused-vars
                stops++;
                stopt = true;
                stopsList.push({
                  deviceId: positions[i]?.deviceId,
                  startTime: positions[i]?.deviceTime,
                  endTime: positions[i]?.deviceTime,
                  positionId: positions[i]?.id,
                  latitude: positions[i]?.latitude,
                  longitude: positions[i]?.longitude,
                  address: positions[i]?.address,
                  duration: count,
                });
              }
            } else {
              stopsList[stopsList.length - 1].duration = count;
              stopsList[stopsList.length - 1].deviceTime =
                positions[i]?.deviceTime;
            }
          } else {
            count = 0;
            stopt = false;
          }
        }
        if (
          stopsList.length > 0 &&
          stopsList[0].longitude === positions[0].longitude &&
          stopsList[0].latitude === positions[0].latitude
        ) {
          stopsList.shift();
        }
        if (
          stopsList.length > 0 &&
          stopsList[stopsList.length - 1].longitude ===
            positions[positions.length - 1].longitude &&
          stopsList[stopsList.length - 1].latitude ===
            positions[positions.length - 1].latitude
        ) {
          stopsList.pop();
        }
        setMarkersStops(stopsList, hours12);
        setStops(stopsList);

        const response = await fetch(`/api/reports/alerts?${query.toString()}`, {
          headers: { Accept: 'application/json' },
        });
        if (response.ok) {
          const rsp = await response.json();
          if (rsp.length > 0) {
            let alarms = rsp.filter((alarm) => alarm.positionId > 0).sort((a, b) => a.id - b.id);
            alarms = getAlarmPosition(alarms, positions);
            setMarkersAlarms(alarms, t, hours12, speedUnit);
          }
        } else {
          throw Error(await response.text());
        }
      } else {
        throw Error(t('sharedNoData'));
      }
    } else {
      throw Error(await response.text());
    }
  });

  const handleHistoryItem = (item) => () => {
    handleSubmit({
      deviceId: selectedDeviceId,
      from: item.from,
      to: item.to,
    });
  };

  const handleStopItem = (item) => () => {
    selectedDeviceAnimation?.value?.remove();
    clearTimeout(selectedDeviceTimeout.value);
    // eslint-disable-next-line no-undef
    selectedDeviceAnimation.value = document.createElement('div');
    selectedDeviceAnimation.value.className = 'selected-devices-animation';
    selectedDeviceAnimation.value = new Marker({
      element: selectedDeviceAnimation.value,
    })
      .setLngLat([item.longitude, item.latitude])
      .addTo(map);
    selectedDeviceTimeout.value = setTimeout(() => {
      if (selectedDeviceAnimation.value) {
        selectedDeviceAnimation.value.remove();
        selectedDeviceAnimation.value = null;
        clearTimeout(selectedDeviceTimeout.value);
      }
    }, 1500);

    map.easeTo({
      center: [item.longitude, item.latitude],
      duration: 300,
    });
  };

  const handleDownload = () => {
    const query = new URLSearchParams({ deviceId: selectedDeviceId, from, to });
    // eslint-disable-next-line no-undef
    window.location.assign(`/api/positions/kml?${query.toString()}`);
  };
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#0000FF');
  const colorPickerRef = useRef(null);

  const [animatedRoute, setAnimatedRoute] = usePersistedState('expandGroups', true);

  const handleAnimateRouteChange = (event) => {
    setAnimatedRoute(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <MapView>
        <MapGeofence />
        <MapRoutePath positions={positions} color={selectedColor} animatedRoute={animatedRoute} />
        <MapRoutePoints positions={positions} onClick={onPointClick} />
        {index < positions.length && (
          <MapPositionsRoutes
            positions={[positions[index]]}
            onClick={onMarkerClick}
            titleField="fixTime"
            playing={playing}
          />
        )}
      </MapView>
      <MapCamera coordinates={coordinates} />
      <div className={classes.sidebar}>
        <Paper elevation={3} square>
          <Toolbar
            style={{
              backgroundColor: theme.palette.colors.primary,
              color: theme.palette.primary.contrastText,
            }}
          >
            <IconButton
              edge="start"
              sx={{ mr: 2 }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon
                style={{ color: theme.palette.primary.contrastText }}
              />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t('reportReplay')}
            </Typography>
            {!expanded && (
              <IconButton onClick={handleDownload}>
                <DownloadIcon
                  style={{ color: theme.palette.primary.contrastText }}
                />
              </IconButton>
            )}
            {positions?.length > 0 && (
              <IconButton
                edge="end"
                onClick={() => {
                  if (positions?.length > 0) {
                    return setExpanded(!expanded);
                  }
                  return null;
                }}
              >
                {!expanded ? (
                  <TuneIcon
                    style={{ color: theme.palette.primary.contrastText }}
                  />
                ) : (
                  <RouteIcon
                    style={{ color: theme.palette.primary.contrastText }}
                  />
                )}
              </IconButton>
            )}
          </Toolbar>
        </Paper>
        <Paper className={classes.content} square>
          {!expanded ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  style={{
                    fontWeight: 'bold',
                    maxWidth: '230px',
                    overflowWrap: 'break-word',
                    textAlign: 'center',
                  }}
                >
                  {deviceName}
                </Typography>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    justifyContent: 'flex-end',
                    position: 'relative',
                  }}
                >
                  <Typography>
                    Animación:
                  </Typography>
                  <Checkbox
                    checked={animatedRoute}
                    onChange={handleAnimateRouteChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    justifyContent: 'flex-end',
                    position: 'relative',
                  }}
                >
                  <Typography style={{ marginRight: '10px' }}>
                    Color de ruta:
                  </Typography>
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: selectedColor,
                      cursor: 'pointer',
                      borderRadius: '50%',
                    }}
                    onClick={() => setShowPicker(!showPicker)}
                  />
                  {showPicker && (
                  <div
                    ref={colorPickerRef}
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      top: '44px',
                      right: '0px',
                    }}
                  >
                    <GithubPicker
                      triangle="top-right"
                      color={selectedColor}
                      onChangeComplete={(color) => {
                        setSelectedColor(color.hex);
                        setShowPicker(false);
                      }}
                    />
                  </div>
                  )}
                </div>
              </div>
              <Slider
                className={classes.slider}
                max={positions.length - 1}
                step={null}
                marks={positions.map((_, index) => ({ value: index }))}
                value={index}
                onChange={(_, index) => setIndex(index)}
              />
              <div className={classes.controls}>
                {`${index + 1}/${positions.length}`}
                <IconButton
                  onClick={() => setIndex((index) => index - 1)}
                  disabled={playing || index <= 0}
                >
                  <FastRewindIcon />
                </IconButton>
                <IconButton
                  onClick={() => setPlaying(!playing)}
                  disabled={index >= positions.length - 1}
                >
                  {playing ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
                <IconButton
                  onClick={() => setIndex((index) => index + 1)}
                  disabled={playing || index >= positions.length - 1}
                >
                  <FastForwardIcon />
                </IconButton>
                <Typography variant="subtitle2">
                  {formatTime(positions[index].fixTime, 'seconds', hours12)}
                </Typography>
              </div>
              <Divider />
              <Typography
                variant="subtitle2"
                align="center"
                style={{ paddingTop: 8 }}
              >
                {formatTime(from, 'minutes', hours12)}
                {' - '}
                {formatTime(to, 'minutes', hours12)}
              </Typography>
              {desktop && stops.length > 0 && (
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  scrollbarWidth: 'thin',
                  maxHeight: desktop ? 360 : 290,
                  '& ul': { padding: 0 },
                }}
                subheader={<ListSubheader />}
              >
                <ListSubheader>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8px 3px 3px',
                    }}
                  >
                    <PendingActionsOutlinedIcon />
                    <ListItemText
                      primary={t('stops') || 'Paradas'}
                      sx={{ paddingLeft: 1 }}
                    />
                    <Chip
                      label={stops.length}
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                  <Divider />
                </ListSubheader>
                {Array.from(stopsByDate.keys()).map((date) => (
                  <Accordion key={date}>
                    <AccordionSummary
                      expandIcon={(
                        <ExpandMoreIcon />
                      )}
                    >
                      <Typography>{date}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List dense>
                        {stopsByDate.get(date).map((stop) => (
                          <ListItemButton
                            alignItems="flex-start"
                            key={`itemStop-${stop.positionId}`}
                            onClick={handleStopItem(stop)}
                            dense
                          >
                            <ListItemText primary={(
                              <span style={{ display: 'flex', alignItems: 'center' }}>
                                <TimerOutlinedIcon fontSize="small" />
                                <ListItemText primary={formatTime(stop.startTime, 'minutes', hours12)} sx={{ paddingLeft: 1 }} />
                                <ListItemText primary={formatHours(stop.duration)} />
                              </span>
                        )}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </List>
              )}
            </>
          ) : (
            <>
              <div style={{ padding: '0 16px', marginBottom: -7 }}>
                <FormControl fullWidth>
                  <TextField
                    id="standard-number"
                    label="Tiempo Parada"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <TimerOutlinedIcon
                            style={{ padding: 2, marginRight: 10 }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">min.</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    pattern="[1-9]\d{0,2}$"
                    value={stopTime}
                    onChange={handleStopTimeChange}
                  />
                </FormControl>
              </div>

              <ReportFilter handleSubmit={handleSubmit} fullScreen showOnly multiDevice />
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  scrollbarWidth: 'thin',
                  maxHeight: desktop ? 360 : 290,
                  '& ul': { padding: 0 },
                }}
                subheader={<ListSubheader />}
              >
                <ListSubheader>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: 4,
                    }}
                  >
                    <EventRepeatIcon />
                    <ListItemText
                      primary={t('record') || 'Record'}
                      sx={{ paddingLeft: 3 }}
                    />
                  </div>
                  <Divider />
                </ListSubheader>
                {recordHistory.map((item) => (
                  <ListItem
                    alignItems="flex-start"
                    sx={{ padding: '0 0 0 8px' }}
                    key={`itemHistory-${item.id}`}
                    onClick={handleHistoryItem(item)}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={(
                          <span
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <HistoryIcon fontSize="small" />
                            <span>
                              <ListItemText
                                primary={formatTime(
                                  item.from,
                                  'minutes',
                                  hours12,
                                )}
                                sx={{ paddingLeft: 2 }}
                              />
                              <ListItemText
                                primary={formatTime(
                                  item.to,
                                  'minutes',
                                  hours12,
                                )}
                                sx={{ paddingLeft: 2 }}
                              />
                            </span>
                          </span>
                        )}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Paper>
      </div>
      {showCard && index < positions.length && (
        <> </>
        // <StatusCard
        //   deviceId={selectedDeviceId}
        //   position={positions[index]}
        //   onClose={() => setShowCard(false)}
        //   disableActions
        // />
      )}
    </div>
  );
};

export default ReplayPage;
